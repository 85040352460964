import React from "react";
import { AirdropComponents } from "../components/Airdrop";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

function Airdrops() {

  return (
    <div>
      <TonConnectUIProvider manifestUrl="https://api-staging.clanofraiders.com/tonconnect-manifest.json">
        <AirdropComponents />
      </TonConnectUIProvider>
    </div>
  );
}

export default Airdrops;
