import React, { useState, useEffect } from "react";
import { SwitchComponent } from "./SwitchComponent";

export const SwitchSettings = () => {
  const onClick = () => {};
  const getInitialHapticState = () => {
    const storedValue = localStorage.getItem("HapticFeedback");
    return storedValue === null || storedValue === "true";
  };

  const [enableAnimation, setEnableAnimation] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(false);
  const [enabledHaptic, setEnabledHaptic] = useState(getInitialHapticState);

  useEffect(() => {
    if (localStorage.getItem("allowAnimation")) {
      localStorage.getItem("allowAnimation") === "true"
        ? setEnableAnimation(true)
        : setEnableAnimation(false);
    } else {
      setEnableAnimation(true);
    }

    if (localStorage.getItem("soundEnabled")) {
      localStorage.getItem("soundEnabled") === "true"
        ? setSoundEnabled(true)
        : setSoundEnabled(false);
    } else {
      setSoundEnabled(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("HapticFeedback", enabledHaptic.toString());
  }, [enabledHaptic]);

  const handleAnimation = () => {
    localStorage.setItem("allowAnimation", !enableAnimation);
  };

  const handleSound = () => {
    localStorage.setItem("soundEnabled", !soundEnabled);
  };

  const HapticFeedback = () => {
    setEnabledHaptic((prevState) => !prevState);
  };

  return (
    <div className="mb-10 flex flex-col gap-8">
      <div className="flex justify-between items-center">
        <div className="text-[1rem]">Haptic Feedback</div>
        <SwitchComponent
          checked={enabledHaptic}
          onChange={HapticFeedback}
          onClick={onClick}
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="text-[1rem]">Coin Animation</div>
        <SwitchComponent
          checked={enableAnimation}
          onChange={() => setEnableAnimation(!enableAnimation)}
          onClick={handleAnimation}
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="text-[1rem]">Game Sounds</div>
        <SwitchComponent
          checked={soundEnabled}
          onChange={() => setSoundEnabled(!soundEnabled)}
          onClick={handleSound}
        />
      </div>
    </div>
  );
};
