import React, { useContext, useState } from "react";
import { Fade } from "react-awesome-reveal";
import "./style.css";
import { context } from "../../App";
import { ButtonPrimary } from "../buttons/ButtonPrimary";
import StepOneIcon from "../../icons/onboard-step-1.png";
import StepThreeIcon from "../../icons/boost.png";
import StepTwoIcon from "../../icons/onboard.png";
import StepFourIcon from "../../icons/earnWithFriends.png";
import ReactSwipe from "react-swipe";
// import 'react-swipe/lib/react-swipe.css';

export const Onboarding = () => {
  const {
    showGame,
    setShowGame,
    setShowOnBoarding,
    setModalLevel,
    setShowClan,
  } = useContext(context);
  const steps = [
    {
        name: "Welcome to Clan of Raiders",
        description: "Clan of Raiders is an exciting game where you can raid and collect Airdrops from various projects. Join the adventure and start earning today!",
        image: StepOneIcon
    },
    {
        name: "Tap to Earn Coins",
        description: "Engage with the game by tapping to earn coins. The more you tap, the more coins you collect. Start now and see your wealth grow!",
        image: StepTwoIcon
    },
    {
        name: "Boosts and Upgrades",
        description: "Enhance your gameplay with powerful boosts and upgrades. Strategize to maximize your earnings and stay ahead in the game.",
        image: StepThreeIcon
    },
    {
        name: "Earn More Coins with Friends",
        description: "Invite your friends to join the fun and earn even more coins together. Team up to redeem coins for exclusive Raider Clan airdrops when the token launches.",
        image: StepFourIcon
    },
  ];

  let swipeRef;
  let [btnText, setBtnText] = useState('Next');

  const handleNext = () => {
    const pos = swipeRef?.getPos()
    setTimeout(() => {
      if (pos < steps.length - 1) {
        setBtnText("Next")
        swipeRef?.next();
      }
      if(pos === (steps?.length - 2)) {
        setBtnText("Let's go")
      } 
      else if (pos === steps.length - 1) {
        setShowOnBoarding(false);
        setShowClan(true);
      }
    }, 100)
  };

  const handleRectangleClick = (index) => {
    const pos = swipeRef?.getPos()
    if(pos === (steps?.length - 2)) {
      setBtnText("Let's go")
    } else {
      setBtnText("Next")
    }
    swipeRef?.slide(index)
  };

  return (
    <div className="onboarding relative">
      <ReactSwipe 
        swipeOptions={{
          continuous: false
        }}
        childCount={steps.length}
        ref={el => (swipeRef = el)} 
      >
        {
          steps?.map((step, index) => (
            <div key={index} className={`div flex px-4`}>
              <Fade delay={300}>
                <div className="rectangle">
                  <img src={step.image} className={`${index === 0 ? 'w-[17.5rem] h-[17.5rem] rounded-[35px]' : '!w-full h-full rounded-[47.08px]'} lg:h-auto block md:p-6 object-fill`} />
                </div>
              </Fade>
              <Fade delay={500}>
                <div className="frame-2">
                  <div className="text-wrapper-2">{step.name}</div>
                  <p className="p">{step.description}</p>
                </div>
              </Fade>

              <div className="group">
                <div className="frame-3">
                  {steps.map((_, rectangleIndex) => (
                    <div
                      key={rectangleIndex}
                      className={`rectangle-${index === rectangleIndex ? "2" : "3"}`}
                      onClick={() => handleRectangleClick(rectangleIndex)}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))
        }
      </ReactSwipe>
      <ButtonPrimary
        text={btnText}
        className={`!w-[21rem] mx-auto mb-6`}
        divClassName={'!text-[20px] !font-semibold py-2 !w-full'}
        onClick={handleNext}
      />
    </div>
  );
};