import { config } from "../config";
import { AxiosConnect } from "./auth.service";

export class EarnConnect {

    async getDailyReward(telegramId) {
        const { data: response } = await AxiosConnect.get(`/earn/daily-reward/${telegramId}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async verifyTelegramFollow(telegramId, project_id) {
        const { data: response } = await AxiosConnect.get(`/earn/validate-membership/${telegramId}/${project_id}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async verifyTwitterFollow(telegramId) {
        const { data: response } = await AxiosConnect.post(`/earn/validate-twitter`,{ telegramId });
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async dailyRewards(telegramId) {
        const { data: response } = await AxiosConnect.get(`/reward/daily-reward/${telegramId}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async getTaskList(telegramId, project_id){
        const { data: response } = await AxiosConnect.get(`/earn/task-list/${telegramId}/${project_id}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }
    

    async verifyReferralCountEqualThree(telegramId){
        const { data: response } = await AxiosConnect.get(`/earn/referral-count-three/${telegramId}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async verifyReferralCountEqualTen(telegramId){
        const { data: response } = await AxiosConnect.get(`/earn/referral-count-ten/${telegramId}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }


    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        return errorMessage;
    }
}