import { useContext, useEffect } from "react";
import Roadmap from "../../icons/roadmap.png";
import { ColorRing } from "react-loader-spinner";
import moment from "moment";
import { context } from "../../App";
import { logConsole } from "../../utils/useConsole";

const NewRoadMap = ({ showLoading = true }) => {
  

  return (
    <div className='min-h-screen w-full bg-[url("/public/images/roadmap-bg.svg")] flex flex-col px-6 pt-8 bg-cover bg-no-repeat bg-center relative overflow-x-auto zoom-background'>
      <h2 className="text-white text-[2rem] font-bold">Roadmap</h2>
      <div className="relative min-h-fit transform scale-100">
        <img src={Roadmap} className="absolute top-1 center-absolute-el" />
        {/* <div className="flex flex-col-reverse">
                <p className="font-semibold text-[#FFE8E0] my-auto absolute top-[31.875rem] 2xs:left-[4rem] def:left-[5rem]">Support More Games</p>
                <div className="w-[26px] h-[34px]">
                    <ShieldHallow className="absolute top-[27.375rem] 2xs:left-[7.25rem] 12:left-[8rem] pix:left-[8.5rem] def:left-[9.188rem] h-full"/>
                </div>
            </div> */}

        {showLoading && (
          <div className="flex flex-col gap-1 w-fit mx-auto absolute top-[33.375rem] center-absolute-el">
            <ColorRing
              height="91"
              width="91"
              colors={["#84C70D", "#D9772F", "#D9772F", "#C77CBD", "#C77CBD"]}
              ariaLabel="circles-loading"
              wrapperClass="loader-spinner"
              visible={true}
            />
            <p className="font-bold text-[24px] text-white">Loading</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewRoadMap;
