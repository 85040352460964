import { config } from "../config";
import { AxiosConnect } from "./auth.service";

export class UpgradeConnect {

    async getMines(telegramId, project_id) {
        const { data: response } = await AxiosConnect.get(`${config.SERVER_URL}/mine/${telegramId}/${project_id}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async Mint({ telegramId, project_id, mine_id }) {
        const { data: response } = await AxiosConnect.post(`${config.SERVER_URL}/mine/mint`,{
            project_id,
            telegramId,
            mine_id
        });
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        console.error('Error message:', errorMessage);
        if(errorMessage === "An issue has arisen. Please try again later."){
            errorMessage = "Please ensure that you are a waitlist member and use the email to send us a message"
        }
        return errorMessage;
    }
}