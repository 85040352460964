import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";
import TonCoin from "../../../icons/toncoin_big.png";
import WalletIcon from "../../../icons/Wallet.svg";
import CopyIcon from "../../../icons/CopyGreen.svg";
import { context } from "../../../App";
import { useContext } from "react";
import { HapticFeedback } from "../../../utils/HapticFeedback";
import SoundPlayer from "../../../utils/GameSounds";

const ConnectTonWalletModal = ({
  open,
  setOpen,
  disconnectWallet,
  address,
}) => {
  const { triggerNotification } = useContext(context);
  const handleCopyClick = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        triggerNotification("success", "Wallet address copied!");
        HapticFeedback();
        SoundPlayer.playSound("menu2");
      })
      .catch((err) => {
        triggerNotification("error", `Failed to copy address`);
      });
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        className="md:w-[24.7rem] md:mx-auto overflow-x-scroll"
      >
        <div 
        key={address}
        className="flex flex-col justify-center w-full">
          <img
            src={TonCoin}
            alt={"connect ton wallet"}
            className="rounded-md mx-auto mb-3 w-[6.6rem] h-[6.7rem]"
          />
          <h4 className="text-[28px] font-bold text-center mb-2">
            Your TOn wallet is <br /> connected
          </h4>
          <p className="text-[#848AA0] text-[15px] font-semibold text-center mb-8">
            Copy your wallet address below
          </p>
          <div className="flex flex-row justify-between items-center w-[19.5rem] h-[3.125rem] mx-auto rounded-[45px] bg-[#0D0F18] px-4 cursor-pointer mb-6">
            <img
              src={WalletIcon}
              alt="connect your ton wallet"
              className="w-[1.5rem] h-[1.5rem]"
            />
            <p className="text-[15px] font-semibold text-[#EBEFFF]">
              {address.length > 10 && address.substring(0,10) + "..." + address.substring(address.length - 10, address.length)}
            </p>
            <img
              src={CopyIcon}
              style={{ cursor: "pointer" }}
              className="w-[1.5rem] h-[1.5rem]"
              onClick={() => handleCopyClick(address)}
            />
          </div>
          <p
            className="text-center text-[15px] font-semibold text-[#F67373]"
            onClick={disconnectWallet}
          >
            Disconnect
          </p>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ConnectTonWalletModal;
