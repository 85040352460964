import React, { useState } from "react";
import { useContext } from "react";
import { context } from "../../App";
import { RefillModal } from "../modals/RefillModal";
import DollarCoin from "../../icons/dollar-coin.svg";
import SoundPlayer from "../../utils/GameSounds";
import { HapticFeedback } from "../../utils/HapticFeedback";

export const DailyBoosters = ({
  refill,
  getRefill,
  remainingTime,
  disableBtn,
}) => {
  const { projects, currentProjectIndex } = useContext(context);
  const [openRefillModal, setOpenRefillModal] = useState(false);

  const useRefill = async () => {
    HapticFeedback();
    await getRefill();
    setOpenRefillModal(false);
    SoundPlayer.playSound("success2");
  };

  return (
    <div className="mb-6">
      <h2 className="text-white text-[1.25rem] mb-2 text-left">
        Free Daily Boosters
      </h2>
      <div
        className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2"
        onClick={(e) => setOpenRefillModal(true)}
      >
        <div className="flex items-center flex-row-reverse gap-3">
          <div>
            <div className="text-[0.9375rem] text-white">
              Free Energy Refill
            </div>
            <div className="text-[0.9375rem] text-[#848aa0]">
              {`${
                6 - (projects?.[currentProjectIndex]?.boostCount ?? 0)
              }/${"6"}` ||
                refill?.remainingBoost ||
                "..."}{" "}
              Remaining
            </div>
          </div>
          <img className="fi-6" alt="Fi" src={DollarCoin} />
        </div>

        <div className="text-[0.9375rem] text-[#848aa0]">
          {remainingTime || refill?.message || "Loading..."}
        </div>
      </div>
      <RefillModal
        open={openRefillModal}
        setOpen={setOpenRefillModal}
        onClick={useRefill}
        disableBtn={disableBtn}
        disabledText={remainingTime || refill?.message || "Loading..."}
      />
    </div>
  );
};
