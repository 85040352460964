import { logConsole } from "../utils/useConsole";
import { AxiosConnect } from "./auth.service";

export class UserConnect {
  async userUserProjectDetails(telegramId) {
    const { data: response } = await AxiosConnect.get(
      `/user/user-projects-details/${telegramId}`
    );
    localStorage.setItem("token", response?.data?.token);
    if (!response || !response.data) {
      alert("Unable to complete action");
    } else {
      return { data: response?.data?.usersWithProjectSymbols };
    }
  }

  async getDashboard(telegramId) {
    const { data: response } = await AxiosConnect.get(
      `/user/dashboard/${telegramId}`
    );
    if (!response || !response.data) {
      alert("Unable to complete action");
    } else {
      return response;
    }
  }

  async validateData(data) {
    const { data: response } = await AxiosConnect.post(`/user/validate-data`, data);
    if (!response || !response.data) {
      alert("Unable to complete action");
      return response;
    } else {
      return response;
    } 
  }



  async useTap(data) {
    const { data: response } = await AxiosConnect.post(`/user/tap-in`, data);
    if (!response || !response.data) {
      alert("Unable to complete action");
    } else {
      return response;
    }
  }

  async CheckReferralGain(telegramId) {
    const { data: response } = await AxiosConnect.get(`/user/check-ref-gain/${telegramId}`);
    if (!response || !response.data) {
      alert("Unable to complete action");
    } else {
      return response;
    }
  }

  async getReferralGain(telegramId) {
    const { data: response } = await AxiosConnect.get(`/user/get-ref-gain/${telegramId}`);
    if (!response || !response.data) {
      alert("Unable to complete action");
    } else {
      return response;
    }
  }

  async connectWallet(data) {
    const { data: response } = await AxiosConnect.post(`/user/connect-wallet/`, data);
    if (!response || !response.data) {
      alert("Unable to complete action");
    } else {
      return response;
    }
  }

  async connectEVMWallet(data) {
    const { data: response } = await AxiosConnect.post(`/user/connect-evm-wallet/`, data);
    if (!response || !response.data) {
      alert("Unable to complete action");
    } else {
      return response;
    }
  }

  async disConnectWallet(telegramId) {
    const { data: response } = await AxiosConnect.get(`/user/disconnect-wallet/${telegramId}`);
    if (!response || !response.data) {
      alert("Unable to complete action");
    } else {
      return response;
    }
  }

  async requestHardreset(telegramId) {
    const { data: response } = await AxiosConnect.get(
      `/user/request-reset/${telegramId}`
    );
    if (!response || !response.data) {
      alert("Unable to complete action");
    } else {
      return response;
    }
  }

  handleTheError(AxiosError) {
    let errorMessage =
      AxiosError.response?.data?.message ||
      AxiosError.message ||
      "Request failed";
    console.error("Error message:", errorMessage);
    if (errorMessage === "An issue has arisen. Please try again later.") {
      errorMessage =
        "Please ensure that you are a waitlist member and use the email to send us a message";
    }
    return errorMessage;
  }
}
