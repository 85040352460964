import DollarCoin from "../../icons/dollar-coin.svg";

export const FriendList = ({ friends, friendsLength }) => {
  return (
    <div>
      <h2 className="text-white text-[1.1rem] mb-3 text-left">
        List of your friends{" "}
        {friendsLength > 0 && `[${friendsLength.toLocaleString()}]`}
      </h2>
      <div className="flex gap-1 flex-col">
        {Array.isArray(friends) && friends?.length > 0 ? (
          friends.map((friend, index) => (
            <div
              key={index}
              className="cursor-pointer flex items-center justify-between bg-[#151827] h-[3.625rem] rounded-[7px] px-3"
            >
              <div className="flex items-center gap-3">
                <img
                  className="w-[2.375rem] h-[2.375rem] rounded-[2.375rem] object-contain"
                  alt="avatar"
                  src={friend?.image}
                />
                <div className="text-[0.9375rem] text-white font-bold">
                  {friend?.name}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <img
                  src={DollarCoin}
                  alt="coin"
                  className="w-[0.75rem] h-[0.75rem]"
                />
                <span className="text-[#F28A00] text-[0.9375rem] font-bold">
                  +{friend?.gain}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="border-2 border-solid border-[#151827] rounded-[0.9rem] bg-[#151827] min-h-[7.75rem] flex items-center justify-center">
            <span className="text-[0.8rem] text-[#5D657E] font-bold">
              You haven’t invited anyone yet
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
