import DailyComboBoxIcon from "../../icons/dailyComboBox.png"

const DailyComboItem = ({image, name, onClick}) => {
  return (
    <div 
      className="w-full min-h-[6.13rem] h-[6.13rem] rounded-lg flex flex-col items-center justify-center align-middle cursor-pointer bg-[#0D0F18] dailyComboItemShadow"
      onClick={onClick ?? null}
    >
      {
        image && name ? <>
          <img src={image} alt={name} className="w-[45%] mb-2" />
          <p className="capitalize font-semibold text-[10px]">{name}</p>
        </> : 
        <img src={DailyComboBoxIcon} alt="coming soon" className="w-[6.6rem] h-[6.13rem] rounded-lg m-auto" />
      }
    </div>
  )
}

export default DailyComboItem