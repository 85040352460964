import { ModalLayout } from "../ModalLayout";
import Image from "../../../icons/inviteFriends.png";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import { logConsole } from "../../../utils/useConsole";
import DollarCoin from "../../../icons/dollar-coin.svg";

const InviteFriendsModal = ({ open, setOpen, linked, title, reward, data }) => {
  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      icon={Image}
      content={
        <div>
          <div className="mx-auto">
            <p className="text-white text-[1.5rem] text-center mb-1">{title}</p>
          </div>

          <div className="flex items-center justify-center gap-1 mt-4">
            <img src={DollarCoin} alt="coin" className="h-[2rem] w-[2rem]" />
            <div className="text-[2rem] font-bold">+{reward}</div>
          </div>

          <div className="flex justify-center mt-6">
            <ButtonPrimary
              text={"Claim Reward"}
              onClick={() =>
                data.completeTask(
                  data.action,
                  data.linked,
                  data.link,
                  data.reward
                )
              }
              disabled={linked}
              disabledText={"Claim Reward"}
              className={"!w-[18rem] mx-auto text-[20px]"}
            />
          </div>
        </div>
      }
    />
  );
};

export default InviteFriendsModal;
