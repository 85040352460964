import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { context } from "../../App";
import SoundPlayer from "../../utils/GameSounds";
import Rocket from "../../icons/rocket.svg";
import Lightingbolt from "../../icons/lightning.svg";
import Marketplace from "../../icons/marketplace.svg";

function Boosters() {
  const { setModal, energyLevel, remainingEnergyLevel } = useContext(context);

  return (
    <div
      className="flex justify-between items-center px-5 absolute bottom-0 w-full mb-5"
      style={{ height: "10%" }}
    >
      <button type="button" className="flex items-center">
        <img className="fi-4" alt="energy" src={Lightingbolt} />
        <div className="text-[0.875rem] text-white font-bold">
          {Math.floor(remainingEnergyLevel)}/{Math.floor(energyLevel)}
        </div>
      </button>

      <Link to={"/marketplace"}>
        <button
          type="button"
          className="flex items-center gap-1 bg-[#1D202B] rounded-[2.5rem] w-fit px-3 py-2"
          onClick={() => SoundPlayer.playSound("menu2")}
        >
          <img className="fi-5" alt="boost" src={Marketplace} />
          <div className="text-[0.875rem] text-white font-bold">
            Marketplace
          </div>
        </button>
      </Link>

      <Link to={"/boosters"}>
        <button
          type="button"
          className="flex items-center gap-1 bg-[#1D202B] rounded-[2.5rem] w-fit px-3 py-2"
          onClick={() => SoundPlayer.playSound("menu2")}
        >
          <img className="fi-5" alt="boost" src={Rocket} />
          <div className="text-[0.875rem] text-white font-bold">Boost</div>
        </button>
      </Link>

      {/* <Link to={"/upgrades"}>
        <span style={{ cursor: "pointer" }}>
          <img
            className="fi"
            alt="upgrades"
            src="https://c.animaapp.com/SG58ovQI/img/fi-6774954.svg"
          />
          <div className="text-[8px] text-white font-medium mt-1">Upgrades</div>
        </span>
      </Link> */}
    </div>
  );
}

export default Boosters;
