import React, { useContext } from "react"
import Actions from "../Actions";
import { Fade, Slide } from "react-awesome-reveal";
import { Timeline } from "./Timeline";
import AirdropCoin from "../../icons/moreAirdropsLarge.png";
import TonCoin from "../../icons/toncoin.png";
import { DoneRingWhiteIcon, ExpandLeftWhiteIcon } from "../../icons";
import { useEffect, useState } from "react";
import ConnectTonWalletModal from "../modals/ConnectTonWallet";
import { useTonConnectModal, TonConnectButton, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { logConsole } from "../../utils/useConsole";
import { context } from "../../App";
import { UserConnect } from "../../services/user.service";

export const AirdropComponents = () => {
  const user = new UserConnect();
  const [connected, setConnected] = useState(false);
  const { triggerNotification, telegramId } = useContext(context)
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [address, setAddress] = useState(null);
  const { state, open, close } = useTonConnectModal();
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);

  const updateWallet = async (action, walletAddress) => {
    
    if(action == "connect"){
      
      const { data: response } = await user.connectWallet({ telegramId, walletAddress: walletAddress || address });
      if(response) {
        logConsole({ updateWallet: response }, true)
        return;
      }
    } else if(action == "disconnect") {
      const { data: response }= await user.disConnectWallet(telegramId);
      if(response){
        logConsole({ updateWalletDisconnect: response }, true)
        return;
      }
    }
  }

  const allowConnection = async () => {
    try {
      await open();
      await updateWallet("connect", userFriendlyAddress);
    } catch (error) {
      console.error("Error connecting to wallet:", error);
    }
  };

  const allowDisconnect = async () => {
    try {
      await tonConnectUI.disconnect();
      await close();
      setConnected(false);
      setShowWalletModal(false);
      setAddress(null);
      await updateWallet("disconnect");
      triggerNotification("success", "Wallet disconnected");
    } catch (error) {
      console.error("Error disconnecting from wallet:", error);
      logConsole({ errorMessage: user.handleTheError(error) }, true);
    }
  };

  useEffect(() => {
    if (state?.closeReason !== "wallet-selected") {
      const checkConnection = async () => {
        logConsole({ state }, true);
        if (state?.wallet?.account) {
          setAddress(state.wallet.account.address);
          await updateWallet("connect", state.wallet.account.address);
          setConnected(true);
        }
      };
      checkConnection();
    } else if(state?.closeReason == "wallet-selected") {
      setConnected(true);
      const revConnection = async () => {
        await updateWallet("connect", userFriendlyAddress);
      } 
      revConnection();
      setAddress(userFriendlyAddress);
    }
  }, [state]);

  return (
    <div className="relative w-full max-w-full h-full min-h-screen">
      <div className="px-4 pb-[6rem]">
        <div className="pt-3">
          <Slide direction={"down"} duration={200}>
            <div className="relative py-8 flex items-center justify-center">
              <div className="h-[8.75rem] w-[8.75rem] mx-auto flex items-center justify-center">
                <img
                  src={AirdropCoin}
                  className="max-h-[8.75rem] max-w-[8.75rem] h-full w-full object-fill w-auto mx-auto"
                  alt="Raider"
                />
              </div>
              <div
                className="absolute top-0 bottom-0 left-0 right-0 m-auto w-[8.7rem] h-[8.7rem]"
                style={{
                  boxShadow: `0px 5.2px 68px 0px #F28A00`,
                  filter: "blur(3px)",
                  borderRadius: "50%",
                }}
              />
            </div>
          </Slide>

          <div className="text-center">
            <Fade duration={250}>
              <h2
                className="text-white text-[1.5rem] font-bold mb-2"
                style={{ lineHeight: "27px" }}
              >
                Clan of Raiders Airdrop <br /> coming soon!
              </h2>
            </Fade>

            <p className="text-[#848aa0] text-[0.93rem] font-semibold">
              Keep earning coins to become the ultimate and richest warrior in
              your kingdom
            </p>
          </div>

          <div className="w-[10.63rem] mx-auto h-[2px] bg-[#1C1F2D] my-6" />

          <Timeline />
        </div>

        <p className="text-center mt-10 mb-4">Connect your Wallet</p>
        <div
          className="flex flex-row justify-between items-center w-[19.5rem] h-[3.125rem] mx-auto rounded-[45px] bg-[#0193DF] px-4 cursor-pointer"
          onClick={
            connected ? () => setShowWalletModal(true) : () => allowConnection()
          }
        >
          <img
            src={TonCoin}
            alt="connect your ton wallet"
            className="w-[2.4rem] h-[2.4rem]"
          />
          <p className="text-sm font-semibold">
            {connected ? "Your wallet is connected" : "Connect your TON wallet"}
          </p>
          {connected ? (
            <DoneRingWhiteIcon
              style={{ cursor: "pointer" }}
              className="w-[1.5rem] h-[1.5rem]"
            />
          ) : (
            <ExpandLeftWhiteIcon
              style={{ cursor: "pointer" }}
              className="w-[1.5rem] h-[1.5rem]"
            />
          )}
        </div>

        {!connected && (
          <div className="text-center text-xs font-semibold text-[#848AA0] flex flex-col gap-4 mt-8">
            <p>
              Connect your TON crypto wallet to receive <br /> project airdrop
              rewards.
            </p>
            <p>
              {" "}
              Don’t have TON wallet?{" "}
              <a
                href="https://blog.ton.org/how-to-create-and-start-using-wallet"
                target="_blank"
                className="cursor-pointer underline text-[#83c60d]"
              >
                Learn how <br /> to create one
              </a>
            </p>
          </div>
        )}
      </div>

      <ConnectTonWalletModal
        open={showWalletModal}
        address={userFriendlyAddress}
        setOpen={setShowWalletModal}
        disconnectWallet={() => {
          allowDisconnect();
        }}
      />

      <div className="fixed bottom-0 max-w-[450px] w-full flex">
        <Actions />
      </div>
    </div>
  );
};
