import React from "react";
import { forwardRef } from "react";

export const SwitchComponent = forwardRef((props, forwardedRef) => {
  const handleChange = (e) => {
    props?.onChange();
    props?.onClick();
  };

  return (
    <label className="cursor-pointer">
      <input
        type={"checkbox"}
        className="hidden"
        ref={forwardedRef}
        checked={props?.checked}
        onChange={handleChange}
      />
      <div
        className={`w-14 p-1 rounded-full transition-all duration-300 text-white ${
          props?.checked ? "bg-[#83C60D]" : "bg-[#848AA0]"
        } `}
      >
        <div
          className={`${
            props?.checked ? "translate-x-6" : ""
          } bg-[#D9D9D9] w-[1.25rem]  h-[1.25rem] rounded-[1.25rem]`}
        />
      </div>
    </label>
  );
});
