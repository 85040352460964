import React, { useState, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import Avatar from "../../icons/avatar.png";
import { ReactComponent as Raid } from "../../icons/raid.svg";
import useClanImage from "../../utils/useClanImage";

export const OtherRaiders = ({ leaderBoard }) => {
  const [clanImages, setClanImages] = useState({});

  useEffect(() => {
    leaderBoard.forEach(user => {
      const clanName = user?.raider?.name;
      if (clanName && !clanImages[clanName]) {
        import(`../../assets/Clans/${clanName}.png`)
          .then(image => {
            setClanImages(prevImages => ({ ...prevImages, [clanName]: image.default }));
          })
          .catch(err => console.error('Error loading image:', err));
      }
    });
  }, [leaderBoard, clanImages]);

  function getSuffix(index) {
    let suffix = "th";
    if (index % 10 === 1 && index % 100 !== 11) {
      suffix = "st";
    } else if (index % 10 === 2 && index % 100 !== 12) {
      suffix = "nd";
    } else if (index % 10 === 3 && index % 100 !== 13) {
      suffix = "rd";
    }
    return suffix;
  }

  return (
    <div className="flex flex-col gap-3 mb-8">
      <Slide direction={"up"} duration={500}>
        {(leaderBoard && leaderBoard.length) ? leaderBoard.map((user, index) => (
          <div key={index} className="p-4 bg-[#151827] rounded-[5px]">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <div className="relative">
                  <img
                    src={user?.image || Avatar}
                    alt="avatar"
                    className="w-[2.5rem] h-[2.5rem] rounded-[2.5rem]"
                  />
                  <img
                    src={clanImages[user?.raider?.name] || user?.raider?.image}
                    alt={user?.raider?.name}
                    className="w-[1.125rem] h-[1.125rem] rounded-[0.25rem] absolute bottom-1 -right-1.5"
                  />
                </div>

                <div>
                  <p className="font-semibold text-[#848AA0] text-[0.875rem]">
                    {user?.raider?.name}
                  </p>
                  <p className="font-semibold text-white text-[0.875rem]">
                    {user?.username}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <Raid />
                <div>
                  <p className="font-semibold text-[#848AA0] text-[0.8125rem]">
                    Rank
                  </p>
                  <p className="font-semibold text-white text-[0.8125rem]">
                    {user?.rank || (index + 1)}{getSuffix(user?.rank || (index + 1))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )) : <></>}
      </Slide>
    </div>
  );
};
