import React, { useState, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import Avatar from "../../icons/avatar.png";
import Clan from "../../icons/clan-mini.png";
import DollarCoin from "../../icons/dollar-coin.svg";
import { ReactComponent as Raid } from "../../icons/raid.svg";

export const Leaderboard = ({ leaderBoard, currentStep }) => {
  const currentStep_ = currentStep - 1;

  const [clanImages, setClanImages] = useState({});

  useEffect(() => {
    if(leaderBoard[currentStep_]?.users){
      leaderBoard[currentStep_]?.users?.forEach(user => {
        const clanName = user?.raider?.name;
        if (clanName && !clanImages[clanName]) {
          import(`../../assets/Clans/${clanName}.png`)
            .then(image => {
              setClanImages(prevImages => ({ ...prevImages, [clanName]: image.default }));
            })
            .catch(err => console.error('Error loading image:', err));
        }
      });
    }
  }, [leaderBoard, clanImages, currentStep]);

  function getSuffix(index) {
    let suffix = "th";
    if (index % 10 === 1 && index % 100 !== 11) {
      suffix = "st";
    } else if (index % 10 === 2 && index % 100 !== 12) {
      suffix = "nd";
    } else if (index % 10 === 3 && index % 100 !== 13) {
      suffix = "rd";
    }
    return suffix;
  }

  return (
    <div className="flex flex-col gap-3 mb-12 mt-6">
      <Slide direction={"up"} duration={500}>
        {leaderBoard &&
          leaderBoard[currentStep_]?.users.map((leader, index) => (
            <div key={index} className="p-4 bg-[#151827] rounded-[5px]">
              <div className="flex gap-1.5 justify-between items-center w-full">
                <div className="flex items-center gap-4">
                  <div className="relative flex-none">
                    <img
                      src={Avatar}
                      alt="avatar"
                      className="w-[2.5rem] h-[2.5rem] rounded-[2.5rem]"
                    />
                    <img
                      src={clanImages[leader?.raider?.name] || leader?.raider?.image}
                      alt={leader?.raider?.name}
                      className="w-[1.125rem] h-[1.125rem] rounded-[0.25rem] absolute bottom-1 -right-1.5"
                    />
                  </div>

                  <div>
                    <p className="font-semibold text-[#848AA0] text-[0.875rem]">
                      {"Raider"}
                    </p>
                    <p className="font-semibold text-white text-[0.875rem] text-ellipsis overflow-hidden max-w-[95px] w-[95px]">
                      {leader.username}
                    </p>
                  </div>
                </div>

                <div className="min-w-[110px]">
                  <p className="font-semibold text-[#848AA0] text-[0.875rem]">
                    Loot per hour
                  </p>
                  <div className="flex items-center gap-1">
                    <img
                      src={DollarCoin}
                      alt="coin"
                      className="w-[0.75rem] h-[0.75rem]"
                    />
                    <div className="font-semibold text-white text-[0.875rem]">
                      {(leader?.profitPerHour ?? 0).toLocaleString()}
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Raid />
                  <div>
                    <p className="font-semibold text-[#848AA0] text-[0.8125rem]">
                      Rank
                    </p>
                    <p className="font-semibold text-white text-[0.8125rem]">
                      {" "}{index + 1}{getSuffix(index + 1)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slide>
    </div>
  );
};
