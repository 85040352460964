import { useContext } from "react";
import { context } from "../App";

const useCopyClickHandler = () => {
  
  const { triggerNotification } = useContext(context);

  const handleCopyClick = ({ text, referralLink }) => {
    navigator.clipboard
      .writeText(`${text} ${referralLink}`)
      .then(() => {
        triggerNotification("success", "link copied!");
      })
      .catch((err) => {
        triggerNotification("error", `Failed to copy link`);
      });
  };

  return handleCopyClick;
};

export default useCopyClickHandler;
