import { config } from "../config";
import { AxiosConnect } from "./auth.service";

export class ExchangeConnect {

    async getUserExchange(telegramId, project_id) {
        const { data: response } = await AxiosConnect.get(`/user/user-exchange/${telegramId}/${project_id}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async getBoosters(telegramId, project_id) {
        const { data: response } = await AxiosConnect.get(`/user/boosters/${telegramId}/${project_id}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async getFullEnergy(telegramId, project_id) {
        const { data: response } = await AxiosConnect.get(`/user/boost/${telegramId}/${project_id}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async getMultiTap(telegramId, project_id) {
        const { data: response } = await AxiosConnect.get(`/user/multi-tap/${telegramId}/${project_id}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async getEnergyLimit(telegramId, project_id) {
        const { data: response } = await AxiosConnect.get(`/user/energy/${telegramId}/${project_id}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        console.error('Error message:', errorMessage);
        if(errorMessage === "An issue has arisen. Please try again later."){
            errorMessage = "Please ensure that you are a waitlist member and use the email to send us a message"
        }
        return errorMessage;
    }
}