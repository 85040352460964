import { config } from "../config";
import { AxiosConnect } from "./auth.service";

export class MarketPlaceConnect {

  async todaysUserDailyCombo(telegramId) {
    const { data: response } = await AxiosConnect.get(`/combo/user-daily-combo/${telegramId}`);
    if (response && response.data) {
      return response;
    }
  }

  async claimDailyCombo(telegramId, combo_id) {
    const { data: response } = await AxiosConnect.post(`/combo/claim-combo/`, { telegramId, combo_id });
    if (response && response.data) {
      return response;
    }
  }

  async getItems(telegramId) {
    const { data: response } = await AxiosConnect.get(`/mine/user/${telegramId}`);
    if (response && response.data) {
      return response;
    }
  }

  async mineItem(telegramId, mine_id) {
    const { data: response } = await AxiosConnect.post(`/mine/mint`, { telegramId, mine_id });
    if (response && response.data) {
      return response;
    }
  }


    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        return errorMessage;
    }
}
