import React from "react";
import { Zoom } from "react-awesome-reveal";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import DollarCoin from "../../../icons/dollar-coin.svg";
import Logo from "../../../icons/hammer-icon.png";
import { ReactComponent as Lock } from "../../../icons/lock_fill.svg";
import { ReactComponent as Tick } from "../../../icons/tick-star.svg";
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";
import { formatNumber } from "../../../utils/formatNumber";

export const JoinProjectModal = ({ open, setOpen, data, message, joined, btn, onClick }) => {
  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        className="md:w-[24.7rem] md:mx-auto max-h-[90vh] overflow-x-scroll"
      >
        <div className="pt-10 pb-10">
          <Zoom duration={850}>
            <div className="relative w-fit mx-auto">
              <img
                className="h-[6.4375rem] w-[6.4375rem] mx-auto mb-3 object-fill"
                alt="Logo"
                src={data?.logo}
              />
              <Tick className="w-12 h-12 absolute -bottom-2 -right-4" />
            </div>
          </Zoom>
          <div>
            <div className="max-w-[270px] mx-auto">
              <p className="text-white text-[2rem] leading-[2.5rem] text-center">{`Join`}</p>
              <p className="text-white text-[2rem] leading-[2.5rem] text-center">
                {data?.name}
              </p>
              <p className="text-white text-[2rem] leading-[2.5rem] text-center">{`airdrop`}</p>

              <div className="text-[#F28A00] text-[0.9375rem] text-center max-w-[240px] mx-auto mt-4">
                <p>
                  {message}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center gap-2 mt-6 max-w-[230px] mx-auto">
              <div className="text-[1.625rem] font-semibold">Entry</div>
              <img
                src={DollarCoin}
                alt="coin"
                className="h-[1.875rem] w-[1.875rem]"
              />
              <div className="text-[1.625rem] font-semibold">{formatNumber(data?.cost)}</div>
            </div>

            <div className="flex justify-center mt-6">
              <ButtonPrimary
                text={`Join Airdrop`}
                disabled={(message !== null) || joined || btn}
                disabledText={<div className='flex items-center gap-2'><Lock /> Join Airdrop</div>}
                onClick={onClick}
                style={{ width: "90%" }}
              />
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
