import { useEffect, useRef } from "react"
import { Modal, ModalOverlay } from "../ModalLayout/NewModalLayout"

const UnlockedDailyComboOverlay = ({ open, setOpen, data, combo, hasItems }) => {
  const containerRef = useRef();

  useEffect(()=>{
    let isMounted = true;

    const handleClickOutside = (event) => {
      if(isMounted) {
        if(containerRef?.current && !containerRef?.current.contains(event.target)) setOpen(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      isMounted = false;
    };
  },[]);

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalOverlay>
        <div className="flex justify-center min-h-screen items-center align-middle">
          <div className="flex flex-col gap-8 h-fit my-auto justify-center text-center items-center align-middle">
            <div className="flex flex-col gap-1">
              <p className="font-semibold text-xl">Daily combo</p>
              <div className="flex flex-row justify-center items-center gap-1">
                {hasItems && Array(3).fill().map((_, index) => (
                  data.step >= (index + 1) ?
                  <div key={index} className="bg-[#83C60D] h-[0.9375rem] w-[0.9375rem] rounded-full flex justify-center items-center text-black text-[10px]">
                    &#10004;
                  </div> : 
                  <div className="h-[0.9375rem] w-[0.9375rem] rounded-full !border-2 border-solid border-[#585F7D]" />
                ))}
              </div>
            </div>
            <div ref={containerRef} className="relative w-[22rem] h-[21.9rem] bg-[url('/public/images/dailyComboBg.png')] bg-cover bg-center bg-no-repeat flex justify-center items-center">
              <img
                src={'/images/daily-combo.png'}
                alt={'claimed daily combo'}
                className="rounded-md mx-auto mb-3 animate-bounce"
                style={{ width: "10.94rem", height: "10.94rem" }}
              />
            </div>
            <p className="font-semibold text-xl">
              { hasItems && <>Hurrah! You have unlocked { data.position ? <><br />the {data?.position} card. {data.position == "Third" ? <></> : <>Keep <br />looting.</>}</> : <> a card. Keep <br />looting. </>}</> }
            </p>
          </div>
        </div> 
      </ModalOverlay>
    </Modal>
  )
}

export default UnlockedDailyComboOverlay