import React from "react";
import { CharacterComponents } from "../components/Character";

function Characters() {
  return (
    <div>
      <CharacterComponents />
    </div>
  );
}

export default Characters;
