import QRCode from 'react-qr-code';

function PageNotFound() {
  return (
    <div className="min-h-screen flex flex-col gap-4 justify-center items-center text-center text-white">
      <h1 className='text-2xl font-bold'>Play on mobile</h1>
      <p className='font-semibold'>More fun and adventures awaits</p>
      
      <div className='w-[15.1875rem] h-[15.1875rem] mx-auto rounded-2xl bg-white p-2'>
        <QRCode 
          value='https://t.me/@clanofraidersbot'
          fgColor='#0d0f18'
          level='Q'
          style={{
            borderRadius: '1rem',
            maxHeight: '100%',
            maxWidth: '100%'
          }}
        />
      </div> 

      <p className='text-2xl font-semibold'>@clanofraiders</p>
    </div>
  )
}

export default PageNotFound;