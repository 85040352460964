import coinTap from "../audio/cointap.mp3";
import error from "../audio/error.mp3";
import menu1 from "../audio/menu1.mp3";
import menu2 from "../audio/menu2.mp3";
import success1 from "../audio/success1.mp3";
import success2 from "../audio/success2.mp3";

/**
 * @typedef {Object.<string, HTMLAudioElement>} Sounds
 */

class SoundPlayer {
  static audioContext = new (window.AudioContext ||
    window.webkitAudioContext)();
  static soundBuffers = {};
  static isReady = false;

  static sounds = {
    coinTap: new Audio(coinTap),
    error: new Audio(error),
    menu1: new Audio(menu1),
    menu2: new Audio(menu2),
    success1: new Audio(success1),
    success2: new Audio(success2),
  };

  static async initializeSounds() {
    const soundFiles = {
      coinTap: coinTap,
      error: error,
      menu1: menu1,
      menu2: menu2,
      success1: success1,
      success2: success2,
    };
    const loadPromises = Object.entries(soundFiles).map(([key, value]) =>
      SoundPlayer.loadSoundFile(key, value)
    );
    await Promise.all(loadPromises);
    SoundPlayer.isReady = true;
  }

  static loadSoundFile = async (soundName, file) => {
    const response = await fetch(file);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await SoundPlayer.audioContext.decodeAudioData(
      arrayBuffer
    );
    SoundPlayer.soundBuffers[soundName] = audioBuffer;
  };

  static resumeAudioContext() {
    if (SoundPlayer.audioContext.state === "suspended") {
      return SoundPlayer.audioContext.resume();
    }
    return Promise.resolve();
  }

  static getSoundSetting = () => {
    var setting = localStorage.getItem("soundEnabled");
    if (setting === null) {
      localStorage.setItem("soundEnabled", "true");
      return true;
    } else if (setting === "true") {
      return true;
    } else {
      return false;
    }
  };

  static playSound = (soundName) => {
    if (this.getSoundSetting()) {
      if (!SoundPlayer.isReady) {
        console.error("Sounds are not ready");
        return;
      }
      const soundBuffer = SoundPlayer.soundBuffers[soundName];
      if (soundBuffer && SoundPlayer.getSoundSetting()) {
        const source = SoundPlayer.audioContext.createBufferSource();
        source.buffer = soundBuffer;
        source.connect(SoundPlayer.audioContext.destination);
        source.start(0);
      } else {
        console.error(
          `Sound '${soundName}' not found or sound setting disabled`
        );
      }
    }
  };

  static playSoundAsBackground = (soundName) => {
    const sound = this.sounds[soundName];
    if (sound) {
      if (!sound.paused) {
        sound.currentTime = 0; // Restart sound if it is already playing
        sound.volume = 0.35;
        sound.play();
      }
      sound
        .play()
        .catch((error) => console.error("Error playing sound:", error));
    } else {
      console.error(`Sound '${soundName}' not found`);
    }
  };

  static playSoundMultiple = (soundName) => {
    if (!SoundPlayer.isReady) {
      console.error("Sounds are not ready");
      return;
    }
    const soundBuffer = SoundPlayer.soundBuffers[soundName];
    if (soundBuffer && SoundPlayer.getSoundSetting()) {
      const source = SoundPlayer.audioContext.createBufferSource();
      source.buffer = soundBuffer;
      source.connect(SoundPlayer.audioContext.destination);
      source.start(0);
    } else {
      console.error(`Sound '${soundName}' not found or sound setting disabled`);
    }
  };
}

// Preload sounds
new SoundPlayer();
SoundPlayer.loadSoundFile("coinTap", coinTap);
SoundPlayer.loadSoundFile("menu1", menu1);
SoundPlayer.loadSoundFile("menu2", menu2);
SoundPlayer.loadSoundFile("success2", success2);
SoundPlayer.loadSoundFile("success1", success1);
SoundPlayer.loadSoundFile("error", error);

// Initialize sounds on app start
SoundPlayer.initializeSounds();

// Setup event listener for resuming audio context on user interaction
document.addEventListener("click", SoundPlayer.resumeAudioContext);
document.addEventListener("touchend", SoundPlayer.resumeAudioContext);
export default SoundPlayer;
