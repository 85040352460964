import { useState, useContext } from "react"
import ProductCard from "./ProductCard"
import ProductModal from "../modals/ProductModal";
import { logConsole } from "../../utils/useConsole";
import { context } from "../../App";

const ClanTab = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showProductModal, setShowProductModal] = useState(false);
  const [ mine_id, setMine_id ] = useState("");
  const { mineItem } = useContext(context);
  const [ btn, setBtn ] = useState(false);

  const selectProduct = (item) => {
    setSelectedProduct(item);
    setMine_id(item?.mine_id);
    setShowProductModal(true);
  }

  const buyItem = async () => {
    const cost = selectedProduct?.mineLevel?.cost ?? 0;
    setBtn(true);
    setShowProductModal(false);
    await mineItem(mine_id, cost);
    setBtn(false);
  }


  return (
    <div className="grid grid-cols-2 gap-4">
      {
        products?.map(product => (
          <ProductCard
            key={product.mine_id}
            name={product.title}
            level={product?.mineLevel?.level}
            price={product?.mineLevel?.cost}
            earnPerHour={product?.profitPerHourSum??0}
            image={product?.image}
            relatedProduct={product?.relatedProduct}
            locked={product?.locked}
            isLevelBased={product?.isLevelBased}
            isReferralBased={product?.isReferralBased}
            click={()=> !product?.locked && selectProduct({...product})}
          />
        ))
      }

      <ProductModal
        open={showProductModal}
        setOpen={setShowProductModal}
        data={selectedProduct}
        buyItem={()=> !selectedProduct?.locked && buyItem()}
        btn={btn}
      />
    </div>
  )
}

export default ClanTab