import React, { useContext } from "react";
import { context } from "../../App";
import styles from "./style.module.css";
import DollarCoin from "../../icons/dollar-coin.svg";
import { UpgradeConnect } from "../../services/upgrade.service";

function Mines({ data }) {
  const upgrade = new UpgradeConnect();
  const {
    telegramId,
    project_id,
    setModal,
    coins,
    profitPerHour,
    updateProject,
    setProfitPerHour,
    setCoins,
    userTap,
  } = useContext(context);

  const Mint = async (mine) => {
    try {
      const mine_id = mine?.mine_id;
      const data_ = { telegramId, project_id, mine_id };
      const taps = await userTap(coins);
      const { data } = await upgrade.Mint(data_);
      if (taps || data) {
        const profitPerHourInc = mine?.perHourGain || 0;
        setProfitPerHour(profitPerHour + profitPerHourInc);
        setCoins(coins - mine.cost);
        await userTap(coins - mine.cost);
        updateProject({
          project_id,
          coin: coins - mine.cost,
          profitPerHour: profitPerHour + profitPerHourInc,
        });
        setModal(() => ({
          isOpen: true,
          message: "Total Earn per Hour",
          description: mine?.description || mine?.title,
          coin: "+" + mine?.perHourGain.toString(),
          btnTxt: "Close",
        }));
      }
    } catch (error) {
      const Msg = upgrade.handleTheError(error);
      setModal(() => ({
        isOpen: true,
        message: Msg,
        coin: null,
        btnTxt: "Close",
      }));
    }
  };

  return (
    <div>
      <span style={{ cursor: "pointer" }}>
        <div className={styles.upgrades}>
          {data.map((item, index) => (
            <span
              onClick={() => Mint(item)}
              key={index}
              className={styles.upgradeFlex}
            >
              <div
                key={item._id}
                className={index % 2 === 0 ? styles.overlap2 : styles.overlap5}
              >
                <div className={styles.overlap3}>
                  <div className={styles.textWrapper7}>{item.title}</div>
                  <div
                    className={
                      index % 2 === 0
                        ? styles.textWrapper8
                        : styles.textWrapper12
                    }
                  >
                    Earn per hour
                  </div>
                  <div className={styles.frame}>
                    <img className={styles.fi} alt="coin" src={DollarCoin} />
                    <div className={styles.textWrapper9}>
                      +{item.perHourGain}
                    </div>
                  </div>
                  <div className={styles.div2}>
                    <div className={styles.groupWrapper}>
                      <img
                        className={styles.group}
                        alt="Group"
                        src={item.image}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.textWrapper10}>Level {item.level}</div>
                <div className={styles.frame2}>
                  <img className={styles.img} alt="coin" src={DollarCoin} />
                  <div className={styles.textWrapper11}>{item.cost}</div>
                </div>
              </div>
            </span>
          ))}
        </div>
      </span>
    </div>
  );
}

export default Mines;
