import React, { createContext, useContext, useRef, useState } from "react";
import "./style.css";
import Player from "./Player";
import Actions from "../Actions";
// import Boosters from "./Boosters";
import Progress from "./Progress";
import Coins from "./Coins";
import Headers from "./Headers";
import Hammer from "./Hammer";
// import Project from "./Project";
import { context } from "../../App";
import { useEffect } from "react";
import { v4 } from "uuid";
import DollarCoin from "../../icons/dollar-coin.svg";
import { HapticFeedback } from "../../utils/HapticFeedback";
import { logConsole } from "../../utils/useConsole";
import SoundPlayer from "../../utils/GameSounds";
import ReferralModal from "../modals/ReferralModal";
import { LootModal } from "../modals/LootModal";
import { UserConnect } from "../../services/user.service";
import OfflineClanRaidModal from "../modals/OfflineClanRaidModal";
import useClanImage from "../../utils/useClanImage";

export const gameContext = createContext({});

export const Game = () => {
  const auth = new UserConnect();
  const screen_id = document.getElementById("screen");
  const [enableAnimation, setEnableAnimation] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [animations, setAnimations] = useState([]);
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [referralBtn, setReferralBtn] = useState(false);
  const [referralTitle, setReferralTitle] = useState("");
  const [referralReward, setReferralReward] = useState(0);
  const {
    coins,
    setCoins,
    userTap,
    projects,
    project_id,
    setProjects,
    currentProjectIndex,
    setCurrentProjectIndex,
    remainingEnergyLevel,
    setRemainingEnergyLevel,
    multipler,
    updateProject,
    disableTapping,
    levelImage,
    telegramId,
    startTimeout,
    triggerNotification,
    openLootModal,
    setOpenLootModal,
    profitPerHourBtn,
    claimProfitPerHour,
    showOfflinePlayerModal,
    setShowOfflinePlayerModal,
    calculateProftPerHour
  } = useContext(context);
  const lastTapRef = useRef(0);
  const clanImage = useClanImage((projects && projects[currentProjectIndex]?.raider?.name));

  const handleTap = (getMaxAnimationDuration) => {
    setAnimating(true);
    setTimeout(() => {
      setAnimating(false);
    }, getMaxAnimationDuration);
  };

  const getMaxAnimationDuration = () => {
    let maxDuration = 0;
    animations.forEach((anim) => {
      if (anim.duration > maxDuration) {
        maxDuration = anim.duration;
      }
    });
    return maxDuration;
  };

  useEffect(() => {
    if (animations.length > 0) {
      setAnimating(true);
    } else {
      setAnimating(false);
    }
  }, [animations]);

  useEffect(() => {
    if (localStorage.getItem("allowAnimation")) {
      localStorage.getItem("allowAnimation") === "true"
        ? setEnableAnimation(true)
        : setEnableAnimation(false);
    } else {
      setEnableAnimation(true);
    }
  }, []);

  const handleIncrement = (e, numberOfTouches = 1) => {
    if (!disableTapping) {
      if (remainingEnergyLevel < multipler) {
        return;
      } else if (screen_id) {
        const currentTap = Date.now();
        const timeDiff = currentTap - lastTapRef.current;
        const animationDuration = Math.max(
          300,
          Math.min(1000, 1200 - timeDiff)
        );

        lastTapRef.current = currentTap;

        setCoins((prevCount) => prevCount + multipler * numberOfTouches);
        const useRemains =
          remainingEnergyLevel - multipler * numberOfTouches > 0
            ? remainingEnergyLevel - multipler * numberOfTouches
            : 0;
        setRemainingEnergyLevel((prevCount) => useRemains);
        updateProject({
          project_id,
          remainingEnergy: remainingEnergyLevel - multipler * numberOfTouches,
          coin: coins + multipler * numberOfTouches,
        });

        const gamePlay = screen_id.getBoundingClientRect();
        let x = e.clientX ? e.clientX - gamePlay.left - 8 : 0;
        let y = e.clientY ? e.clientY - gamePlay.top - 8 : 0;

        if (e.touches) {
          const touch = e.touches[0];
          if (touch) {
            x = touch.clientX - gamePlay.left - 25;
            y = touch.clientY - gamePlay.top - 25;
          }
        }

        const newAnimations = Array.from({ length: numberOfTouches }).map(
          (_, index) => ({
            id: (currentTap + index).toString() + v4(),
            left: e.clientX || (e.touches ? e.touches[index]?.clientX : 0),
            top: e.clientY || (e.touches ? e.touches[index]?.clientY : 0),
            duration: animationDuration,
            x: x,
            y: y,
          })
        );

        setAnimating(true);
        setAnimations((prevAnimations) => [
          ...prevAnimations,
          ...newAnimations,
        ]);

        handleTap(getMaxAnimationDuration());
        SoundPlayer.playSoundMultiple("coinTap");

        setTimeout(() => {
          setAnimating(false);
          setAnimations((prevAnimations) =>
            prevAnimations.filter(
              (anim) => !newAnimations.some((newAnim) => newAnim.id === anim.id)
            )
          );
        }, animationDuration);
      }
    }
  };

  const handleTouchStart = (e) => {
    handleIncrement(e, e.touches.length);
    HapticFeedback();
  };

  const handleClick = (e) => {
    handleIncrement(e, 1);
  };

  const getReferralReward = async () => {
    try {
      const { data: response } = await auth.getReferralGain(telegramId);
      if (response) {
        logConsole({ response });
        setReferralTitle(response.message);
        setReferralReward(response.totalGain);
        if (response?.totalGain > 0) {
          setOpenReferralModal(true);
        } else {
          setOpenReferralModal(false);
        }
      }
    } catch (error) {
      const errorMessage = auth.handleTheError(error);
      logConsole({ errorMessage });
    }
  };

  const claimReferralReward = async () => {
    try {
      setReferralBtn(true);
      await userTap(coins);
      const { data: response } = await auth.CheckReferralGain(telegramId);
      if (response) {
        logConsole({ response });
        setReferralBtn(false);
        setOpenReferralModal(false);
        const totalGain = response?.totalGain ?? 0;
        setCoins((prevCoins) => prevCoins + totalGain);
        updateProject({ project_id, coin: coins + totalGain });
        triggerNotification("info", "Bonus Claimed!");
      }
    } catch (error) {
      const errorMessage = auth.handleTheError(error);
      logConsole({ errorMessage });
      setReferralBtn(false);
      triggerNotification("error", "Unable to Claim bonus");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getReferralReward();
    }, 2400);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <gameContext.Provider
      value={{
        animations,
        setAnimations,
        handleIncrement,
        animating,
        setAnimating,
        getMaxAnimationDuration,
        handleTouchStart,
        handleClick,
      }}
    >
      <div className="coins actions min-h-screen h-full relative" id="screen">
        {/* <div className="div h-full"> */}
        <div className="div h-full min-h-screen flex flex-col">
          <div className="flex-none h-[15%]">
            <Player />
          </div>

          <div className="flex-grow h-[85%] pb-[80px] rectangle pt-3 overflow-y-scroll">
            {/* <div className="overlap flex-grow h-[85%] pb-[80px] rectangle pt-3"> */}
            {/* <div className="rectangle pt-3"> */}
            <Headers />
            <Coins />
            <Progress />
            <Hammer />

            {enableAnimation &&
              animations.map((anim) => (
                <div
                  key={anim.id}
                  className="score-animation"
                  style={{
                    left: anim.x,
                    top: anim.y,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    animationDuration: `${anim.duration}ms`,
                  }}
                >
                  <img
                    className="h-[1.25rem] w-[1.25rem]"
                    alt="coin"
                    src={DollarCoin}
                  />
                  <span className="text-[1rem] text-white font-bold">
                    +{multipler}
                  </span>
                </div>
              ))}

            {/* <Boosters /> */}
            {/* </div> */}
          </div>

          {/* <div className="h-[10%] w-full flex flex-col justify-end">
            <Actions />
          </div> */}

          <div className="fixed bottom-0 max-w-[450px] w-full flex flex-col justify-end">
            <Actions />
          </div>
          <ReferralModal
            title={referralTitle}
            onClick={claimReferralReward}
            setOpen={setOpenReferralModal}
            open={openReferralModal}
            reward={referralReward}
            disabled={referralBtn}
          />
          <OfflineClanRaidModal
            open={openLootModal}
            setOpen={setOpenLootModal}
            data={{
              image: clanImage || projects && projects[currentProjectIndex]?.raider?.image,
              clan:
                (projects && projects[currentProjectIndex]?.raider?.name) ??
                "Region",
              amount: calculateProftPerHour(),
            }}
            onClick={() => claimProfitPerHour()}
            disabled={profitPerHourBtn}
          />

          <LootModal
            open={showOfflinePlayerModal}
            setOpen={setShowOfflinePlayerModal}
            data={{}}
            onClick={() => setShowOfflinePlayerModal(false)}
          />
        </div>
      </div>
    </gameContext.Provider>
  );
};
