import { Modal, ModalContent } from "../ModalLayout/NewModalLayout"
import DollarCoin from "../../../icons/dollar-coin.svg";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";

const DailyComboModal = ({ open, setOpen, data, claimReward, disabled }) => {
  return (
    <Modal open={open} onOpenChange={setOpen}>
        <ModalContent
            side={'bottom'}
            className="md:w-[24.7rem] md:mx-auto overflow-x-scroll"
        >
            <div className="flex flex-col justify-center w-full">
                <img
                    src={'/images/daily-combo.png'}
                    alt={data.name}
                    className="rounded-md mx-auto mb-3"
                    style={{ width: "10.94rem", height: "10.94rem" }}
                />
                <p className="text-white text-xl font-semibold text-center mb-3">
                    Hurrah! Raider, you have completed <br /> the combo.
                </p>
                <div className="flex items-center justify-center gap-1">
                    <img src={DollarCoin} alt="coin" className="h-[2rem] w-[2rem]" />
                    <div className="text-[2rem] font-bold">+{Number(data.reward).toLocaleString()}</div>
                </div>
                <div className="flex justify-center mt-4">
                    <ButtonPrimary
                        text={"Claim Reward"}
                        onClick={claimReward}
                        disabled={disabled}
                        disabledText={"Claim reward"}
                        className={"!w-[18rem] mx-auto text-[20px]"}
                    />
                </div>
            </div>
        </ModalContent>
    </Modal>
  )
}

export default DailyComboModal