import React, { useContext, useState } from "react";
import { context } from "../../App";
import { useNavigate } from "react-router-dom";
import useClanImage from "../../utils/useClanImage";
import OfflineClanRaidModal from "../modals/OfflineClanRaidModal";

function Player() {
  const { projects, currentProjectIndex, photoURL, firstname } =
    useContext(context);
  const key = currentProjectIndex.toString();
  const navigate = useNavigate();
  const clanImage = useClanImage((projects && projects[currentProjectIndex]?.raider?.name));
  return (
    <div
      key={key}
      className="flex justify-between items-center gap-4 px-4 py-3"
    >
      <div className="flex items-center gap-1">
        <img
          className="h-[2.375rem] w-[2.375rem] rounded-full"
          alt="avatar"
          src={photoURL}
        />
        <div>
          <div className="text-white text-[13px]">
            {(projects && projects[currentProjectIndex]?.username) || firstname}
          </div>
          <div className="text-[#f28a00] text-[1rem]">{"Raider"}</div>
        </div>
      </div>

      <div
        className="overlap-group-2 flex items-center gap-1 px-3 py-1 cursor-pointer"
        onClick={() => navigate("/settings")}
      >
        <img
          src={ clanImage  || (projects && projects[currentProjectIndex]?.raider?.image) || ""}
          alt="clan"
          className="h-[1.625rem] w-[1.625rem] rounded-md"
        />
        <button type="button" className="text-white text-[1rem]">
          {(projects && projects[currentProjectIndex]?.raider?.name) ||
            "Region"}
        </button>
      </div>
    </div>
  );
}

export default Player;
