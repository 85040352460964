import React from 'react'
import { SettingsComponents } from '../components/Settings';

function Settings() {
  return (
    <div>
      <SettingsComponents />
    </div>
  );
}

export default Settings;