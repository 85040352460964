import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import Actions from "../Actions";
import { context } from "../../App";
import { UpgradeConnect } from "../../services/upgrade.service";
import Mines from "./Mines";
import DollarCoin from "../../icons/dollar-coin.svg";

export const Upgrades = () => {
  const [upgrades, setUpgrades] = useState(null);
  const [categories, setCategories] = useState(null);
  const upgrade = new UpgradeConnect();
  const { telegramId, project_id, coins, setModal } = useContext(context);

  const getUpgrades = async () => {
   try {
    if(project_id){
      const { data } = await upgrade.getMines(telegramId, project_id);
    if (data) {
      const uniqueCategories = Array.from(
        new Set(data.map((item) => item.category))
      );
      setCategories(uniqueCategories);
      setUpgrades(data);
    }
    }
   } catch (error) {
    const msg = upgrade.handleTheError(error);
    setModal(()=> ({
      isOpen: true,
      message: msg,
    }));
   }
  };

  useEffect(() => {
    getUpgrades();
  }, [project_id]);

  return (
    <div className={`${styles.upgrades} actions`}>
      <div className={styles.div}>
        <div className={styles.textWrapper}>Upgrades</div>
        <div className={styles.textWrapper2}>sit amet consectetur.</div>
        <div className={styles.textWrapper3}>Your balance</div>
        <div className={styles.textWrapper4}>How it works</div>

        {categories ? (
          <div>
            {categories.map((item, index) => (
              <>
                <div
                  key={index}
                  style={{ cursor: "pointer" }}
                  className={
                    index % 2 === 0 ? styles.overlap : styles.overlapGroup
                  }
                >
                  <div
                    className={
                      index % 2 === 0
                        ? styles.textWrapper5
                        : styles.textWrapper6
                    }
                  >
                    {item}
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : (
          <div>
            <div className={styles.overlap}>
              <div className={styles.textWrapper5}>Market</div>
            </div>
          </div>
        )}

        {upgrades ? (
          <div>
            <Mines data={upgrades} />
          </div>
        ) : (
          <div></div>
        )}

        <div className={styles.frame6}>
          <img className={styles.fi3} alt="DollarCoin" src={DollarCoin} />
          <div className={styles.textWrapper15}>{Math.floor(coins)}</div>
        </div>

        <Actions />
      </div>
    </div>
  );
};
