import React from "react";
import { Zoom } from "react-awesome-reveal";
import DollarCoin from "../../icons/dollar-coin.svg";
import { ReactComponent as GiftBox } from "../../icons/giftbox.svg";
import { ReactComponent as GreenGiftBox } from "../../icons/giftbox-green.svg";


export const Banner = () => {
  return (
    <div className="flex flex-col gap-3 mb-8">
      <div className="px-1 py-4 bg-[#0D0F18] border-2 border-solid border-[#151827] rounded-[0.95rem]">
        <div className="flex items-center gap-2">
          <Zoom direction={"up"} duration={350}>
            <div className="relative">
              <GiftBox className="w-[3.375rem] h-[3.375rem]" />
              <div
                className="absolute top-0 bottom-0 left-0 right-0 m-auto w-[2.2rem] h-[2.2rem]"
                style={{
                  boxShadow: "0px -8px 40px 0px #3B3B98",
                  filter: "blur(8px)",
                }}
              />
            </div>
          </Zoom>
          <div className="flex flex-col items-start gap-2">
            <div className="text-white text-[0.9375rem] text-left font-bold">
              Invite a friend
            </div>
            <div className="flex items-center gap-1">
              <img
                src={DollarCoin}
                alt="coin"
                className="w-[1.25rem] h-[1.25rem]"
              />
              <span className="text-[#F28A00] text-[0.9375rem]">+20,000</span>
              <span className="text-[#848AA0] text-[0.9375rem]">
                for you and your friend
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="px-1 py-4 bg-[#0D0F18] border-2 border-solid border-[#151827] rounded-[0.95rem]">
        <div className="flex items-center gap-2">
          <Zoom direction={"up"} duration={350}>
            <div className="relative">
              <GreenGiftBox className="w-[3.375rem] [3.375rem]" />
              <div
                className="absolute top-0 bottom-0 left-0 right-0 m-auto w-[2.2rem] h-[2.2rem]"
                style={{
                  boxShadow: "0px -8px 40px 0px #83C60D80",
                  filter: "blur(8px)",
                }}
              />
            </div>
          </Zoom>
          <div className="flex flex-col items-start gap-2">
            <div className="text-white text-[0.9375rem] text-left font-bold">
              Invite a friend with Telegram Premium
            </div>
            <div className="flex items-center gap-1">
              <img
                src={DollarCoin}
                alt="coin"
                className="w-[1.25rem] h-[1.25rem]"
              />
              <span className="text-[#F28A00] text-[0.9375rem]">+35,000</span>
              <span className="text-[#848AA0] text-[0.9375rem]">
                for you and your friend
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="text-[#848AA0] text-[0.9375rem] text-center">
        Level up bonus{" "}
        <span className="text-[0.718rem] bg-[#1C1F2D] px-2 py-1 rounded-[20px]">
          Coming Soon
        </span>
      </div>
    </div>
  );
};
