import { Slide } from "react-awesome-reveal";
import Actions from "../Actions";
import { TopRaiders } from "./TopRaiders";
import { OtherRaiders } from "./OtherRaiders";
import { useParams } from "react-router-dom";
import { AirdropConnect } from "../../services/airdrop.service";
import { useContext, useEffect, useState } from "react";
import { logConsole } from "../../utils/useConsole";
import { context } from "../../App";
import { UserRank } from "./UserRank";

export const RaidLeaderboardComponents = () => {
  const airdrop = new AirdropConnect();
  const { telegramId } = useContext(context);
  const { airdrop_id } = useParams();
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [ userRank, setUserRank ] = useState(null);

  const getAirdropLeaderBoard = async () => {
    try {
      const { data: response } = await airdrop.leaderBoard(airdrop_id, telegramId);
      if(response) {
        console.log({ getAirdropLeaderBoard: response }, true);
        setLeaderBoard(response?.leaderboard);
        setUserRank(response?.userMainDetails);
      }
    } catch (error) {
      const errorMessage = airdrop.handleTheError(error);
      logConsole({ errorMessage }, true);
    }
  };

  useEffect(() => {
    getAirdropLeaderBoard();
  }, [airdrop_id]);


  return (
    <div className="relative coins h-full min-h-screen">
      <div className="px-4 pb-[6rem]">
        <div className="pt-7 pb-8 text-center">
          <Slide direction={"down"} duration={200}>
            <div className="text-white text-[1.5rem] font-bold">
              Raid Leaderboard
            </div>
          </Slide>
          <Slide direction={"left"} duration={300}>
            <div className="text-[#848aa0] text-[0.975rem]">
              Top 50 raiders to complete all quests
            </div>
          </Slide>
        </div>

        {leaderBoard  && (
          <TopRaiders leaderBoard={leaderBoard} />
        )}
        {leaderBoard && <OtherRaiders leaderBoard={leaderBoard} />}
      </div>

      <div className="fixed bottom-0 max-w-[450px] w-full flex flex-col justify-end">
        <UserRank userRank={userRank} currentStep={1} />
        <Actions />
      </div>
    </div>
  );
};
