import { useEffect } from "react";
import {
  ExpandLeftIcon,
  Calendar,
  DoneRingIcon,
  InviteFriendsIcon,
} from "../../icons";
import { useState } from "react";
import { useContext } from "react";
import { context } from "../../App";
import { EarnConnect } from "../../services/earn.service";
import { logConsole } from "../../utils/useConsole";
import { HapticFeedback } from "../../utils/HapticFeedback";
import DollarCoin from "../../icons/dollar-coin.svg";
import { JoinXModal } from "../modals/JoinX";
import { JoinTelegramModal } from "../modals/JoinTelegram";
import DailyRewardModal from "../modals/DailyRewardModal/DailyRewardSheet";
import InviteFriendsModal from "../modals/InviteFriendsModal";
import EarnTaskSkeletonLoader from "../Loaders/EarnTasks";
import moment from "moment";
import SoundPlayer from "../../utils/GameSounds";

export const EarnTask = ({
  rewards,
  loadingRewards,
  claimReward,
  disabled,
}) => {
  const earn = new EarnConnect();
  const [tasks, setTasks] = useState(null);
  // const [openModal, setOpenModal] = useState({status:false, view:})
  const [fetchingTasks, setFetchingTasks] = useState(false);
  const [telegramModal, setTelegramModal] = useState(false);
  const [twitterModal, setTwitterModal] = useState(false);
  const [showInviteFriendsModal, setShowInviteFriendsModal] = useState(false);
  const [selectedInviteFriendTask, setSelectedInviteFriendTask] = useState({});
  const [dailyRewardsModal, setDailyRewardsModal] = useState(false);
  const [data, setData] = useState({});
  const [isTwitterEnabled, setIsTwitterEnabled] = useState(false);
  const { telegramId, project_id, triggerNotification, setCoins, coins, setShowProcessingModal } =
    useContext(context);

  const checkIfTwitterEnableTime = () => {
    const twitterEnableTime = localStorage.getItem("twitterEnableTime");
    if (!twitterEnableTime || twitterEnableTime == null) return false;

    const timeDiff = moment
      .duration(Date.now() - parseInt(twitterEnableTime))
      .asMinutes();
    logConsole({ timeDiff }, true);
    return timeDiff >= 30;
  };

  const followTwitterEnableTime = () => {
    if (localStorage.getItem("twitterEnableTime") == null) {
      const currentTime = Date.now();
      localStorage.setItem("twitterEnableTime", currentTime);
      setIsTwitterEnabled(false);
      return false;
    } else {
      return checkIfTwitterEnableTime();
    }
  };

  useEffect(() => {
    setIsTwitterEnabled(checkIfTwitterEnableTime());
  }, []);

  const completeTask = async (action, linked, link, reward) => {
    HapticFeedback();
    if (linked) {
      if (action === "TWITTER") {
        await verifyTwitterFollow();
        setTwitterModal(false);
      } else if (action === "TELEGRAM") {
        window.open(link, "_blank");
        // await verifyTelegramFollow();
        setTelegramModal(false);
      } else if (action === "INVITE_3") {
        await verifyReferralCountEqualThree();
      } else if (action === "INVITE_10") {
        await verifyReferralCountEqualTen();
      }
    } else {
      if (action === "TWITTER") {
        const claimReward = followTwitterEnableTime();
        logConsole({ claimRewardForTwitter: claimReward });
        if (claimReward) {
          const updatedTasks = tasks.map((t) =>
            t.action === action ? { ...t, linked: true } : t
          );
          setData((prevData) => ({ ...prevData, linked: true, reward }));
          setTasks(updatedTasks);
          triggerNotification("success", "Admin has to verify follow");
          SoundPlayer.playSound("success2");
        }
        window.open(link, "_blank");
      } else if (action === "TELEGRAM") {
        setData((prevData) => ({ ...prevData, reward }));
        setTelegramModal(true);
        window.open(link, "_blank");
      } else if (action === "INVITE_3") {
        await verifyReferralCountEqualThree();
      } else if (action === "INVITE_10") {
        await verifyReferralCountEqualTen();
      }
    }
  };

  const openTelegramModal = (details) => {
    setData({ ...details, completeTask });
    setTelegramModal(true);
  };

  const openTwitterModal = (details) => {
    setData({ ...details, completeTask });
    setTwitterModal(true);
  };

  const openInviteThreeModal = (details) => {
    setData({ ...details, completeTask });
    setShowInviteFriendsModal(true);
  };

  const openIviteTenModal = (details) => {
    setData({ ...details, completeTask });
    setShowInviteFriendsModal(true);
  };

  const openModal = (e, action, linked, link, reward) => {
    e.preventDefault();
    if (action === "TWITTER") {
      openTwitterModal({ action, linked, link, reward, completeTask });
    } else if (action === "TELEGRAM" && !linked) {
      openTelegramModal({ action, linked, link, reward, completeTask });
    } else if (action === "INVITE_3") {
      setSelectedInviteFriendTask({ title: "Invite 3 Friends", linked, reward });
      openInviteThreeModal({ action, linked, link, reward, completeTask });
    } else if (action === "INVITE_10") {
      setSelectedInviteFriendTask({ title: "Invite 10 Friends", linked, reward });
      openIviteTenModal({ action, linked, link, reward, completeTask });
    }
  };

  const verifyTwitterFollow = async () => {
    try {
      const { data: response } = await earn.verifyTwitterFollow(telegramId);
      if (response) {
        triggerNotification("success", "Clan Twitter Follower");
        SoundPlayer.playSound("success2");
        setCoins(coins + Number(response.twitterFollowReward || 0));
        const updatedTasks = tasks.map((t) =>
          t.action === "TWITTER" ? { ...t, linked: true } : t
        );
        setTasks(updatedTasks);
      }
    } catch (error) {
      let messageType = "error";
      const errorMessage = earn.handleTheError(error);
      if (errorMessage == "Task completed") messageType = "info";
      triggerNotification(messageType, errorMessage);
    }
  };

  const verifyTelegramFollow = async () => {
    try {
      setShowProcessingModal(true);
      const { data: response } = await earn.verifyTelegramFollow(
        telegramId,
        project_id
      );
      if (response) {
        triggerNotification("success", "Clan Telegram Follower");
        SoundPlayer.playSound("success2");
        setCoins(coins + Number(response.telegramFollowReward || 0));
        const updatedTasks = tasks.map((t) =>
          t.action === "TELEGRAM" ? { ...t, linked: true } : t
        );
        setTasks(updatedTasks);
      }
    } catch (error) {
      let messageType = "error";
      const errorMessage = earn.handleTheError(error);
      if (errorMessage == "Task completed") {
        messageType = "info";
        triggerNotification(messageType, errorMessage);
      } else {
        const updatedTasks = tasks.map((t) =>
          t.action === "TELEGRAM" ? { ...t, linked: false } : t
        );
        setTasks(updatedTasks);
        triggerNotification(messageType, errorMessage);
      }
    }
    setShowProcessingModal(false);
  };

  const verifyReferralCountEqualThree = async () => {
    setShowProcessingModal(true);
    try {
      const { data: response } = await earn.verifyReferralCountEqualThree(
        telegramId
      );
      if (response) {
        triggerNotification("success", "Reward Successfully Claimed!");
        SoundPlayer.playSound("success2");
        setCoins(coins + Number(response.bonus || 0));
        const updatedTasks = tasks.map((t) =>
          t.action === "INVITE_3" ? { ...t, linked: true } : t
        );
        setTasks(updatedTasks);
        setShowInviteFriendsModal(false);
      }
    } catch (error) {
      let messageType = "error";
      const errorMessage = earn.handleTheError(error);
      if (errorMessage == "Task completed") {
        messageType = "info";
        triggerNotification(messageType, errorMessage);
      } else {
        const updatedTasks = tasks.map((t) =>
          t.action === "INVITE_3" ? { ...t, linked: false } : t
        );
        setTasks(updatedTasks);
        triggerNotification(messageType, errorMessage);
      }
    }
    setShowProcessingModal(false);
  };

  const verifyReferralCountEqualTen = async () => {
    setShowProcessingModal(true);
    try {
      const { data: response } = await earn.verifyReferralCountEqualTen(
        telegramId
      );
      if (response) {
        triggerNotification("success", "Reward Successfully Claimed!");
        SoundPlayer.playSound("success2");
        setCoins(coins + Number(response.bonus || 0));
        const updatedTasks = tasks.map((t) =>
          t.action === "INVITE_10" ? { ...t, linked: true } : t
        );
        setTasks(updatedTasks);
        setShowInviteFriendsModal(false);
      }
    } catch (error) {
      let messageType = "error";
      const errorMessage = earn.handleTheError(error);
      if (errorMessage == "Task completed") {
        messageType = "info";
        triggerNotification(messageType, errorMessage);
      } else {
        const updatedTasks = tasks.map((t) =>
          t.action === "INVITE_10" ? { ...t, linked: false } : t
        );
        setTasks(updatedTasks);
        triggerNotification(messageType, errorMessage);
      }
    }
    setShowProcessingModal(false);
  };

  const getTaskList = async () => {
    setFetchingTasks(true);
    try {
      const { data: response } = await earn.getTaskList(telegramId, project_id);
      if (response) {
        logConsole({ getTaskList: response }, true);
        setTasks(response);
        setFetchingTasks(false);
      }
    } catch (error) {
      const errorMessage = earn.handleTheError(error);
      triggerNotification("error", errorMessage);
      setFetchingTasks(false);
    }
  };

  useEffect(() => {
    getTaskList();
  }, []);

  return (
    <div className="overflow-x-scroll pb-28">
      <h2 className="text-white text-[1.1rem] mb-2 text-left">Daily Tasks</h2>
      {loadingRewards ? (
        <EarnTaskSkeletonLoader />
      ) : (
        <span
          onClick={() => setDailyRewardsModal(true)}
          className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2"
        >
          <div className="flex items-center flex-row-reverse gap-3">
            <div>
              <div className="text-[0.875rem] text-[#848AA0]">Daily Reward</div>

              <div className="flex items-center gap-1 w-[45px]">
                <img
                  className="h-[20px] w-[20px]"
                  alt="coin"
                  src={DollarCoin}
                />

                <div className="text-wrapper-15 text-white">6,000,000</div>
              </div>
            </div>
            <Calendar
              className="img-2 rounded-md"
              style={{ width: "40px", height: "40px" }}
            />
          </div>

          <span className="flex p-2">
            <ExpandLeftIcon
              style={{ cursor: "pointer" }}
              className="w-[1.5rem] h-[1.5rem]"
            />
          </span>
        </span>
      )}

      <h2 className="text-white text-[1.1rem] mb-2 text-left mt-12">
        Tasks List
      </h2>
      {fetchingTasks ? (
        Array.from({ length: 4 }, (_, i) => <EarnTaskSkeletonLoader key={i} />)
      ) : (
        <div className="flex gap-2 flex-col">
          {Array.isArray(tasks) &&
            tasks?.length > 0 &&
            tasks.map((task, index) => (
              <span
                key={index}
                onClick={(e) =>
                  openModal(e, task.action, task.linked, task.link, task.amount)
                }
                href={task.link}
                className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2"
              >
                <div className="flex items-center flex-row-reverse gap-3">
                  <div>
                    <div className="text-[0.875rem] text-[#848AA0]">
                      {task?.name}
                    </div>

                    <div className="flex items-center gap-1 w-[45px]">
                      <img
                        className="h-[20px] w-[20px]"
                        alt="coin"
                        src={DollarCoin}
                      />

                      <div className="text-wrapper-15 text-white">
                        {task?.amount}
                      </div>
                      <InviteFriendsIcon
                        className="img-2 rounded-md"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </div>
                  </div>
                  <img
                    className="img-2 rounded-md"
                    alt="Img"
                    style={{ width: "40px", height: "40px" }}
                    src={task.icon}
                  />
                </div>

                <span className="flex p-2">
                  {task.linked ? (
                    <DoneRingIcon
                      style={{ cursor: "pointer" }}
                      className="w-[1.5rem] h-[1.5rem]"
                    />
                  ) : (
                    <ExpandLeftIcon
                      style={{ cursor: "pointer" }}
                      className="w-[1.5rem] h-[1.5rem]"
                    />
                  )}
                </span>
              </span>
            ))}

          {/* <span
            onClick={() =>{
              setSelectedInviteFriendTask({title: 'Invite 10 Friends', reward: '400,000'})
              setShowInviteFriendsModal(true)
            }}
            className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2"
          >
            <div className="flex items-center flex-row-reverse gap-3">
              <div>
                <div className="text-[0.875rem] text-[#848AA0]">Invite 10 Friends</div>

                <div className="flex items-center gap-1 w-[45px]">
                  <img
                    className="fi-7"
                    alt="Fi"
                    src={"https://c.animaapp.com/SG58ovQI/img/fi-9729309.svg"}
                  />

                  <div className="text-wrapper-15 text-white">400,000</div>
                </div>
              </div>
              <InviteFriendsIcon
                className="img-2 rounded-md"
                style={{ width: "40px", height: "40px" }}
              />
            </div>

            <span className="flex p-2">
              <ExpandLeftIcon
                style={{ cursor: "pointer" }}
                className="w-[1.5rem] h-[1.5rem]"
              />
            </span>
          </span> */}
        </div>
      )}
      <DailyRewardModal
        open={dailyRewardsModal}
        setOpen={setDailyRewardsModal}
        rewards={rewards}
        claimReward={claimReward}
        disabled={disabled}
      />
      {/* <DailyRewardModal
        open={dailyRewardsModal}
        setOpen={setDailyRewardsModal}
        rewards={rewards}
        claimReward={claimReward}
        disabled={disabled}
      /> */}
      <JoinXModal
        open={twitterModal}
        setOpen={setTwitterModal}
        data={data}
        isTwitterEnabled={isTwitterEnabled}
      />
      <JoinTelegramModal
        open={telegramModal}
        setOpen={setTelegramModal}
        data={data}
        claimReward={verifyTelegramFollow}
      />
      <InviteFriendsModal
        open={showInviteFriendsModal}
        setOpen={setShowInviteFriendsModal}
        data={data}
        title={selectedInviteFriendTask.title}
        linked={selectedInviteFriendTask?.linked}
        reward={selectedInviteFriendTask.reward}
      />
    </div>
  );
};
