import React from "react";
// import "./style.css";
import Image from "../../../icons/telegram-btn.png";
import DollarCoin from "../../../icons/dollar-coin.svg";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import { ModalLayout } from "../ModalLayout";

export const JoinTelegramModal = ({ open, setOpen, data, claimReward }) => {
  const claim = async () => {
    claimReward().then(() => setOpen(false));
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      icon={Image}
      content={
        <div>
          <div className="">
            <p className="text-white text-[1.5rem] text-center mb-1">
              Join our TG channel
            </p>
          </div>

          <div className="flex justify-center mt-6">
            <ButtonPrimary
              text={"Join"}
              onClick={() =>
                data.completeTask(
                  data.action,
                  data.linked,
                  data.link,
                  data.reward
                )
              }
              className={"!w-[18rem] mx-auto"}
            />
          </div>

          <div className="flex items-center justify-center gap-1 mt-4">
            <img src={DollarCoin} alt="coin" className="h-[2rem] w-[2rem]" />
            <div className="text-[2rem] font-bold">+{data.reward}</div>
          </div>

          <div className="flex justify-center mt-6">
            <ButtonPrimary
              text={"Claim Reward"}
              onClick={claim}
              // disabled={!data.linked}
              disabledText={"Claim reward"}
              className={"!w-[18rem] mx-auto text-[20px]"}
            />
          </div>
        </div>
      }
    />
  );
};
