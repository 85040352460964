import React, { useEffect } from "react";
import { ReactComponent as WarningIcon } from "../../icons/warning.svg";
import { ReactComponent as ErrorIcon } from "../../icons/error.svg";
import { ReactComponent as InfoIcon } from "../../icons/info.svg";
import { ReactComponent as SuccessIcon } from "../../icons/success.svg";
import SoundPlayer from "../../utils/GameSounds";

export const Warning = ({ message }) => {
  useEffect(() => {
    SoundPlayer.playSound("error");
  }, []);

  return (
    <div className="flex items-center gap-2">
      <WarningIcon className="w-[2.25rem] h-[2.25rem]" />
      <div>
        <p className="text-[1rem] text-white font-bold">Warning</p>
        <p className="text-[0.875rem] text-white font-medium">
          {message ?? "Warning message"}
        </p>
      </div>
    </div>
  );
};

export const Error = ({ message }) => {
  useEffect(() => {
    SoundPlayer.playSound("error");
  }, []);
  return (
    <div className="flex items-center gap-2">
      <ErrorIcon className="w-[2.25rem] h-[2.25rem]" />
      <div>
        <p className="text-[1rem] text-white font-bold">Error</p>
        <p className="text-[0.875rem] text-white font-medium">
          {message ?? "Network Error"}
        </p>
      </div>
    </div>
  );
};

export const Info = ({ message }) => {
  useEffect(() => {
    SoundPlayer.playSound("error");
  }, []);

  return (
    <div className="flex items-center gap-2">
      <InfoIcon className="w-[2.25rem] h-[2.25rem]" />
      <div>
        <p className="text-[1rem] text-white font-bold">Info</p>
        <p className="text-[0.875rem] text-white font-medium">
          {message ?? "Info message"}
        </p>
      </div>
    </div>
  );
};

export const Success = ({ message }) => {
  useEffect(() => {
    SoundPlayer.playSound("success1");
  }, []);
  return (
    <div className="flex items-center gap-2">
      <SuccessIcon className="w-[2.25rem] h-[2.25rem]" />
      <div>
        <p className="text-[1rem] text-white font-bold">Success</p>
        <p className="text-[1rem] text-white font-medium">
          {message ?? "Success message"}
        </p>
      </div>
    </div>
  );
};
