import DollarCoin from "../../icons/dollar-coin.svg";
import LockIcon from "../../icons/lock.png";
import lockedName from "../../utils/createLockedName";
import { formatNumber } from "../../utils/formatNumber";

const SpecialsCard = ({
  image,
  name,
  title,
  price,
  level,
  earnPerHour,
  relatedSpecial,
  click,
  locked,
  isLevelBased,
  isReferralBased,
}) => {
  const handleClick = () => {
    if (!locked) {
      click();
    }
  };

  return (
    <div
      className="flex flex-col cursor-pointer w-fit relative"
      onClick={handleClick}
    >
      {locked && (
        <div className="absolute top-0 bottom-0 right-0 left-0 bg-[#0D0F18AD] flex justify-center items-center z-30">
          <img
            src={LockIcon}
            alt={"locked"}
            className="w-[3.3rem] h-[3.5rem] mx-auto object-cover"
          />
        </div>
      )}

      <div className="flex flex-col px-2 pt-2 bg-[#151827] mb-[2px] rounded-t-[7px] ">
        <div className="relative mb-3">
          <img
            src={image}
            alt={name}
            className="w-40 h-40 mx-auto rounded-[5px] object-cover"
          />
          <div className="flex flex-row gap-3 items-center font-semibold h-5 w-[7.625rem] rounded-[30px] justify-center absolute bottom-2 center-absolute-el bg-[#5E3800C9]">
            <p className="text-[8px]">Loot per hour</p>
            <div className="flex flex-row gap-1 items-center">
              <img
                src={DollarCoin}
                alt={name}
                className="w-[11.55px] h-[11.55px]"
              />
              <p className="text-[9.9px]">
                + {formatNumber(Math.floor(earnPerHour)).toLocaleString()}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col mb-4">
          <h4 className="font-semibold text-[11px]">{name}</h4>
          <p className="text-[9px] font-semibold text-[#848AA0] mb-2">
            {title}
          </p>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center px-3 py-4 bg-[#151827] rounded-b-[7px]">
        <p className="text-[10px] font-semibold text-[#848AA0]">
          Level {level}
        </p>
        {relatedSpecial ? (
          <p className="capitalize font-semibold text-[10px]">
            {relatedSpecial}
          </p>
        ) : (
          <div className="flex flex-row gap-1 items-center">
            {!locked && (
              <img
                src={DollarCoin}
                alt={name}
                className="w-[11.5px] h-[11.5px]"
              />
            )}
            <p className="text-[9.9px] font-semibold">
              {!locked
                ? formatNumber(Math.floor(price)).toLocaleString()
                : lockedName({ isLevelBased, isReferralBased })}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecialsCard;
