import React from "react";

export const ButtonM = ({ className, divClassName, text, onClick }) => {
    return (
      <button 
      onClick={onClick}
      className={`frame ${className}`}>
        <div className={`let-s-go ${divClassName}`}>{text}</div>
      </button>
    );
};