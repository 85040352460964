import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ExpandLeftIcon } from "../../icons";
import { context } from "../../App";

export const ChooseSettings = () => {
  const navigate = useNavigate();
  const { projects, currentProjectIndex } = useContext(context);
  return (
    <div className="mb-10 flex flex-col gap-2">
      <div
        className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2"
        onClick={() => navigate("/roadmap")}
      >
        <div className="flex items-center gap-3">
          <div>
            <div className="text-[0.9375rem] text-white">
              Clan of Raiders Roadmap
            </div>
            <div className="text-[0.9375rem] text-[#848aa0]">
              More adventures to come
            </div>
          </div>
        </div>

        <ExpandLeftIcon
          style={{ cursor: "pointer" }}
          className="w-[1.5rem] h-[1.5rem]"
        />
      </div>

      <div
        className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2"
        onClick={() => navigate("/clans")}
      >
        <div className="flex items-center gap-3">
          <div>
            <div className="text-[0.9375rem] text-white">Change your Clan</div>
            <div className="text-[0.9375rem] text-[#848aa0]">
              {(projects && projects[currentProjectIndex]?.raider?.name) ||
                "Region"}
            </div>
          </div>
        </div>

        <ExpandLeftIcon
          style={{ cursor: "pointer" }}
          className="w-[1.5rem] h-[1.5rem]"
        />
      </div>

      <div className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2">
        <div className="flex items-center gap-3">
          <div>
            <div className="text-[0.9375rem] text-white">Choose Language</div>
            <div className="text-[0.9375rem] text-[#848aa0]">English</div>
          </div>
        </div>

        {/* <ExpandLeftIcon
          style={{ cursor: "pointer" }}
          className="w-[1.5rem] h-[1.5rem]"
        /> */}

        <span className="text-[0.9375rem] text-[#848aa0]">Coming Soon</span>
      </div>
    </div>
  );
};
