import { useState } from "react";
import CharacterDetailModal from "../modals/CharacterDetailModal";
import { ReactComponent as ArrowLeft } from "../../icons/circle-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../icons/circle-arrow-right.svg";

export const Frame = ({
  handlePrevious,
  handleNext,
  levels,
  currentStep,
  setOpenModal,
}) => {
  
  const [open, setOpen] = useState(false);
  
  return (
    <div>
      <div className="relative">
        {currentStep !== 0 && (
          <ArrowLeft
            onClick={() => handlePrevious()}
            className="absolute left-0 top-0 bottom-0 m-auto cursor-pointer z-10"
          />
        )}
        {levels.length - 1 > currentStep && (
          <ArrowRight
            onClick={() => handleNext()}
            className="absolute right-0 top-0 bottom-0 m-auto cursor-pointer z-10"
          />
        )}

        {levels?.map((level, index) => (
          <div
            key={index}
            className={`relative pt-6 mb-8  ${
              currentStep === index ? "" : "hidden"
            }`}
          >
            <div
              className={`absolute top-4 left-0 right-0 mx-auto flex items-center justify-center w-[84px] h-[34px] rounded-[26px] text-[0.875rem] text-black font-bold z-[20]`}
              style={{ background: `${level.color}` }}
            >
              Level {index + 1}
            </div>

            <div className="max-h-[250px] max-w-[164px] mx-auto flex items-center justify-center cursor-pointer relative z-10">
              <img
                src={level.img}
                className="max-h-[250px] max-w-[164px] h-full w-full object-fill w-auto mx-auto"
                alt="Raider"
                onClick={() => {
                  setOpenModal(true);
                }}
              />
            </div>
            <div
              className="absolute top-0 bottom-0 left-0 right-0 m-auto w-[150px] h-[220px]"
              style={{
                boxShadow: `0px 5px 60px 0px ${level.color}`,
                filter: "blur(8px)",
              }}
            />
          </div>
        ))}
      </div>

      <CharacterDetailModal
        open={open}
        setOpen={setOpen}
        currentStep={currentStep}
        levels={levels}
        data={{}}
      />
    </div>
  );
};
