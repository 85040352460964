import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectDetailsComponents } from "../components/ProjectDetails";
import { context } from "../App";
import { AirdropConnect } from "../services/airdrop.service";
import { logConsole } from "../utils/useConsole";
export const AirdropContext = createContext(null);

function ProjectDetails() {
  const airdrop = new AirdropConnect();
  const { airdrop_id } = useParams();
  const { allAirdrops, triggerNotification, telegramId } = useContext(context);
  const [currentAirdrop, setCurrentAirdrop] = useState({});

  const getAirdrop = async (bool) => {
    try {
      const result = allAirdrops
        ? allAirdrops?.find(
            (airdrop) => airdrop?.airdrop?.airdrop_id == airdrop_id
          )
        : undefined;
      if (!result || bool) {
        const { data: response } = await airdrop.getUserAirdrop(
          telegramId,
          airdrop_id
        );
        if (response) {
          setCurrentAirdrop({ ...response, isOpen: true, joined: true });
          logConsole({ response }, true);
        }
      } else {
        setCurrentAirdrop(result);
        logConsole({ result }, true);
      }
    } catch (error) {
      logConsole({ projectDetails: error }, true);
    }
  };

  useEffect(() => {
    getAirdrop();
  }, [telegramId]);

  return (
    <div>
      <AirdropContext.Provider
      value={{ getAirdrop, currentAirdrop }}
      >
        <ProjectDetailsComponents data={currentAirdrop} />
      </AirdropContext.Provider>
    </div>
  );
}

export default ProjectDetails;
