import { useEffect, useContext, useState } from "react";
import { Slide } from "react-awesome-reveal";
import Actions from "../Actions";
import { context } from "../../App";
import { Banner } from "./Banner";
import { FriendList } from "./FriendList";
import { ReactComponent as Copy } from "../../icons/copy.svg";
import { ButtonPrimary } from "../buttons/ButtonPrimary";
import { FriendsConnect } from "../../services/friends.service";
import { logConsole } from "../../utils/useConsole";
import { HapticFeedback } from "../../utils/HapticFeedback";
import SoundPlayer from "../../utils/GameSounds";
import { config } from "../../config"
import { UserConnect } from "../../services/user.service";

export const FriendComponents = () => {
  const friendsConnect = new FriendsConnect();
  const user = new UserConnect();
  const { telegramId, triggerNotification, project_id, updateProject, coins, setCoins } = useContext(context);
  const [friends, setFriends] = useState(null);
  const [ friendsLength, setFriendsLength ] = useState(0);
  const [referralLink, setReferralLink] = useState(
    `${config.referralLinkURL}=${telegramId}`
  );

  const getFriends = async () => {
    try {
      const { data: response } = await friendsConnect.getReferrals(telegramId);
      if (response) {
        setFriends(response?.filteredFriendLists);
        setFriendsLength(response?.friendsLength);
      }
    } catch (error) {
      triggerNotification("error", friendsConnect.handleTheError(error));
    }
  }

  useEffect(() => {
    getFriends();
  }, []);


  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(`Play with me, become a raider and get a token airdrop! 💸 20k Coins as a first-time referral bonus 🔥 35k Coins referral bonus if you have Telegram Premium. The higher your level, the higher your referral bonus. ${referralLink}`)
      .then(() => {
        triggerNotification("success", "Referral link copied!");
        HapticFeedback();
        SoundPlayer.playSound("menu2");
      })
      .catch((err) => {
        triggerNotification("error", `Failed to copy link`);
      });
  };

  const handleShare = () => {
    if (navigator.share) {
      try {
        HapticFeedback();
        // await navigator.share({
        //   title: "Play with me, become a raider and get a token airdrop! 💸 35k Coins as a first-time referral bonus 🔥 50k Coins referral bonus if you have Telegram Premium. The higher your level, the higher your referral bonus.",
        //   url: referralLink,
        // });      
        handleCopyClick()  
       //  triggerNotification("success", 'Referral link shared successfully!');
      } catch (error) {
        // triggerNotification("error", 'Error sharing referral link');
      }
    } else {
      handleCopyClick()
      logConsole('Share API is not supported in your browser.');
    }
  };

  return (
    <div className="relative coins h-full min-h-screen">
      <div className="px-4 pb-[6rem]">
        <div className="pt-7 pb-8">
          <Slide direction={"left"} duration={200}>
            <div className="text-white text-[1.5rem] font-bold">
              Invite your Friends
            </div>
          </Slide>
          <Slide direction={"left"} duration={300}>
            <div className="text-[#848aa0] text-[0.975rem]">
              You and your friends will receive bonuses
            </div>
          </Slide>
        </div>

        <div>
          <Banner />
          <FriendList 
          friendsLength={friendsLength}
          friends={friends} />

          <Slide direction={"up"} duration={200}>
            <div className="flex justify-between gap-3 mt-8 mb-4">
              <ButtonPrimary
                onClick={handleShare}
                text={"Invite a friend"}
                style={{ width: "100%" }}
              />
              <button
                type="button"
                onClick={handleCopyClick}
                className="button-primary"
                style={{ width: "auto" }}
              >
                <Copy />
              </button>
            </div>
          </Slide>
        </div>
      </div>

      <div className="fixed bottom-0 max-w-[450px] w-full flex flex-col justify-end">
        <Actions />
      </div>
    </div>
  );
};
