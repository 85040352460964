import { useState } from "react";
// import { Slide } from "react-awesome-reveal";
import Actions from "../Actions";
import { ProjectName } from "./ProjectName";
import { AirdropQuest } from "./AirdropQuest";
import { Description } from "./Description";
import SoundPlayer from "../../utils/GameSounds";

const tabs = [
  {
    name: "Airdrop Quests",
    href: "quest",
  },
  {
    name: "Description",
    href: "description",
  },
];

export const ProjectDetailsComponents = ({ data }) => {
  const [activeTab, setActiveTab] = useState("quest");

  const handleTabClick = (tab) => {
    if (activeTab !== tab?.href) {
      setActiveTab(tab?.href);
    }
  };

  return (
    <div className="relative coins h-full min-h-screen">
      <div className="px-4 pb-[6rem] mb-10">
        <div className="pt-7 pb-8">
          <ProjectName data={data} />
        </div>

        {data && (
          <div className="mb-6">
            <nav className="flex items-center gap-3">
              {tabs?.map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => handleTabClick(tab)}
                  className={`h-[34px] px-4 py-1 text-[0.85rem] text-center outline-none border border-solid rounded-[2.5rem] ${
                    activeTab === tab?.href
                      ? "text-[#0D0F18] bg-[#83C60D] border-none"
                      : "text-[#848AA0] bg-transparent border-[#212942] border-2"
                  }`}
                >
                  {tab?.name}
                </button>
              ))}
            </nav>
          </div>
        )}

        {data && (
          <div>
            {activeTab === "quest" && <AirdropQuest data={data} />}
            {activeTab === "description" && <Description data={data} />}
          </div>
        )}
      </div>

      <div className="fixed bottom-0 z-10 max-w-[450px] w-full flex flex-col justify-end">
        <Actions />
      </div>
    </div>
  );
};
