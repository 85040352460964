import { useState, useEffect } from 'react';

const useClanImage = (clanName) => {
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        import(`../assets/Clans/${clanName}.png`)
            .then(image => setImageSrc(image.default))
            .catch(err => console.error('Error loading image:', err));
    }, [clanName]);

    return imageSrc;
};

export default useClanImage;
