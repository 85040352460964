import { Modal, ModalContent } from "../ModalLayout/NewModalLayout"
import FavIcon from "../../../icons/favourite.svg"
import DollarCoin from "../../../icons/dollar-coin.svg"

const OfflineClanRaidModal = ({open, setOpen, data, onClick, disabled }) => {
  return (
    <Modal open={open} onOpenChange={setOpen}>
        <ModalContent
            side={'bottom'}
            className="md:w-[24.7rem] md:mx-auto overflow-x-scroll"
        >
            <div className="flex flex-col mt-6 justify-center w-full">
                <img
                    src={data.image}
                    alt={data.clan}
                    className="rounded-[33px] mx-auto mb-3 w-28 h-28"
                />
                <h4 className="text-white text-[32px] font-semibold text-center mb-1">
                    Welcome back!
                </h4>
                <p className="text-[#848AA0] text-[15px] font-semibold text-center mb-6">
                    While you are away your clan {data.clan} <br /> raided for you!
                </p>
                <div className="flex items-center justify-center gap-1">
                    <img src={DollarCoin} alt="coin" className="h-9 w-9" />
                    <div className="text-[32px] font-semibold">+{Number(data?.amount ?? 0).toLocaleString()}</div>
                </div>
                <div className="flex justify-center mt-4">
                    <button
                        type="button"
                        onClick={onClick}
                        disabled={disabled}
                        className={`button-primary flex flex-row gap-1 items-center !w-[18rem] mx-auto`}
                    >
                        <p className="text-black whitespace-nowrap text-[1.25rem]">Thank you, {data.clan}</p>
                        <img src={FavIcon} alt="my clan" className="w-[1.375rem] h-[1.25rem]" />
                    </button>
                </div>
            </div>
        </ModalContent>
    </Modal>
  )
}

export default OfflineClanRaidModal