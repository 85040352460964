import React from "react";
import { Zoom } from "react-awesome-reveal";
import Loot from "../../../icons/loot.svg";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import DollarCoin from "../../../icons/dollar-coin-vector.svg";
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";

export const LootModal = ({ open, setOpen, data, onClick, disabled }) => {
  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        className="md:w-[24.7rem] md:mx-auto max-h-[90vh] overflow-x-scroll"
      >
        <div className="pt-10 pb-10">
          <Zoom duration={850}>
            <div>
              <div className="max-h-[250px] max-w-[164px] mx-auto flex items-center justify-center cursor-pointer relative z-10">
                <img
                  className="h-[6.4375rem] w-[6.4375rem] mx-auto mb-3 object-fill"
                  alt="loot"
                  src={Loot}
                />
              </div>
              <div
                className="absolute -top-3 bottom-0 left-0 right-0 m-auto h-[5.4rem] w-[5.4rem] rounded-full"
                style={{
                  boxShadow: `0px 5px 10px 0px #ED8514`,
                  filter: "blur(25px)",
                  backgroundColor: "#ED8514",
                }}
              />
            </div>
          </Zoom>
          <div>
            <div className="max-w-[308px] mx-auto">
              <p className="text-white text-[2rem] font-semi-bold text-center mb-1">
                Loot per hour
              </p>
              <div className="text-[#848aa0] text-[0.9375rem] text-center">
                To earn loot coins purchase items from the marketplace or
                complete any project airdrops
              </div>
              <div className="text-white text-[0.9375rem] text-center mt-5">
                Earn more when offline up to 3 hours
              </div>
            </div>

            <div className="flex justify-center mt-8">
              <ButtonPrimary
                text={
                  <div className='flex items-center justify-center gap-2'>
                    <span>Start looting</span>
                    <img
                      src={DollarCoin}
                      alt="coin"
                      className="h-[1.625rem] w-[1.625rem]"
                    />
                  </div>
                }
                disabled={disabled}
                disabledText={"Claim"}
                onClick={onClick}
                style={{ width: "90%" }}
              />
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
