import { useContext, useState } from "react"
import ProjectCard from "./ProjectCard"
import ProductModal from "../modals/ProductModal";
import { context } from "../../App";

const ProjectsTab = ({projects}) => {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showProductModal, setShowProductModal] = useState(false);
  const [ mine_id, setMine_id ] = useState("");
  const { mineItem } = useContext(context);
  const [ btn, setBtn ] = useState(false);

  const selectProduct = (item) => {
    setSelectedProduct(item);
    setMine_id(item?.mine_id);
    setShowProductModal(true);
  }

  const buyItem = async () => {
    const cost = selectedProduct?.mineLevel?.cost ?? 0;
    setBtn(true);
    setShowProductModal(false);
    await mineItem(mine_id, cost);
    setBtn(false);
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      {
        projects?.length && projects?.map(project => (
          <ProjectCard
            key={project.mine_id}
            name={project.title}
            image={project.image}
            level={project?.mineLevel?.level}
            price={project?.mineLevel?.cost??0}
            earnPerHour={project?.profitPerHourSum??0}
            relatedProject={project?.relatedProject}
            locked={project?.locked}
            isLevelBased={project?.isLevelBased} 
            isReferralBased={project?.isReferralBased} 
            click={()=> !project?.locked && selectProduct({...project})}
          />
        ))
      }

      <ProductModal
        open={showProductModal}
        setOpen={setShowProductModal}
        data={selectedProduct}
        buyItem={()=> !selectedProduct?.locked &&  buyItem()}
        btn={btn}
      />
    </div>
  )
}

export default ProjectsTab