import { config } from "../config";
import { AxiosConnect } from "./auth.service";

export class AirdropConnect {


  async userAirdrops(telegramId) {
    const { data: response } = await AxiosConnect.get(
      `/airdrop/airdrop-user/${telegramId}`
    );
    if (response && response.data) {
      return response;
    }
  }

  async joinAidrop(telegramId, airdrop_id) {
    const { data: response } = await AxiosConnect.get(
      `/airdrop/join/${airdrop_id}/${telegramId}`
    );
    if (response && response.data) {
      return response;
    }
  }

  async completeQuest(data) {
    const { data: response } = await AxiosConnect.post("/airdrop/user-quest-claim", data);
    if (response && response.data) {
      return response;
    }
  }

  async leaderBoard(airdrop_id, telegramId) {
    const { data: response } = await AxiosConnect.get(`/airdrop/leaderboard/${airdrop_id}/${telegramId}`);
    if (response && response.data) {
      return response;
    }
  }

  async getUserAirdrop(telegramId, airdrop_id) {
    const { data: response } = await AxiosConnect.get(
      `/airdrop/user-aidrop/${airdrop_id}/${telegramId}`
    );
    if (response && response.data) {
      return response;
    }
  }

  handleTheError(AxiosError) {
    let errorMessage =
      AxiosError.response?.data?.message ||
      AxiosError.message ||
      "Request failed";
    return errorMessage;
  }
}
