import React from "react";
import { Slide } from "react-awesome-reveal";
import { SinglePendingProject } from "./SinglePendingProject";
import { SingleJoinedProject } from "./SingleJoinedProject";
import { SingleEndedProject } from "./SingleEndedProject";

export const AllProjects = ({ airdrops }) => {
  return (
    <div className="flex flex-col gap-4 mb-8">
      <Slide direction={"up"} cascade duration={200}>
        {airdrops &&
          airdrops?.map((airdrop) => {
            if (airdrop?.airdrop?.status === "ended") {
              return <SingleEndedProject airdrop={airdrop} />;
            } else if (airdrop?.joined) {
              return <SingleJoinedProject airdrop={airdrop} />;
            } else {
              return <SinglePendingProject airdrop={airdrop} />;
            }
          }).reverse()}
          
      </Slide>
    </div>
  );
};
