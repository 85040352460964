import React, { useContext } from "react";
import "./style.css";
import { context } from "../../../App";
import { ButtonM } from "./Button";
import ParachuteCoin from "../../../icons/parachute-coin.svg";

export const Modal = () => {
  const { modal, setModal } = useContext(context);

  const closeModal = () => {
    setModal((prevModal) => ({
      ...prevModal,
      isOpen: false,
    }));
  }   

  return (
    <div>
      {modal && modal.isOpen && (
        <>
          <div className="start modal-shadow">
            <div className="div">
              <img className="fi" alt="fly-coin" src={ParachuteCoin} />
              <p className="text-wrapper">{modal.description}</p>
              <div className="text-wrapper-2">{modal.message}</div>
              {modal.coin ? (
                <div className="frame-2">
                  <img className="img" alt="fly-coin" src={ParachuteCoin} />
                  <div className="text-wrapper-3">{modal.coin}</div>
                </div>
              ) : (
                <div className="frame-2"></div>
              )}
              <ButtonM
                className="frame-100"
                divClassName="frame-instance"
                text={modal.btnTxt || "Start Hunting"}
                onClick={closeModal}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
