import React, { useContext } from "react";
import { gameContext } from ".";
import { context } from "../../App";
import { Bounce } from "react-awesome-reveal";
import DollarCoin from "../../icons/dollar-coin.svg";

const Coins = () => {
  const { coins } = useContext(context);
  const { animating } = useContext(gameContext);

  return (
    <Bounce duration={500}>
      <div className="w-100% px-4 pb-4 pt-2 relative" style={{ height: "12%" }}>
        {/* <div className={`frame-5 ${animating ? "animating" : ""}`}> */}
        <div className="flex items-center justify-center gap-1">
          <img
            className="w-[2.875rem] h-[2.875rem]"
            alt="coins"
            src={DollarCoin}
          />
          <div
            className={`text-[2.5rem] text-white ${
              animating ? "animating" : ""
            }`}
          >
            {Math.floor(coins).toLocaleString()}
          </div>
        </div>
      </div>
    </Bounce>
  );
};

export default Coins;
