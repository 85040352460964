import React from "react";
import SoundPlayer from "../../utils/GameSounds";

export const ButtonPrimary = ({
  className,
  divClassName,
  text,
  onClick,
  disabled,
  disabledText,
  style,
}) => {
  const buttonClick = () => {
    onClick();
    SoundPlayer.playSound("menu2");
  };

  return (
    <button
      type="button"
      onClick={buttonClick}
      disabled={disabled}
      className={`button-primary ${className}`}
      style={style}
    >
      <div className={`sub-button ${divClassName}`}>
        {disabled ? disabledText : text}
      </div>
    </button>
  );
};
