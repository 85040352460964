import { ColorRing } from "react-loader-spinner"
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout"

const ProcessingBarModal = ({open, setOpen}) => {
  return (
    <Modal open={open} onOpenChange={setOpen}>
        <ModalContent
            side='top'
            className="w-[23rem] mx-auto h-[3.3rem] rounded-[10px] flex flex-row items-center px-4"
        >
            <div className="flex flex-row gap-1 items-center">
                <ColorRing
                    height="36"
                    width="36"
                    colors={["#83C60D", "#83C60D", "#83C60D", "#83C60D", "#83C60D"]}
                    ariaLabel="circles-loading"
                    wrapperClass="loader-spinner"
                    visible={true}
                />
                <p className="font-semibold">Processing</p>
            </div>
        </ModalContent>
    </Modal> 
  )
}

export default ProcessingBarModal