import { useContext } from "react";
import { context } from "../../App";

const CharactersTab = () => {
  const { cachedImage } = useContext(context);
  return (
    <div className="flex flex-col justify-center text-center my-8">
      {cachedImage && (
        <img
          className="w-[9.5rem] h-[9.5rem] mx-auto"
          alt="Clan"
          src={cachedImage}
        />
      )}
      <h3 className="font-semibold text-2xl mb-2">Characters <br /> Coming Soon!</h3>
      <p className="text-[15px] text-[#848AA0]">Upgrade and modify your level <br /> character to any raider of your choice</p>
    </div>
  )
}

export default CharactersTab