import { useContext, useEffect, useState } from "react";
import { Bounce, Slide } from "react-awesome-reveal";
import DollarCoin from "../icons/dollar-coin.svg";
import { UserConnect } from "../services/user.service";
import ClanTab from "../components/Marketplace/ClanTab";
import ProjectsTab from "../components/Marketplace/ProjectsTab";
import {
  dummySpecialProducts,
  productsDummyData,
  projectsDummyData,
} from "../utils/dummyData";
import { context } from "../App";
import SpecialProductsTab from "../components/Marketplace/SpecialProductsTab";
import Actions from "../components/Actions";
import CharactersTab from "../components/Marketplace/CharactersTab";
import DailyCombo from "../components/Marketplace/DailyCombo";
import { MarketPlaceConnect } from "../services/marketplace.service";
import { logConsole } from "../utils/useConsole";
import { Info } from "../components/notifications/NotificationTypes";
import { formatNumber } from "../utils/formatNumber";

const MarketplacePage = () => {
  const [tab, setTab] = useState("clan");
  const activeTabStyle = "bg-[#83C60D] text-[#0D0F18] border-0";
  const inActiveTabStyle = "bg-[#0A0C15] tab-inactive-state text-[#848AA0]";
  const user = new UserConnect();
  const marketplace = new MarketPlaceConnect();
  const {
    triggerNotification,
    coins,
    telegramId,
    userTap,
    items,
    setItems,
    dailyCombo,
    showProcessingModal,
    setShowProcessingModal,
    setDailyCombo,
    profitPerHour,
    setShowOfflinePlayerModal,
    currentLevel,
    totalLevel,
  } = useContext(context);

  const clanProducts = items?.filter((item) => item.category === "clan");
  const projectProducts = items?.filter((item) => item.category === "project");
  const specialProducts = items?.filter(
    (item) => item.category === "special" && !item?.mined
  );
  const myCards = items?.filter(
    (item) => item.category === "special" && item?.mined
  );

  return (
    <div className="relative w-full max-w-full h-full min-h-screen overflow-x-scroll">
      <div className="px-4">
        <div className="py-7">
          <Slide direction={"left"} duration={200}>
            <div className="text-white text-[1.5rem] font-bold">
              Marketplace
            </div>
          </Slide>
          <Slide direction={"left"} duration={300}>
            <div className="text-[#848aa0] text-[0.938rem]">
              Upgrade your raiding gears
            </div>
          </Slide>
        </div>
        <div
          className="flex justify-between items-center gap-2 px-4 mb-3"
          style={{ height: "10%" }}
        >
          <div className="bg-[#1D202B] rounded-[2.5rem] w-1/2 min-w-[6.5rem] px-1 py-1">
            <div className="flex flex-col items-center justify-center">
              <div className="text-[#ff8b3e] text-[0.625rem]">User Level</div>
              <div className="flex items-center justify-center">
                {/* <img
                  className="w-[1rem] h-[1rem]"
                  alt="coin"
                  src={DollarCoin}
                /> */}
                <div className="text-[0.875rem]">
                  {currentLevel}/{totalLevel}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-[#1D202B] rounded-[2.5rem] w-1/2 ml-auto min-w-[6.5rem] px-1 py-1">
            <div className="flex flex-col items-center justify-center">
              <div className="text-[#ff8b3e] text-[0.625rem] flex items-center gap-1">
                <span>Loot per hour</span>
              </div>
              <div className="flex items-center justify-center">
                <img
                  className="w-[1rem] h-[1rem]"
                  alt="coin"
                  src={DollarCoin}
                />
                <div className="text-[0.875rem] mr-1" />
                <div className="text-[0.875rem] mr-1">
                  {formatNumber(profitPerHour)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-fit mx-auto text-center mb-6">
          {/* <p className="text-[#848aa0] text-[0.938rem] font-semibold">Your Balance</p> */}
          <div className="flex gap-2 items-center">
            <Bounce duration={600}>
              <img
                className="w-[39px] h-[39px]"
                alt="coins"
                src={DollarCoin}
              ></img>
            </Bounce>

            <span className="text-white text-[2.5rem]">
              {Math.floor(coins).toLocaleString()}
            </span>
          </div>
        </div>

        <DailyCombo
          amount={dailyCombo?.dailyCombo?.reward}
          day={dailyCombo?.dailyCombo?.day}
          claimed={dailyCombo?.dailyCombo?.claimed}
          comboItems_={dailyCombo?.userDailyCombo}
        />

        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <div
              className={`px-5 py-[5px] rounded-[37px] cursor-pointer border-red-300 ${
                tab === "clan" ? activeTabStyle : inActiveTabStyle
              }`}
              onClick={() => setTab("clan")}
            >
              Clan
            </div>
            <div
              className={`px-5 py-[5px] rounded-[37px] cursor-pointer ${
                tab === "project" ? activeTabStyle : inActiveTabStyle
              }`}
              onClick={() => setTab("project")}
            >
              Project
            </div>
            <div
              className={`px-5 py-[5px] rounded-[37px] cursor-pointer ${
                tab === "special" ? activeTabStyle : inActiveTabStyle
              }`}
              onClick={() => setTab("special")}
            >
              Special
            </div>
            {/* <div 
                        className={`px-5 py-[5px] rounded-[37px] cursor-pointer ${tab === 'characters' ? activeTabStyle : inActiveTabStyle}`}
                        onClick={()=>setTab('characters')}
                    >
                        Characters
                    </div> */}
          </div>

          <div className="mt-4 pb-32">
            {tab === "clan" ? (
              <ClanTab products={clanProducts} />
            ) : tab === "project" ? (
              <ProjectsTab projects={projectProducts} />
            ) : tab === "special" ? (
              <SpecialProductsTab
                myCards={myCards}
                newCards={specialProducts}
                completed={[]}
              />
            ) : tab === "characters" ? (
              <CharactersTab />
            ) : (
              <ClanTab products={clanProducts} />
            )}
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 max-w-[450px] w-full flex flex-col justify-end z-50">
        <Actions />
      </div>
    </div>
  );
};

export default MarketplacePage;
