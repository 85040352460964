import React, { useContext } from "react";
import { context } from "../../App";
import { formatNumber } from "../../utils/formatNumber";

export const Progress = ({ levels, currentStep, percentage = 50 }) => {
  const { coins, nextLevelUp, currentLevel } = useContext(context);
  return (
    <div className="text-center">
      {levels?.[currentStep]?.current === currentLevel ? (
        <>
          <div className="text-[1rem] text-[#FE9923] text-bold mb-3">
            {formatNumber(Math.floor(coins??0))} / {formatNumber(nextLevelUp??0)}
          </div>
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${percentage}%` }} />
          </div>
        </>
      ) : (
        <div className="text-[1rem] text-[#FE9923] text-bold mb-6">
          From {levels[currentStep]?.levelUp} coins
        </div>
      )}
    </div>
  );
};
