import React, { useState, useEffect } from "react";
import Avatar from "../../icons/avatar.png";
import { ReactComponent as Rank1st } from "../../icons/rank-1st.svg";
import { ReactComponent as Rank2nd } from "../../icons/rank-2nd.svg";
import { ReactComponent as Rank3rd } from "../../icons/rank-3rd.svg";
import { ReactComponent as RankCrown } from "../../icons/rank-crown.svg";

export const TopRaiders = ({ leaderBoard }) => {
  const [clanImages, setClanImages] = useState({});

  useEffect(() => {
    leaderBoard.forEach(user => {
      const clanName = user?.raider?.name;
      if (clanName && !clanImages[clanName]) {
        import(`../../assets/Clans/${clanName}.png`)
          .then(image => {
            setClanImages(prevImages => ({ ...prevImages, [clanName]: image.default }));
          })
          .catch(err => console.error('Error loading image:', err));
      }
    });
  }, [leaderBoard, clanImages]);

  return (
    <div className="mb-8">
      <div className="grid grid-cols-3">
        {(leaderBoard && leaderBoard[2]) && (
          <div className="text-center pt-2">
            <div
              className="w-[104px] h-[102px] bg-[#151827] rounded-[10px] mx-auto mb-3 flex items-center justify-center"
              style={{ boxShadow: "0 5px 0 #D9772F" }}
            >
              <div className="relative w-fit">
                <img
                  src={leaderBoard[2]?.image || Avatar}
                  alt="avatar"
                  className="w-[3.5rem] h-[3.5rem] rounded-[3.5rem]"
                />
                <img
                  src={clanImages[leaderBoard[2]?.raider?.name] || leaderBoard[2]?.raider?.image}
                  alt={leaderBoard[2]?.raider?.name}
                  className="w-[1.5rem] h-[1.5rem] rounded-[0.25rem] absolute bottom-1 -right-1.5"
                />
              </div>
            </div>
            <p className="text-[#848AA0] text-[0.8125rem]">{leaderBoard[2].raider?.name}</p>
            <p className="text-white text-base">{leaderBoard[2]?.username}</p>
            <div className="flex items-center justify-center gap-1 mt-4">
              <Rank3rd className="h-7 w-7" />
              <span className="text-[#D9772F] text-[0.9375rem]">3rd Rank</span>
            </div>
          </div>
        )}

        {(leaderBoard && leaderBoard[0]) && (
          <div className="text-center">
            <div
              className="relative w-[104px] h-[120px] bg-[#151827] rounded-[10px] mx-auto mb-3 flex items-center justify-center"
              style={{ boxShadow: "0 5px 0 #83C60D" }}
            >
              <RankCrown className="absolute -top-3" />
              <div className="relative w-fit">
                <img
                  src={leaderBoard[0]?.image || Avatar}
                  alt="avatar"
                  className="w-[4.5rem] h-[4.5rem] rounded-[4.5rem]"
                />
                <img
                  src={clanImages[leaderBoard[0]?.raider?.name] || leaderBoard[0]?.raider?.image}
                  alt={leaderBoard[0]?.raider?.name}
                  className="w-[1.5rem] h-[1.5rem] rounded-[0.25rem] absolute bottom-1 -right-1.5"
                />
              </div>
            </div>
            <p className="text-[#848AA0] text-[0.8125rem]">{leaderBoard[0]?.raider?.name}</p>
            <p className="text-white text-base">{leaderBoard[0]?.username}</p>
            <div className="flex items-center justify-center gap-1 mt-4">
              <Rank1st className="h-7 w-7" />
              <span className="text-[#D9772F] text-[0.9375rem]">1st Rank </span>
            </div>
          </div>
        )}

        {(leaderBoard && leaderBoard[1]) && (
          <div className="text-center pt-2">
            <div
              className="w-[104px] h-[102px] bg-[#151827] rounded-[10px] mx-auto mb-3 flex items-center justify-center"
              style={{ boxShadow: "0 5px 0 #585F7D" }}
            >
              <div className="relative w-fit">
                <img
                  src={leaderBoard[1]?.image || Avatar}
                  alt="avatar"
                  className="w-[3.5rem] h-[3.5rem] rounded-[3.5rem]"
                />
                <img
                  src={clanImages[leaderBoard[1]?.raider?.name] || leaderBoard[1]?.raider?.image}
                  alt={leaderBoard[1]?.raider?.name}
                  className="w-[1.5rem] h-[1.5rem] rounded-[0.25rem] absolute bottom-1 -right-1.5"
                />
              </div>
            </div>
            <p className="text-[#848AA0] text-[0.8125rem]">{leaderBoard[1]?.raider?.name}</p>
            <p className="text-white text-base">{leaderBoard[1]?.username}</p>
            <div className="flex items-center justify-center gap-1 mt-4">
              <Rank2nd className="h-7 w-7" />
              <span className="text-[#D9772F] text-[0.9375rem]">2nd Rank</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
