import { Calendar } from "../../../icons";
import { formatNumber } from "../../../utils/formatNumber";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";
import DollarCoin from "../../../icons/dollar-coin.svg";

const DailyRewardModal = ({
  open,
  setOpen,
  rewards,
  claimReward,
  disabled,
}) => {
  const styling = (reward) => {
    if (reward.isToday) {
      return "border-[#a4eb2a] border-2";
    } else if (reward.used) {
      return "border-[#83C60D] !bg-[#83C60D]";
    } else {
      return "border-transparent opacity-[0.5]";
    }
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        className="h-[42rem] md:w-[24.7rem] md:mx-auto max-h-[90vh] overflow-x-scroll"
      >
        <div className="flex flex-col gap-6 justify-center mt-6 w-full">
          <div className="relative mx-auto h-[120px] w-[120px]">
            <Calendar
              className="rounded-md mx-auto absolute z-30 top-4 center-absolute-el"
              style={{ width: "92px", height: "92px" }}
            />
            <div
              className="absolute top-0 bottom-0 left-0 right-0 bg-[#83C60D] bg-opacity-40 z-10"
              style={{
                flexShrink: 0,
                fill: "rgba(131, 198, 13, 0.40)",
                filter: "blur(20.952383041381836px)",
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-[2rem] font-semibold text-center">
              Daily Reward
            </h3>
            <p className="text-center text-[0.935rem] font-semibold text-[#848AA0]">
              Login daily to collect rewards.
            </p>
          </div>
          <div className="grid grid-cols-4 gap-[10px]">
            {Array.isArray(rewards) &&
              rewards?.length > 0 &&
              rewards.map((reward, index) => (
                <div
                  key={index}
                  className={`${styling(
                    reward
                  )} border border-solid !rounded-[9.7px] bg-[#0A0B12] flex flex-col items-center justify-center gap-1 py-1 w-full`}
                >
                  <div className="text-white text-[0.875rem] font-semibold">
                    {reward?.name}
                  </div>
                  <img
                    src={DollarCoin}
                    alt="coin"
                    className="w-[1.7rem] h-[1.7rem]"
                  />
                  <div className="text-white text-[0.936rem] font-semibold">
                    {formatNumber(reward?.reward)}
                  </div>
                </div>
              ))}
          </div>

          <ButtonPrimary
            onClick={claimReward}
            text={"Claim"}
            disabled={disabled}
            disabledText={"Come back tomorrow"}
            className={"!w-full"}
            divClassName={"!w-full"}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default DailyRewardModal;
