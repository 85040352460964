import React, { useContext, useEffect, useState } from "react";
import Actions from "../Actions";
import { Progress } from "./Progress";
import { Frame } from "./Frame";
import { Leaderboard } from "./Leaderboard";
import Level1 from "../../icons/Level1.png";
import Level2 from "../../icons/Level2.png";
import Level3 from "../../icons/Level3.png";
import Level4 from "../../icons/Level4.png";
import Level5 from "../../icons/Level5.png";
import Level6 from "../../icons/Level6.png";
import Level7 from "../../icons/Level7.png";
import Level8 from "../../icons/Level8.png";
import Level9 from "../../icons/Level9.png";
import Level10 from "../../icons/Level10.png";
import { context } from "../../App";
import { UserRank } from "./UserRank";
import SoundPlayer from "../../utils/GameSounds";
import CharacterDetailModal from "../modals/CharacterDetailModal";
import { LevelConnect } from "../../services/level.service";
import { logConsole } from "../../utils/useConsole";

const levels = [
  {
    name: "Baby Raider",
    img: Level1,
    desc: "Begin your raiding journey. Learn the basics and complete simple tasks to earn your first airdrop tokens.",
    color: "#2F9CD2",
    current: 1,
    levelUp: "0",
  },
  {
    name: "Junior Raider",
    img: Level2,
    desc: "Hone your skills with more challenging tasks. Prove your potential and collect more valuable airdrops.",
    color: "#51BA6B",
    current: 2,
    levelUp: "50k",
  },
  {
    name: "Scout Raider",
    img: Level3,
    desc: "Scout out hidden airdrops. Utilize your growing expertise to locate and claim harder-to-find tokens.",
    color: "#7867C9",
    current: 3,
    levelUp: "150k",
  },
  {
    name: "Sergeant Raider",
    img: Level4,
    desc: "Lead small raids and guide your fellow raiders. Show your leadership and earn bigger rewards.",
    color: "#51BA6B",
    current: 4,
    levelUp: "500k",
  },
  {
    name: "Warrior Raider",
    img: Level5,
    desc: "Engage in intense raids. Demonstrate your warrior spirit and secure significant airdrops.",
    color: "#51BA5F",
    current: 5,
    levelUp: "1M",
  },
  {
    name: "Lieutenant Raider",
    img: Level6,
    desc: "Command your raiding party with precision. Strategize and optimize raids for maximum token collection.",
    color: "#7867C9",
    current: 6,
    levelUp: "5M",
  },
  {
    name: "Colonel Raider",
    img: Level7,
    desc: "Oversee large-scale raids. Use your advanced skills to dominate the airdrop battlefield and gather high-value tokens.",
    color: "#51BA6B",
    current: 7,
    levelUp: "10M",
  },
  {
    name: "Major General Raider",
    img: Level8,
    desc: "Lead major operations. Coordinate complex raids and ensure successful token retrieval for your clan.",
    color: "#2F9CD2",
    current: 8,
    levelUp: "50M",
  },
  {
    name: "Veteran Raider",
    img: Level9,
    desc: "Draw on your extensive experience to tackle the toughest challenges. Claim elite airdrops that few can reach.",
    color: "#2F9CAF",
    current: 9,
    levelUp: "100M",
  },
  {
    name: "Grandmaster Raider",
    img: Level10,
    desc: "Achieve the highest point of raiding excellence. Master every aspect of the game and secure the rarest and most valuable airdrops.",
    color: "#51BA6B",
    current: 10,
    levelUp: "1B",
  },
];

export const CharacterComponents = () => {
  const levelConnect = new LevelConnect();
  const [openModal, setOpenModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { currentLevel, telegramId, leaderBoard, setLeaderBoard } =
    useContext(context);
  const [levelsData, setLevelsData] = useState(levels);

  const fetchImage = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };

  const cacheImage = async (level) => {
    const cachedImage = localStorage.getItem(level.img);
    if (cachedImage) {
      return cachedImage;
    } else {
      const imageUrl = await fetchImage(level.img);
      localStorage.setItem(level.img, imageUrl);
      return imageUrl;
    }
  };

  const generateHash = async (data) => {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(JSON.stringify(data));
    const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
    return Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  };

  useEffect(() => {
    setCurrentStep(currentLevel - 1);
  }, []);

  useEffect(() => {
    const cacheImages = async () => {
      const currentHash = await generateHash(levels);
      const storedHash = localStorage.getItem("levelsHash");

      if (currentHash !== storedHash) {
        const updatedLevels = await Promise.all(
          levels.map(async (level) => {
            const cachedImage = await cacheImage(level);
            return { ...level, img: cachedImage, current: level.lvl === 2 };
          })
        );
        setLevelsData(updatedLevels);
        localStorage.setItem("levelsHash", currentHash);
      } else {
        const updatedLevels = levels.map((level) => {
          const cachedImage = localStorage.getItem(level.img);
          return {
            ...level,
            img: cachedImage || level.img,
            current: level.lvl === 2,
          };
        });
        setLevelsData(updatedLevels);
      }
    };
    cacheImages();
  }, []);

  const handleNext = () => {
    setTimeout(() => {
      if (currentStep < levels.length - 1) {
        setCurrentStep(currentStep + 1);
        SoundPlayer.playSoundMultiple("menu1");
      }
    }, 100);
  };

  const handlePrevious = () => {
    setTimeout(() => {
      if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
        SoundPlayer.playSoundMultiple("menu1");
      }
    }, 100);
  };

  return (
    <div className="relative coins h-full min-h-screen">
      <div className="px-4 pt-6 pb-[6rem]">
        <Frame
          handlePrevious={handlePrevious}
          levels={levels}
          handleNext={handleNext}
          currentStep={currentStep}
          setOpenModal={setOpenModal}
        />

        <div className="text-center mb-1">
          <div className="text-[1.75rem] text-white font-bold">
            {levels?.[currentStep]?.name}
          </div>
        </div>

        <Progress levels={levels} currentStep={currentStep} />
        <Leaderboard leaderBoard={leaderBoard} currentStep={currentStep} />
      </div>

      <div className="fixed bottom-0 max-w-[450px] w-full flex flex-col justify-end">
        <UserRank leaderBoard={leaderBoard} currentStep={currentStep} />
        <Actions />
      </div>

      <CharacterDetailModal
        open={openModal}
        setOpen={setOpenModal}
        currentStep={currentStep}
        levels={levels}
        leader={leaderBoard[currentStep]}
        data={{}}
      />
    </div>
  );
};
