import DollarCoin from "../../icons/dollar-coin.svg";
import lockedName from "../../utils/createLockedName";
import LockIcon from '../../icons/lock.png';

const ProjectCard = ({
  image,
  name,
  price,
  level,
  earnPerHour,
  relatedProject,
  locked,
  click,
  isLevelBased, 
  isReferralBased 
}) => {

  const handleClick = () => {
    if (!locked) {
      click();
    }
  };

  return (
    <div
      className="flex flex-col cursor-pointer h-full"
      onClick={handleClick}
    >
      <div className="flex flex-row gap-2 px-2 py-2 bg-[#151827] mb-[2px] rounded-t-[7px] h-full">
        <div className="bg-[#1B2034] rounded-[5px] w-[45px] h-10 flex justify-center items-center flex-none relative">
          {
            locked && 
            <div className="absolute top-0 bottom-0 right-0 left-0 bg-[#0D0F18AD] flex justify-center items-center z-30">
              <img
                src={LockIcon}
                alt={'locked'}
                className="w-5 h-6 mx-auto object-cover"
              />
            </div>
          }
          <img src={image} alt={name} className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col">
          <h4 className="text-white font-semibold text-[11px] mb-1 line-clamp-1">{name}</h4>
          <p className="text-[7px] font-semibold text-[#848AA0]">
            Loot per hour
          </p>
          <div className="flex flex-row gap-1 items-center">
            <img
              src={DollarCoin}
              alt={name}
              className="w-3 h-3 mix-blend-luminosity"
            />
            <p className="text-[10px] font-semibold text-[#848AA0]">
              + {Math.floor(earnPerHour).toLocaleString()}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center px-2 py-2 bg-[#151827] rounded-b-[7px]">
        <p className="text-[10px] font-semibold text-[#848AA0]">
          Level {level}
        </p>
        {relatedProject ? (
          <p className="capitalize font-semibold text-[10px]">
            {relatedProject}
          </p>
        ) : (
          <div className="flex flex-row gap-1 items-center">
            {!locked ? (
              <>
                <img
                  src={DollarCoin}
                  alt={name}
                  className="w-[11.5px] h-[11.5px]"
                />
                <p className="text-[9.9px] font-semibold">
                  {Math.floor(price).toLocaleString()}
                </p>
              </>
            ) : (
              <>
                {" "}
                <p className="text-[9.9px] font-semibold">
                  {lockedName({ isLevelBased, isReferralBased })}
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
