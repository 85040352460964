import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import "./style.css";
import { Bounce } from "react-awesome-reveal";
import Actions from "../Actions";
import { context } from "../../App";
import { MoreBoosters } from "./MoreBoosters";
import { DailyBoosters } from "./DailyBoosters";
import { ExchangeConnect } from "../../services/exchange.service";
import { MultiplierModal } from "../modals/MultiplierModal";
import { EnergyLimitModal } from "../modals/EnergyLimitModal";
import { logConsole } from "../../utils/useConsole";
import { HapticFeedback } from "../../utils/HapticFeedback";
import SoundPlayer from "../../utils/GameSounds";
import DollarCoin from "../../icons/dollar-coin.svg";

export const Boosters = () => {
  const [boosters, setBoosters] = useState(null);
  const [refill, setRefill] = useState(null);
  const {
    telegramId,
    project_id,
    coins,
    setCoins,
    totalLevel,
    updateProject,
    userTap,
    setMultipler,
    currentProjectIndex,
    projects,
    multipler,
    setModal,
    setEnergyLevel,
    energyLevel,
    setRemainingEnergyLevel,
    triggerNotification,
    remainingEnergyLevel,
  } = useContext(context);
  const [remainingTime, setRemainingTime] = useState("");
  const [openMultitap, setOpenMultitap] = useState(false);
  const [openEnergylimit, setOpenEnergylimit] = useState(false);
  const [data, setData] = useState({});
  const [disableBtn, setDisableBtn] = useState(true);
  const exchange = new ExchangeConnect();

  const getBoosters = async () => {
    try {
      if (project_id) {
        const { data } = await new ExchangeConnect().getBoosters(
          telegramId,
          project_id
        );
        if (data) {
          setBoosters({
            multiTap: data.find((index) => index.name === "MultiTap"),
            energyLimit: data.find((index) => index.name === "Energy Limit"),
          });
          logConsole({
            multiTap: data.find((index) => index.name === "MultiTap"),
            energyLimit: data.find((index) => index.name === "Energy Limit"),
          });
        }
      }
    } catch (error) {}
  };

  const getRefill = async () => {
    try {
      if (!refill || !refill?.lastBoostTime) {
        const { data } = await exchange.getFullEnergy(telegramId, project_id);
        if (data) {
          setRefill(data);
          setRemainingTime(data?.message);
          if (data.boost) {
            const boostCount = projects[currentProjectIndex]?.boostCount ?? 0;
            updateProject({
              project_id,
              lastBoostDate: Date.now(),
              boostCount: boostCount + 1,
              remainingEnergyLevel: energyLevel,
              isNewUserBoost: false,
            });
            setRemainingEnergyLevel(energyLevel);
            triggerNotification("success", `Earned Free Energy Refill`);
          } else {
            triggerNotification("info", "Boost not available");
          }
        }
      } else {
        const now = moment();
        const lastBoostTime = moment(refill.lastBoostTime);
        const hoursPassed = now.diff(lastBoostTime, "hours");
        if (hoursPassed >= 1) {
          const { data } = await exchange.getFullEnergy(telegramId, project_id);
          if (data) {
            setRefill(data);
            // setRemainingTime(data.message);
            const boostCount = projects[currentProjectIndex]?.boostCount ?? 0;
            updateProject({
              project_id,
              lastBoostDate: Date.now(),
              boostCount: boostCount + 1,
              remainingEnergyLevel: energyLevel,
              isNewUserBoost: false,
            });
            if (data.boost) {
              setRemainingEnergyLevel(energyLevel);
              triggerNotification("success", `Earned Free Energy Refill`);
            } else {
              triggerNotification("info", "Boost not available");
            }
          }
        }
      }
    } catch (error) {
      triggerNotification("error", new ExchangeConnect().handleTheError(error));
    }
  };

  useEffect(() => {
    if (
      projects?.[currentProjectIndex]?.lastBoostDate ||
      (refill && refill?.lastBoostTime)
    ) {
      const interval = setInterval(() => {
        const now = moment();
        const lastBoostTime = moment(
          refill?.lastBoostTime || projects[currentProjectIndex]?.lastBoostDate
        );

        if (
          !now.isSame(lastBoostTime, "day") ||
          projects[currentProjectIndex]?.isNewUserBoost
        ) {
          updateProject({
            project_id,
            boostCount: 0,
          });
          setRemainingTime("Available");
          setDisableBtn(false);
        } else {
          const timeUntilNextBoost = moment
            .duration(1, "hours")
            .subtract(now.diff(lastBoostTime));

          let formattedTime = "";
          if (
            timeUntilNextBoost.seconds() > 0 ||
            timeUntilNextBoost.minutes() > 0
          ) {
            if (timeUntilNextBoost.hours() > 0) {
              formattedTime = `${timeUntilNextBoost.hours()} hour ${timeUntilNextBoost.minutes()} min`;
            } else {
              formattedTime = `${timeUntilNextBoost.minutes()} mins ${timeUntilNextBoost.seconds()} sec`;
            }
            setRemainingTime(formattedTime);
            setDisableBtn(true);
          } else {
            setRemainingTime("Available");
            setDisableBtn(false);
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [refill, projects, remainingTime]);

  useEffect(() => {
    getBoosters();
  }, [project_id]);

  const getMultiTap = async () => {
    HapticFeedback();
    try {
      if (coins >= boosters?.multiTap?.costToPurchaseMultiTap) {
        const taps = await userTap(coins);
        const { data } = await exchange.getMultiTap(telegramId, project_id);
        if (taps || data) {
          setMultipler(multipler + boosters?.multiTap?.multiTap);
          setCoins(coins - boosters?.multiTap?.costToPurchaseMultiTap);
          await userTap(coins - boosters?.multiTap?.costToPurchaseMultiTap);
          updateProject({
            project_id,
            earnPerTap: multipler + boosters?.multiTap?.multiTap,
            coin: coins - boosters?.multiTap?.costToPurchaseMultiTap,
          });
          setOpenMultitap(false);
          triggerNotification(
            "success",
            `Multiplier Increased by +${boosters?.multiTap?.multiTap}`
          );
          await getBoosters();
          SoundPlayer.playSound("success2");
        }
      } else {
        setOpenMultitap(false);
        triggerNotification("info", "You're low on coins");
      }
    } catch (error) {
      triggerNotification("error", new ExchangeConnect().handleTheError(error));
    }
  };

  const getEnergyLimit = async () => {
    HapticFeedback();
    try {
      if (coins >= boosters?.energyLimit?.costToPurchaseEnergyLimit) {
        const taps = await userTap(coins);
        const { data } = await exchange.getEnergyLimit(telegramId, project_id);
        if (taps || data) {
          const energyLevel_ = boosters?.energyLimit?.energyLimit + energyLevel;
          setEnergyLevel(energyLevel_);
          setRemainingEnergyLevel(energyLevel_);
          setCoins(coins - boosters?.energyLimit?.costToPurchaseEnergyLimit);
          await userTap(
            coins - boosters?.energyLimit?.costToPurchaseEnergyLimit
          );
          updateProject({
            project_id,
            energyLevel: energyLevel_,
            remainingEnergy: energyLevel_,
            coin: coins - boosters?.energyLimit?.costToPurchaseEnergyLimit,
          });
          setOpenEnergylimit(false);
          triggerNotification(
            "success",
            `Energy Level Increased by +${boosters?.energyLimit?.energyLimit}`
          );
          SoundPlayer.playSound("success2");
          await getBoosters();
        }
      } else {
        setOpenEnergylimit(false);
        triggerNotification("info", "You're low on coins");
      }
    } catch (error) {
      triggerNotification("error", new ExchangeConnect().handleTheError(error));
    }
  };

  const isOpen = (type, lvl, cost, energy) => {
    if (type === "multitap") {
      setOpenMultitap(true);
      setData({ function: getMultiTap, lvl, cost });
    } else if (type === "energylimit") {
      setOpenEnergylimit(true);
      setData({ function: getEnergyLimit, lvl, cost, energy });
    }
  };

  return (
    <div className="boosters actions">
      <div className="div pb-[6rem] px-4">
        <div className="flex justify-between pb-10 pt-8">
          <div>
            <div className="text-white text-[1.5rem] font-bold">Boosters</div>
            <div className="text-[#848aa0] text-[0.975rem]">
              Supercharge your raiding adventures
            </div>
          </div>
        </div>

        <div className="mb-8">
          <div className="text-[#848aa0] text-[0.975rem] text-center">
            Your balance
          </div>
          <Bounce duration={500}>
            <div className="flex gap-1 items-end justify-center">
              <img
                className="h-[3.25rem] w-[3.25rem] object-fill"
                alt="coins"
                src={DollarCoin}
              />

              <div className="text-white text-[2.5rem] text-center">
                {Math.floor(coins).toLocaleString()}
              </div>
            </div>
          </Bounce>
        </div>

        <DailyBoosters
          refill={refill}
          getRefill={getRefill}
          remainingTime={remainingTime}
          disableBtn={disableBtn}
        />

        {boosters && <MoreBoosters boosters={boosters} isOpen={isOpen} />}
      </div>

      <EnergyLimitModal
        open={openEnergylimit}
        setOpen={setOpenEnergylimit}
        data={data}
      />
      <MultiplierModal
        open={openMultitap}
        setOpen={setOpenMultitap}
        data={data}
      />
      <div className="fixed bottom-0 max-w-[450px] w-full flex flex-col justify-end">
        <Actions />
      </div>
    </div>
  );
};
