import React from "react";
import { useContext } from "react";
import { context } from "../../App";
import { formatNumber } from "../../utils/formatNumber";
import { ExpandLeftIcon } from "../../icons";
import { ReactComponent as Multitap } from "../../icons/multitap.svg";
import DollarCoin from "../../icons/dollar-coin.svg";
import Energy from "../../icons/energy-horizontal.svg";

export const MoreBoosters = ({ boosters, isOpen }) => {
  const { coins } = useContext(context);
  return (
    <div className="mb-6">
      <h2 className="text-white text-[1.25rem] mb-2 text-left">
        More Boosters
      </h2>
      <div className="flex gap-3 flex-col">
        <div
          onClick={() =>
            isOpen(
              "multitap",
              boosters?.multiTap?.lvl,
              boosters?.multiTap?.costToPurchaseMultiTap
            )
          }
          className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2"
        >
          <div className="flex items-center flex-row-reverse gap-3">
            <div>
              <div className="text-[0.9375rem] text-white">Multitap</div>
              <div className="flex items-center gap-1">
                <div className="flex items-center gap-1 min-w-[48px]">
                  {boosters?.multiTap?.costToPurchaseMultiTap <= coins ? (
                    <img className="fi-7" alt="coin" src={DollarCoin} />
                  ) : (
                    <img
                      className="fi-7"
                      alt="coin"
                      style={{ mixBlendMode: "luminosity" }}
                      src={DollarCoin}
                    />
                  )}

                  <div className="text-white text-[#848aa0]">
                    {formatNumber(boosters?.multiTap?.costToPurchaseMultiTap)}
                  </div>
                </div>

                <div className="text-[0.9375rem] text-[#848aa0] ml-2">
                  {` Level ${boosters?.multiTap?.lvl}`}
                </div>
              </div>
            </div>
            <Multitap className="w-[2.4375rem] h-[2.4375rem]" />
          </div>

          <span>
            <ExpandLeftIcon
              style={{ cursor: "pointer" }}
              className="w-[1.5rem] h-[1.5rem]"
            />
          </span>
        </div>
        <div
          onClick={() =>
            isOpen(
              "energylimit",
              boosters?.energyLimit?.lvl,
              boosters?.energyLimit?.costToPurchaseEnergyLimit,
              boosters?.energyLimit?.energyLimit
            )
          }
          className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2"
        >
          <div className="flex items-center flex-row-reverse gap-3">
            <div>
              <div className="text-[0.875rem] text-white">Energy Limit</div>
              <div className="flex items-center">
                <div className="flex items-center gap-1 min-w-[45px]">
                  {boosters?.energyLimit?.costToPurchaseEnergyLimit <= coins ? (
                    <img className="fi-7" alt="coin" src={DollarCoin} />
                  ) : (
                    <img
                      className="fi-7"
                      alt="coin"
                      style={{ mixBlendMode: "luminosity" }}
                      src={DollarCoin}
                    />
                  )}

                  <div className="text-[0.9375rem] text-white">
                    {formatNumber(
                      boosters?.energyLimit?.costToPurchaseEnergyLimit
                    )}
                  </div>
                </div>

                <div className="text-[0.9375rem] text-[#848aa0] ml-2">
                  {` Level ${boosters?.energyLimit?.lvl}`}
                </div>
              </div>
            </div>

            <img
              className="w-[2.4375rem] h-[2.4375rem]"
              alt="energy-level"
              src={Energy}
            />
          </div>

          <span
            onClick={() =>
              isOpen(
                "energylimit",
                boosters?.energyLimit?.lvl,
                boosters?.energyLimit?.costToPurchaseEnergyLimit,
                boosters?.energyLimit?.energyLimit
              )
            }
          >
            <ExpandLeftIcon
              style={{ cursor: "pointer" }}
              className="w-[1.5rem] h-[1.5rem]"
            />
          </span>
        </div>
      </div>
    </div>
  );
};
