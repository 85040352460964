import React from "react";
import { useNavigate } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import { useContext } from "react";
import { context } from "../../../App";
// import { ModalLayout } from "../ModalLayout";
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";

export const ChangeClanModal = ({ open, setOpen, name, image }) => {
  const { raiderLevel, setRaiderLevel, showGame, setShowGame } =
    useContext(context);
  const navigate = useNavigate();

  const closeModal = () => {
    setOpen(false);
    setRaiderLevel((raiderLevel) => ({ ...raiderLevel, isOpen: false }));
    setShowGame(true);
    navigate("/");
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        className="md:w-[24.7rem] md:mx-auto max-h-[90vh] overflow-x-scroll"
      >
        <div className="pt-10 pb-10">
          <Zoom duration={850}>
            <img
              className="h-[6.4375rem] w-[6.4375rem] mx-auto mb-3 object-fill"
              alt="Fi"
              src={image}
            />
          </Zoom>
          <div className="mb-6">
            <p className="text-white text-[2rem] text-center mb-2">{name}</p>
            <div className="text-[#848aa0] text-[0.9375rem] text-center max-w-[230px] mx-auto">
              You have joined the {name} clan. Good luck raider
            </div>
          </div>

          <div className="mb-6">
            <ButtonPrimary
              text={"Go Raid"}
              onClick={closeModal}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
