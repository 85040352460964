import { logConsole } from "./useConsole";
let turnOn = true;
const keyValue = "refreshv1";

export const hardReset = () => {
       if(turnOn) {
        if(localStorage.getItem(keyValue) == null){
            const keepKey = null // "telegramId";
            const keepValue = localStorage.getItem(keepKey);
            localStorage.clear();
            logConsole("Reseting...");
            if (keepValue !== null) {
                localStorage.setItem(keepKey, keepValue);
            }
            turnOn = false;
            localStorage.setItem(keyValue, turnOn);
            window.location.reload();
        }
       }
};
