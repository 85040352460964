import React, { useContext, useEffect, useState } from "react";
import { gameContext } from ".";
import { context } from "../../App";
import Boosters from "./Boosters";
import GameBg1 from "../../icons/gameplay-bg.png";
import GameBg2 from "../../icons/gameplay-bg2.png";

function Hammer() {
  const {
    animating = true,
    handleIncrement,
    handleTouchStart,
    handleClick,
    getMaxAnimationDuration,
  } = useContext(gameContext);

  const { levelImage, cachedImage, setCachedImage } = useContext(context);
  const imageDivRef = React.createRef();
  const [enableAnimation, setEnableAnimation] = useState(false);

  function pauseEvent(e) {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  }

  useEffect(() => {
    if (imageDivRef.current) {
      imageDivRef.current.addEventListener(
        "onTouchMove",
        (event) => event.preventDefault(),
        { passive: false }
      );
    }
    return () => {
      if (imageDivRef.current) {
        imageDivRef.current.removeEventListener(
          "onTouchMove",
          (event) => event.preventDefault(),
          { passive: false }
        );
      }
    };
  });

  useEffect(() => {
    if (localStorage.getItem("allowAnimation")) {
      localStorage.getItem("allowAnimation") === "true"
        ? setEnableAnimation(true)
        : setEnableAnimation(false);
    } else {
      setEnableAnimation(true);
    }
  }, []);
  return (
    <div
      ref={imageDivRef}
      className="relative min-h-[24rem]"
      style={{ height: "60%", userSelect: "none", touchAction: "none" }}
    >
      <div onTouchStart={handleTouchStart} className="mb-5">
        <img
          className="group"
          alt="Group"
          style={{ userSelect: "none", touchAction: "none" }}
          src={GameBg1}
        />
        <img
          className={`groupAnimation ${
            enableAnimation && animating ? "animate" : ""
          }`}
          style={{
            "--animation-duration": `${getMaxAnimationDuration()}ms`,
            userSelect: "none",
            touchAction: "none",
          }}
          alt="Group"
          src={GameBg2}
        />
        {cachedImage && (
          <img
            className="fi-2 no-vertical-scroll"
            alt="Clan Image"
            src={cachedImage}
            style={{ userSelect: "none", touchAction: "none" }}
          />
        )}
      </div>

      <Boosters />
    </div>
  );
}

export default Hammer;
