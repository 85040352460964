import React, { useContext } from "react";
import Avatar from "../../icons/avatar.png";
// import Clan from "../../icons/clan-mini.png";
// import DollarCoin from "../../icons/dollar-coin.svg";
import { ReactComponent as Raid } from "../../icons/raid.svg";
import useClanImage from "../../utils/useClanImage";
import { context } from "../../App";

export const UserRank = ({ userRank }) => {
  const userDetails = userRank;
  const { projects, currentProjectIndex } = useContext(context);

  const clanImage = useClanImage((projects && projects[currentProjectIndex]?.raider?.name));

  function getSuffix(index) {
    let suffix = "th";
    if (index % 10 === 1 && index % 100 !== 11) {
      suffix = "st";
    } else if (index % 10 === 2 && index % 100 !== 12) {
      suffix = "nd";
    } else if (index % 10 === 3 && index % 100 !== 13) {
      suffix = "rd";
    }
    return suffix;
  }

  return (
    <div className="px-4">
      {(userDetails !== null) && (
        <div
          style={{ boxShadow: "0px 14px 14px 0px #00000040" }}
          className="flex gap-1.5 justify-between items-center w-full border-2 border-solid border-[#2F3243] bg-[#202332] rounded-md p-3"
        >
          <div className="flex items-center gap-4">
            <div className="relative flex-none">
              <img
                src={Avatar}
                alt="avatar"
                className="w-[2.5rem] h-[2.5rem] rounded-[2.5rem]"
              />
              <img
                src={clanImage || userDetails?.raider?.image}
                alt="clan"
                className="w-[1.125rem] h-[1.125rem] rounded-[0.25rem] absolute bottom-1 -right-1.5"
              />
            </div>

            <div>
              <p className="font-semibold text-[#848AA0] text-[0.875rem]">
                {(projects && projects[currentProjectIndex]?.raider?.name)}
              </p>
              <p className="font-semibold text-white text-[0.875rem] text-ellipsis overflow-hidden max-w-[95px]">
                You
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <Raid />
            <div>
              <p className="font-semibold text-[#848AA0] text-[0.8125rem]">
                Rank
              </p>
              <p className="font-semibold text-white text-[0.8125rem]">
                {/* {userDetails?.rank??0}{getSuffix(userDetails?.rank??0)} */}
                {(userDetails.rank).toLocaleString()}{getSuffix(userDetails.rank)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
