import React, { useContext, useEffect, useState } from "react";
import { Slide } from "react-awesome-reveal";
import Actions from "../Actions";
import { EarnTask } from "./EarnTask";
import { context } from "../../App";
import { EarnConnect } from "../../services/earn.service";
import { logConsole } from "../../utils/useConsole";
import { HapticFeedback } from "../../utils/HapticFeedback";
import SoundPlayer from "../../utils/GameSounds";

export const EarnComponents = () => {
  const earn = new EarnConnect();
  const { telegramId, coins, setCoins, project_id, updateProject, triggerNotification, setShowProcessingModal } =
    useContext(context);
  const [dailyRewards, setDailyRewards] = useState(null);
  const [totalAmount, setTotalAmount] = useState("");
  const [ btnText, setBtnText ] = useState("Claim");
  const [ enableBtn, setEnbaleBtn ] = useState(true);
  const [fetchingRewards, setFetchingRewards] = useState(false);

  const getDailyReward = async () => {
    setFetchingRewards(true);
    try {
      const { data: response } = await earn.dailyRewards(telegramId);
      if (response) {
        logConsole({ response })
        setDailyRewards(response.userStreaks);
        setTotalAmount(response.totalAmount.toLocaleString());
        setEnbaleBtn(response?.enableBtn);
        setBtnText(response?.btnText || "Come back tomorrow")
        setFetchingRewards(false);
      }
    } catch (error) {
      const errorMessage = earn.handleTheError(error);
      triggerNotification("error", errorMessage);
      setFetchingRewards(false);
    }
  };

  const claimReward = async () => {
    setShowProcessingModal(true);
    try {
      HapticFeedback();
      const { data: response } = await earn.getDailyReward(telegramId);
      if (response) {
        const reward = response?.reward;
        updateProject({ project_id, coin: coins + reward });
        triggerNotification("success", "Reward Claimed!");
        SoundPlayer.playSound("success2");
        setDailyRewards(prevRewards =>
          prevRewards.map((reward, index) => {
            // if (reward.isToday) {
            //   return { ...reward, used: true };
            // }
            if (reward.isToday) {
              return { ...reward, isToday: false, used: true };
            }
            if (index > 0 && prevRewards[index - 1].isToday) {
              return { ...reward, isToday: true };
            }
            return reward;
          })
        );
        if(areAllRewardsToday(dailyRewards)){
          setBtnText("Daily Streaks Completed");
        }
        setEnbaleBtn(false);
        setTimeout(()=> window.location.reload(), 2000);
      }
    } catch (error) {
      const errorMessage = earn.handleTheError(error);
      triggerNotification("error", errorMessage);
    }
    setShowProcessingModal(false);
  };

  function areAllRewardsToday(rewards) {
    logConsole({ rewards });
    return rewards.every(reward => reward.used === true);
  }

  useEffect(() => {
    getDailyReward();
  }, []);

  return (
    <div className="relative coins h-full min-h-screen overflow-x-scroll">
      <div className="px-4">
        <div className="py-7">
          <Slide direction={"left"} duration={200}>
            <div className="text-white text-[1.5rem] font-bold">
              Earn more coins
            </div>
          </Slide>
          <Slide direction={"left"} duration={300}>
            <div className="text-[#848aa0] text-[0.938rem]">Perform tasks to earn coins</div>
          </Slide>
        </div>

        <div>
          <EarnTask 
            rewards={dailyRewards} 
            loadingRewards={fetchingRewards}
            claimReward={claimReward}
            btnText={btnText}
            disabled={!enableBtn}
          />
        </div>
      </div>

      <div className="fixed bottom-0 max-w-[450px] w-full flex flex-col justify-end">
        <Actions />
      </div>
    </div>
  );
};
