import React, { useContext } from "react";
import "./style.css";
import { Zoom } from "react-awesome-reveal";
import { context } from "../../../App";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
// import { ModalLayout } from "../ModalLayout";
import LevelUp from "../../../icons/levelup.svg";
import Lightning from "../../../icons/lightning.svg";
import DollarCoin from "../../../icons/dollar-coin.svg";
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";

export const LevelModal = ({ open, setOpen }) => {
  const { modalLevel, setModalLevel } = useContext(context);

  const closeModal = () => {
    setOpen(false);
    setModalLevel((prevModal) => ({
      ...prevModal,
      isOpen: false,
    }));
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        className="md:w-[24.7rem] md:mx-auto max-h-[90vh] overflow-x-scroll"
      >
        <div className="pt-10 pb-10">
          <Zoom duration={850}>
            <img
              className="h-[6.4375rem] w-[6.4375rem] mx-auto mb-3 object-fill"
              alt="LevelUp"
              src={LevelUp}
            />
          </Zoom>
          <div className="text-[1.5rem] text-white text-center font-bold">
            Level up
          </div>

          <div className="text-[0.9375rem] text-[#848AA0] text-center mb-8">
            Congratulations!
          </div>

          <div className="flex items-center justify-between gap-3 max-w-[350px] mx-auto mb-6">
            <div className="text-center">
              <div className="text-[0.9375rem] text-[#848AA0] text-center">
                Multiplier
              </div>
              <div className="flex items-center gap-1">
                <img
                  className="w-[1.61rem] h-[1.61rem] object-contain"
                  alt="coin"
                  src={DollarCoin}
                />
                <div className="text-[1.5rem] text-white text-center font-bold">
                  +{modalLevel?.multipler ?? 0}
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className="text-[0.9375rem] text-[#848AA0] text-center">
                Level bonus
              </div>
              <div className="flex items-center gap-1">
                <img
                  className="w-[1.61rem] h-[1.61rem] object-contain"
                  alt="coin"
                  src={DollarCoin}
                />
                <div className="text-[1.5rem] text-white text-center font-bold">
                  +{modalLevel?.bonus ?? 0}
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className="text-[0.9375rem] text-[#848AA0] text-center">
                Energy Limit
              </div>
              <div className="flex items-center gap-1">
                <img
                  className="w-[1.61rem] h-[1.61rem] object-contain"
                  alt="energy"
                  src={Lightning}
                />
                <div className="text-[1.5rem] text-white text-center font-bold">
                  +{modalLevel?.energyLevel ?? 0}
                </div>
              </div>
            </div>
          </div>

          <ButtonPrimary
            // className="frame-10"
            // divClassName="frame-instance"
            text="Close"
            onClick={closeModal}
            style={{ width: "100%" }}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};
