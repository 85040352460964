import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva } from "class-variance-authority";
import * as React from "react";
import { ReactComponent as XIcon } from "../../../icons/close.svg";
import { twMerge } from "tailwind-merge";

const Modal = SheetPrimitive.Root;

const ModalTrigger = SheetPrimitive.Trigger;

const ModalClose = SheetPrimitive.Close;

const ModalPortal = ({ ...props }) => <SheetPrimitive.Portal {...props} />;
ModalPortal.displayName = SheetPrimitive.Portal.displayName;

const ModalOverlay = React.forwardRef(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={twMerge([
      "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    ])}
    {...props}
    ref={ref}
  />
));
ModalOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
  "fixed z-50 gap-4 p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=open]:slide-in-from-bottom data-[state=closed]:animate-out data-[state=closed]:slide-out-from-bottom data-[state=closed]:duration-300 data-[state=open]:duration-500 rounded-t-[50px]",
  {
    variants: {
      side: {
        top: "inset-x-0 top-3 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom modal-shadow",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm",
        right:
          "inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm",
      },
    },
    defaultVariants: {
      side: "right",
    },
  }
);

const ModalContent = React.forwardRef(
  ({ side = "right", className, children, ...props }, ref) => (
    <ModalPortal>
      <ModalOverlay />
      <SheetPrimitive.Content
        ref={ref}
        className={twMerge([
          sheetVariants({ side }),
          "bg-[#151827]",
          className,
        ])}
        {...props}
      >
        {children}
        <SheetPrimitive.Close className={`absolute right-6 ${side === 'top' ? 'top-3' : 'top-6'} opacity-70 border-[#151827] outline-[#151827] transition-opacity hover:opacity-100 border-0 outline-none focus:border-0 focus:outline-none disabled:pointer-events-none`}>
          <XIcon className={`${side === 'top' ? 'h-6 w-6' : 'h-[36px] w-[36px]'} border-0 outline-none border-[#151827] outline-[#151827] focus:border-0 focus:outline-none`} />
          <span className="sr-only">Close</span>
        </SheetPrimitive.Close>
      </SheetPrimitive.Content>
    </ModalPortal>
  )
);
ModalContent.displayName = SheetPrimitive.Content.displayName;

const ModalHeader = ({ className, ...props }) => (
  <div
    className={twMerge([
      "flex flex-col space-y-2 text-center sm:text-left",
      className,
    ])}
    {...props}
  />
);
ModalHeader.displayName = "SheetHeader";

const ModalFooter = ({ className, ...props }) => (
  <div
    className={twMerge([
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className,
    ])}
    {...props}
  />
);
ModalFooter.displayName = "SheetFooter";

const ModalTitle = React.forwardRef(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={twMerge(["text-lg font-semibold", className])}
    {...props}
  />
));
ModalTitle.displayName = SheetPrimitive.Title.displayName;

const ModalDescription = React.forwardRef(({ className, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={twMerge(["text-sm", className])}
    {...props}
  />
));
ModalDescription.displayName = SheetPrimitive.Description.displayName;

export {
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalPortal,
  ModalTitle,
  ModalTrigger,
};
