import { config } from "../config";
import { AxiosConnect } from "./auth.service";

export class RaiderConnect {

    async getRaiders() {
        const { data: response } = await AxiosConnect.post(`/raider/clans`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    async chooseRaider(data) {
        const { data: response } = await AxiosConnect.post(`/raider/add`, data);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        return errorMessage;
    }
}
