import React from "react";
import { Zoom } from "react-awesome-reveal";
import Multitap from "../../../icons/multitap.svg";
import DollarCoin from "../../../icons/dollar-coin.svg";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
// import { ModalLayout } from "../ModalLayout";
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";

export const MultiplierModal = ({ open, setOpen, data }) => {
  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        className="md:w-[24.7rem] md:mx-auto max-h-[90vh] overflow-x-scroll"
      >
        <div className="pt-10 pb-10">
          <Zoom duration={850}>
            <img
              className="h-[6.4375rem] w-[6.4375rem] mx-auto mb-3 object-fill"
              alt="Fi"
              src={Multitap}
            />
          </Zoom>

          <div className="max-w-[270px] mx-auto">
            <p className="text-white text-[1.5rem] text-center mb-1">
              Multitap
            </p>
            <div className="text-[#848aa0] text-[0.9375rem] text-center">
              Increase the amount of coins you earn per swipe or tap
            </div>
          </div>

          <div className="flex items-center justify-between gap-2 mt-8 max-w-[230px] mx-auto">
            <div className="flex items-center justify-center gap-1">
              <img
                src={DollarCoin}
                alt="coin"
                className="h-[1.625rem] w-[1.625rem]"
              />
              <div className="text-[1.5rem] font-semibold">
                {(data.cost || 0).toLocaleString()}
              </div>
            </div>
            <div className="text-[0.9375rem] font-semibold text-[#F28A00]">
              Level {data.lvl}
            </div>
          </div>

          <div className="flex justify-center mt-8">
            <ButtonPrimary
              text={"Add +1 coin"}
              disabled={false}
              disabledText={"Not enough balance"}
              onClick={data.function}
              style={{ width: "90%" }}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
