import React, { useContext } from "react";
import { context } from "../../App";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { Warning, Info, Error, Success } from "./NotificationTypes";

const Notification = ({ type, message, id }) => {
  const { closeNotificationById } = useContext(context);
  return (
    <div
      className={`notification w-full min-h-[58px] flex items-center justify-between gap-3 bg-[#151827F2] rounded-[10px] px-3 py-1 mb-1 transition-all duration-300 ease-in-out`}
    >
      <div>
        {type === "warning" ? (
          <Warning message={message} />
        ) : type === "info" ? (
          <Info message={message} />
        ) : type === "error" ? (
          <Error message={message} />
        ) : type === "success" ? (
          <Success message={message} />
        ) : (
          ""
        )}
      </div>
      {/* The close icon */}

      <button type="button" onClick={() => closeNotificationById(id)}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default Notification;
