import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import Info from "../../../icons/chat-info.svg";

const CharacterDetailModal = ({ open, setOpen, currentStep, levels, leader }) => {

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side="bottom"
        className="md:w-[24.7rem] md:mx-auto overflow-x-scroll"
      >
        <div className="flex flex-col justify-center w-full">
          <img
            src={Info}
            alt={Info}
            className="rounded-md mx-auto mb-8 w-[6.4rem] h-[6.4rem]"
          />

          <p className="text-white text-3xl font-bold text-center mb-8">
            {leader?.name}
          </p>

          <p className="text-[#848AA0] text-[15px] font-semibold text-center mb-6">
            {leader?.description}
          </p>

          <div className="flex justify-center mt-4">
            <ButtonPrimary
              text={"Close"}
              onClick={() => setOpen(false)}
              className={"!w-[18rem] mx-auto text-[20px]"}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CharacterDetailModal;
