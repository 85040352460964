import React from "react";

export const Description = ({ data }) => {
  return (
    <div className="mb-6">
      <div className="p-4 bg-[#151827] rounded-[10px]">
        <h3 className="text-white text-base font-bold">{`About ${data?.airdrop?.name}`}</h3>
      </div>
      <div className="pl-4 mt-2 mb-6">
        <p className="text-[0.81rem] text-white font-medium">
          {data?.airdrop?.about}{" "}
        </p>
      </div>

      <div className="p-4 bg-[#151827] rounded-[10px]">
        <h3 className="text-white text-base font-bold">Airdrop Reward</h3>
      </div>
      <div className="pl-4 mt-2 mb-4">
        <ol className="list-decimal">
          {data?.airdrop?.rewardSteps.map((step, index) => (
              <li key={index} className="text-[0.81rem] text-white font-medium mb-4">
                {step?.description??""}
              </li>
            ))}
        </ol>
      </div>
    </div>
  );
};
