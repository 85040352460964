import { splitString } from "../../ProjectDetails/AirdropQuest"
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout"
import { ButtonPrimary } from "../../buttons/ButtonPrimary";

const UnlockedAirdropQuest = ({ open, setOpen, data, completeQuest }) => {

    return (
    <Modal open={open} onOpenChange={setOpen}>
        <ModalContent
            side={'bottom'}
            className="md:w-[24.7rem] md:mx-auto overflow-x-scroll"
        >
            <div className="flex flex-col gap-4 mt-6 justify-center w-full">
                <h4 className="text-white text-[32px] font-semibold text-center mb-1">
                    {data.title}
                </h4>
                {
                    splitString(data?.text).length > 1 ?
                    splitString(data?.text).map((text, idx) => (
                        <div key={idx} className="flex flex-row gap-4 mb-3">
                            <div className="w-2 h-2 rounded-full" />
                            <p className="text-[#848AA0] font-semibold">{text}</p>
                        </div>
                    )) : 
                    <p className="text-[#848AA0] font-semibold text-center my-4">{data?.text}</p>
                }

                <ButtonPrimary 
                    disabled={data.completed}
                    disabledText={"Completed"}
                    text={data.completed ? (
                        "Completed"
                      ) : (
                        data?.btn || "Join"
                      )}
                    onClick={() => completeQuest(data?.item, data?.activityItem, data?.activityItemIdx, data?.index)}
                    className={'mx-auto'}
                />
            </div>
        </ModalContent>
    </Modal>
  )
}

export default UnlockedAirdropQuest