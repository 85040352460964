import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./style.css";
import Coin from "../../icons/actions-coin.svg";
import Earn from "../../icons/actions-earn.svg";
import Friends from "../../icons/actions-friends.svg";
import Airdrop from "../../icons/actions-airdrop.png";
import Projects from "../../icons/actions-projects.svg";
import { HapticFeedback } from "../../utils/HapticFeedback";
import SoundPlayer from "../../utils/GameSounds";

const navLinks = [
  {
    title: "Coins",
    href: "/",
    img: Coin,
  },
  {
    title: "Projects",
    href: "/projects",
    img: Projects,
  },
  {
    title: "Friends",
    href: "/friends",
    img: Friends,
  },
  {
    title: "Earn",
    href: "/earn",
    img: Earn,
  },
  {
    title: "More",
    href: "/airdrops",
    img: Airdrop,
  },
];

const hapticSound = () => {
  HapticFeedback();
  SoundPlayer.playSoundMultiple("menu1");
};
function Actions() {
  const location = useLocation();
  return (
    <div className="flex justify-around items-center bg-[#13151e] h-[80px] w-full px-[6px] py-[4px]">
      {navLinks?.length > 0 &&
        navLinks?.map((nav) => (
          <Link to={nav?.href} key={nav?.title}>
            <div
              onClick={hapticSound}
              className={`cursor-pointer py-1 px-3 rounded-[3.25rem] ${
                location?.pathname === nav?.href
                  ? "bg-[#0d0f18]"
                  : "bg-transparent"
              }`}
            >
              <div className="items-center justify-center inline-flex flex-col relative gap-2">
                <img
                  className="h-[1.25rem] w-auto object-fill"
                  alt="icon"
                  src={nav?.img}
                />
                <div className="text-white text-[0.82rem]">{nav?.title}</div>
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
}

export default Actions;
