import React from "react";
import "./style.css";
import Actions from "../Actions";
import { SwitchSettings } from "./SwitchSettings";
import { ChooseSettings } from "./ChooseSettings";

export const SettingsComponents = () => {
  return (
    <div className="boosters action">
      <div className="div pb-[6rem] px-4">
        <div className="pb-10 pt-8">
          <div className="text-white text-[1.5rem] font-bold">Settings</div>
        </div>

        <ChooseSettings />
        <SwitchSettings />
      </div>

      <div className="fixed bottom-0 max-w-[450px] w-full flex flex-col justify-end">
        <Actions />
      </div>
    </div>
  );
};
