import { Modal, ModalContent } from "../ModalLayout/NewModalLayout"
import DollarCoin from "../../../icons/dollar-coin.svg";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";

const ProductModal = ({ open, setOpen, data, buyItem, btn }) => {
  return (
    <Modal open={open} onOpenChange={setOpen}>
        <ModalContent
            side='bottom'
            className="md:w-[24.7rem] md:mx-auto overflow-x-scroll"
        >
            <div className="flex flex-col justify-center w-full">
                <img
                    src={data.image}
                    alt={data.title}
                    className="rounded-md mx-auto mb-3 w-[40%]"
                />
                <h4 className="text-[32px] font-semibold text-center mb-1">
                    {data.name}
                </h4>
                <p className="text-[#848AA0] text-[15px] font-semibold text-center mb-6">
                    {data.description}
                </p>
                <div className="flex flex-row justify-center gap-4 items-center font-semibold mb-4">
                    <p className="text-[#848AA0] text-[15px]">Loot per hour</p>
                    <div className="flex items-center justify-center gap-1">
                        <img src={DollarCoin} alt="coin" className="h-[1.7rem] w-[1.7rem] mix-blend-luminosity" />
                        <div className="text-2xl text-[#848AA0] font-semibold">+{Number(data?.mineLevel?.perHourGain??0).toLocaleString()}</div>
                    </div>
                </div>
                <div className="flex flex-row justify-center gap-4 items-center font-semibold mb-4">
                    <p className="text-[#848AA0] text-lg">Level {" "}{data?.mineLevel?.level??0}</p>
                    <div className="flex items-center justify-center gap-1">
                        <img src={DollarCoin} alt="coin" className="h-[2.2rem] w-[2.2rem]" />
                        <div className="text-[2rem] font-semibold">{Number(data?.mineLevel?.cost??0).toLocaleString()}</div>
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    <ButtonPrimary
                        text={"Purchase"}
                        onClick={buyItem}
                        disabled={btn}
                        disabledText={"Purchase"}
                        className={"!w-[18rem] mx-auto text-[20px]"}
                    />
                </div>
            </div>
        </ModalContent>
    </Modal>
  )
}

export default ProductModal