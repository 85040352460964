import React from "react";
import { ProjectsComponents } from "../components/Projects";

function Projects() {
  return (
    <div>
      <ProjectsComponents />
    </div>
  );
}

export default Projects;
