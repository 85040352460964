import { useContext } from "react";
import { Slide } from "react-awesome-reveal";
import Actions from "../Actions";
import { AllProjects } from "./AllProjects";
// import { EndedProjects } from "./EndedProjects";
// import { JoinedProjects } from "./JoinedProjects";
// import SoundPlayer from "../../utils/GameSounds";
// import { AirdropConnect } from "../../services/airdrop.service";
// import { logConsole } from "../../utils/useConsole";
import { context } from "../../App";

// const tabs = [
//   {
//     name: "All",
//     href: "all",
//   },
//   {
//     name: "Joined",
//     href: "joined",
//   },
//   {
//     name: "Ended",
//     href: "ended",
//   },
// ];

export const ProjectsComponents = () => {
  // const [activeTab, setActiveTab] = useState("all");
  const { telegramId, allAirdrops, setAllAirdrops } = useContext(context);

  // const handleTabClick = (tab) => {
  //   if (activeTab !== tab?.href) {
  //     setActiveTab(tab?.href);
  //   }
  // };

  return (
    <div className="relative coins h-full min-h-screen">
      <div className="px-4 pb-[6rem]">
        <div className="pt-7 pb-8">
          <Slide direction={"left"} duration={200}>
            <div className="text-white text-[1.5rem] font-bold">
              Airdrop Projects
            </div>
          </Slide>
          <Slide direction={"left"} duration={300}>
            <div className="text-[#848aa0] text-[0.975rem]">
              Join and raid any project airdrop
            </div>
          </Slide>
        </div>

        {/* <div className="mb-6">
          <nav className="flex items-center gap-3">
            {tabs?.map((tab, index) => (
              <button
                key={index}
                type="button"
                onClick={() => handleTabClick(tab)}
                className={`w-[88px] py-1 text-[1.06rem] text-center outline-none border border-solid rounded-[2.5rem] ${
                  activeTab === tab?.href
                    ? "text-[#0D0F18] bg-[#83C60D] border-none"
                    : "text-[#848AA0] bg-transparent border-[#212942] border-2"
                }`}
              >
                {tab?.name}
              </button>
            ))}
          </nav>
        </div> */}
        <AllProjects airdrops={allAirdrops} />
        {/* <div>
          {activeTab === "all" && <AllProjects airdrops={allAirdrops} />}
          {activeTab === "joined" && <JoinedProjects airdrops={allAirdrops} />}
          {activeTab === "ended" && <EndedProjects airdrops={allAirdrops} />}
        </div> */}
      </div>

      <div className="fixed bottom-0 max-w-[450px] w-full flex flex-col justify-end">
        <Actions />
      </div>
    </div>
  );
};
