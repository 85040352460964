import React from "react";
import { FriendComponents } from "../components/Friends";

function Friends() {
  return (
    <div>
      <FriendComponents />
    </div>
  );
}

export default Friends;
