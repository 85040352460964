import React from "react";
import "./style.css";
import { Slide, Zoom } from "react-awesome-reveal";
import { ReactComponent as XIcon } from "../../../icons/close.svg";
// import { ButtonPrimary } from "../../buttons/ButtonPrimary";

export const ModalLayout = ({ open, setOpen, icon, content }) => {
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div>
      {open && (
        <div className="start max-w-[450px] max-h-[90vh]">
          <Slide direction={"up"} duration={500}>
            <div className="rounded-t-[3.125rem] bg-[#151827] relative min-w-[300px] min-h-[290px] w-full text-center px-4 pt-10 pb-10 modal-shadow">
              {/* The close button */}
              <XIcon
                className="absolute cursor-pointer right-8 top-8 h-[2.25rem] w-[2.25rem] z-50"
                onClick={() => closeModal()}
              />
              {/* The modal design starts here */}
              {icon && (
                <Zoom duration={850}>
                  <img
                    className="h-[6.4375rem] w-[6.4375rem] mx-auto mb-3 object-fill"
                    alt="Fi"
                    src={icon}
                  />
                </Zoom>
              )}

              {content}
            </div>
          </Slide>
        </div>
      )}
    </div>
  );
};
