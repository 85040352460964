import { UserConnect } from "../services/user.service"
import { clearDB } from "./clearDB";


const user = new UserConnect();

export default async function validateMiniData(initData){
    try {
        const { data: response } = await user.validateData({ initData });
        // console.log({ response });
        if(response) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        const errorMessage = user.handleTheError(error);
        console.error({ errorMessage });
    }
}