import { createContext, useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useFetcher,
} from "react-router-dom";
import { Onboarding } from "./components/OnBoarding";
import { Modal } from "./components/modals/Modal";
import { v4 } from "uuid";
import { LevelModal } from "./components/modals/LevelModal";
import { LootModal } from "./components/modals/LootModal";
import Dashboard from "./pages/Dashboard";
import Boosters from "./pages/Boosters";
import Airdrops from "./pages/Airdrops";
import Upgrade from "./pages/Upgrade";
import Earn from "./pages/Earn";
import Friends from "./pages/Friends";
import ChooseClan from "./pages/ChooseClan";
import ChangeClan from "./pages/ChangeClan";
import LoadGame from "./pages/LoadGame";
import Settings from "./pages/Settings";
import Characters from "./pages/Characters";
import RoadmapPage from "./pages/Roadmap";
import Projects from "./pages/Projects";
import ProjectDetails from "./pages/ProjectDetails";
import RaidLeaderboard from "./pages/RaidLeaderboard";
import { UserConnect } from "./services/user.service";
import { ExchangeConnect } from "./services/exchange.service";
import Notification from "./components/notifications";
import { LoadGameModal } from "./components/modals/LoadGameModal";
import { logConsole } from "./utils/useConsole";
import { hardReset } from "./utils/hardReset";
import NewRoadMap from "./components/NewRoadmap";
import moment from "moment";
import { clearDB } from "./utils/clearDB";
import PageNotFound from "./pages/PageNotFound";
import MarketplacePage from "./pages/Marketplace";
import { AirdropConnect } from "./services/airdrop.service";
import { LevelConnect } from "./services/level.service";
import { MarketPlaceConnect } from "./services/marketplace.service";
import validateMiniData from "./utils/validateMiniData";
import ProcessingBarModal from "./components/modals/ProcessingBarModal";

export const context = createContext({});

const getProjectsFromLocalStorage = () => {
  const projects = localStorage.getItem("projects");
  if (projects) {
    try {
      const parsedProjects = JSON.parse(projects);
      if (Array.isArray(parsedProjects) && parsedProjects.length > 0) {
        logConsole(`Parsed ${parsedProjects[0].project_id}`);
        return parsedProjects[0].project_id;
      }
    } catch (e) {
      logConsole("Error parsing projects from localStorage:", e);
    }
  }
  return null;
};

const getProjectsCoinsFromLocalStorage = () => {
  const projects = localStorage.getItem("projects");
  if (projects) {
    try {
      const parsedProjects = JSON.parse(projects);
      if (Array.isArray(parsedProjects) && parsedProjects.length > 0) {
        logConsole(`Parsed ${parsedProjects[0].project_id}`);
        return parsedProjects[0].coin;
      }
    } catch (e) {
      logConsole("Error parsing projects from localStorage:", e);
    }
  }
  return 0;
};

const getProjectsRemainingEnergyFromLocalStorage = () => {
  const projects = localStorage.getItem("projects");
  if (projects) {
    try {
      const parsedProjects = JSON.parse(projects);
      if (Array.isArray(parsedProjects) && parsedProjects.length > 0) {
        logConsole(`Parsed ${parsedProjects[0].remainingEnerg}`);
        return parsedProjects[0].remainingEnergy;
      }
    } catch (e) {
      logConsole("Error parsing projects from localStorage:", e);
    }
  }
  return 0;
};

function App() {
  const NOW = moment();
  const auth = new UserConnect();
  const airdrop = new AirdropConnect();
  const levelConnect = new LevelConnect();
  const marketplace = new MarketPlaceConnect();
  const [showRoadMap, setShowRoadMap] = useState(true);
  const [telegramId, setTelegramId] = useState(
    localStorage.getItem("telegramId")
  );
  const [openLevelModal, setLevelModal] = useState(false);
  const [project_id, setProject_id] = useState(getProjectsFromLocalStorage);
  const [showOnBoarding, setShowOnBoarding] = useState(false);
  const [showGame, setShowGame] = useState(false);
  const [showClan, setShowClan] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [projects, setProjects] = useState(null);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [coins, setCoins] = useState(getProjectsCoinsFromLocalStorage());
  const [firstname, setFirstname] = useState("...");
  const [photoURL, setPhotoURL] = useState("https://iili.io/dfLYFJs.png");
  const [multipler, setMultipler] = useState(0);
  // const [isGreaterThan10Sec, setIsGreaterThan10Sec] = useState(false);
  const [energyLevel, setEnergyLevel] = useState(0);
  const [remainingEnergyLevel, setRemainingEnergyLevel] = useState(
    getProjectsRemainingEnergyFromLocalStorage()
  );
  const [userExchange, setUserExchange] = useState(null);
  const [profitPerHour, setProfitPerHour] = useState(0);
  const [profitPerHourTime, setProfitPerHourTime] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [currentLevelName, setCurrentLevelName] = useState(null);
  const [totalLevel, setTotalLevel] = useState(0);
  const [disableTapping, setDisableTapping] = useState(false);
  const [loadGameModal, setLoadGameModal] = useState(false);
  const [nextLevelUp, setNextLevelUp] = useState(
    Number(localStorage.getItem("nextLevel")) || 0
  );
  const [levelImage, setLevelImage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [timeDifference, setTimeDifference] = useState(0);
  const [showSplash, setShowSplash] = useState(true);
  const [successfulApiCalls, setSuccessfulApiCalls] = useState(0);
  const [apiCount, setApiCount] = useState(14);
  const [allAirdrops, setAllAirdrops] = useState(null);
  const [leaderBoard, setLeaderBoard] = useState({});
  const [items, setItems] = useState(null);
  const [dailyCombo, setDailyCombo] = useState(null);
  const [showUnlockedDailyComboModal, setShowUnlockedDailyComboModal] =
    useState(false);
  const [ exe, setExe ] = useState(false);
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [combo_id, setCombo_id] = useState("");
  const [openLootModal, setOpenLootModal] = useState(false);
  const [profitPerHourBtn, setProfitPerHourBtn] = useState(false);
  const [lootLastActiveTime, setLootLastActiveTime] = useState(() => {
    const storedTime = localStorage.getItem("lootLastActiveTime");
    return storedTime ? moment(storedTime) : moment();
  });
  const [showOfflinePlayerModal, setShowOfflinePlayerModal] = useState(false);

  const incrementSuccessfulApiCalls = () => {
    setSuccessfulApiCalls((prev) => {
      // logConsole({ successfulApiCalls: prev + 1 }, true);
      return prev + 1;
    });
  };

  const checkPlatform = () => {
    if (window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;
      const includes = [
        "",
        "linux",
        "macos",
        "windows",
        "tdesktop",
        "browser",
        "tab",
        "desktop",
        "",
        "web",
        "tab",
      ].includes(webApp?.platform);
      if (includes) {
        setTelegramId(null);
        if (window.location.pathname !== "/404") {
          window.location = "/404";
          setProjects([]);
          localStorage.setItem("projects", []);
          clearDB();
        }
        setProjects([]);
        localStorage.setItem("projects", []);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;
      webApp.ready();
      if (!webApp.isExpanded) {
        webApp.expand();
        setTimeout(function () {
          webApp.lockView();
        }, 1000);
      }

      const user = webApp.initDataUnsafe.user;
      if (user) {
        logConsole("Telegram user detected");
        setTelegramId(user.id);
        localStorage.setItem("telegramId", user.id);
        setFirstname(user.first_name);
        setPhotoURL(
          user?.photo_url ||
            "https://iili.io/dfLYFJs.png"
        );
      }

      logConsole({ platform: webApp.platform, version: webApp.version }, true);
    } else {
      logConsole("Telegram Web App SDK is not available");
    }
  }, [telegramId]);


  const validateMiniData = async (initData) => {
    const result = await validateMiniData(initData);
    if(result){
      triggerNotification("info", "Data validated");
    } else {
      triggerNotification("error", "Unbale to validate data");
    }
  }

  useEffect(() => {
    if (window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;
      webApp.ready();
      if (!webApp.isExpanded) {
        webApp.expand();
        setTimeout(function () {
          webApp.lockView();
        }, 1000);
      }

      const user = webApp.initData;
      console.log({ initData: user }, true);
      if(user){
        validateMiniData(user);
      }

    } else {
      logConsole("Telegram Web App SDK is not available");
    }
  }, [telegramId]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    const handleBackButton = () => {
      navigate(-1);
    };

    tg.onEvent("backButtonClicked", handleBackButton);

    return () => {
      tg.offEvent("backButtonClicked", handleBackButton);
    };
  }, [navigate]);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    if (location.pathname !== "/") {
      tg.BackButton.show();
    } else {
      tg.BackButton.hide();
    }
  }, [location.pathname]);

  const [modal, setModal] = useState({
    isOpen: false,
    countdown: false,
    message: "",
    error: false,
    description: "",
    btnTxt: "Accept",
    link: "",
    coin: null,
    firstTime: false,
  });

  const [modalLevel, setModalLevel] = useState({
    isOpen: false,
    countdown: false,
    bonus: 0,
    energyLevel: 0,
    multipler: 0,
  });

  const [raiderLevel, setRaiderLevel] = useState({
    isOpen: false,
    countdown: false,
    name: "",
    reward: "",
    projectName: "",
  });

  
  const [lastActiveTime, setLastActiveTime] = useState(() => {
    const storedTime = localStorage.getItem("lastActiveTime");
    return storedTime != null ? parseInt(storedTime, 10) : NOW.valueOf();
  });

  const setToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const updateProject = (data) => {
    const { project_id } = data;
    if (project_id) {
      setProjects((projects) => {
        if (!Array.isArray(projects)) {
          projects = [];
        }

        const updatedProjects = [...projects];
        const projectIndex = projects.findIndex(
          (project) => project.project_id === project_id
        );

        if (projectIndex !== -1) {
          updatedProjects[projectIndex] = {
            ...updatedProjects[projectIndex],
            ...data,
          };
        } else if (
          currentProjectIndex >= 0 &&
          currentProjectIndex < updatedProjects.length
        ) {
          updatedProjects[currentProjectIndex] = {
            ...updatedProjects[currentProjectIndex],
            ...data,
          };
        } else {
          updatedProjects.push(data);
        }
        const isPlatform = checkPlatform();
        if (isPlatform) {
          setToLocalStorage("projects", updatedProjects);
        }
        return updatedProjects;
      });
    }
  };

  const getFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };

  const userTap = async (coins_) => {
    if (coins_ > 2) {
      try {
        logConsole({ userTapClient: coins_ });
        const { data } = await auth.useTap({
          telegramId,
          project_id,
          tapValue: coins_,
        });
        if (data) {
          logConsole({ tapData: data });
          const newCoinValue = data?.coin ?? coins_;
          setCoins(newCoinValue);
          updateProject({ project_id, coin: newCoinValue });
          logConsole({
            message: "Calling backend",
            userTapBackend: newCoinValue,
            coins_: coins_,
            referralBonus: data?.referralBonus,
          });
        }
      } catch (error) {
        const errorMessage = auth.handleTheError(error) + ", while syncing.";
        logConsole(errorMessage, true);
      }
    }
  };

  const updateLastActiveTime = () => {
    const now = moment();
    setLootLastActiveTime(now);
    localStorage.setItem("lootLastActiveTime", now.toISOString());
  };

  useEffect(() => {
    if (modal.countdown) {
      const timer = setTimeout(() => {
        setModal((prevModal) => ({
          ...prevModal,
          isOpen: false,
          countdown: false,
        }));
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, [modal.isOpen]);

  useEffect(() => {
    if (multipler > 0) {
    const interval = setInterval(() => {
        const now = moment();
        const storedTime = localStorage.getItem("lastActiveTime");

        if (storedTime !== null && remainingEnergyLevel !== null) {
          const lastActiveMoment = moment(parseInt(storedTime, 10));
          const diffInSeconds = now.diff(lastActiveMoment, "seconds");

          setTimeDifference(diffInSeconds);
          if (remainingEnergyLevel >= energyLevel) {
            setRemainingEnergyLevel(energyLevel);
            updateProject({ project_id, remainingEnergy: energyLevel });
          } else if (remainingEnergyLevel < 0) {
            setRemainingEnergyLevel(0);
            updateProject({ project_id, remainingEnergy: 0 });
          } else {
            if (storedTime !== null && remainingEnergyLevel !== null) {
              const lastActiveMoment = moment(parseInt(storedTime, 10));
              const diffInSeconds = now.diff(lastActiveMoment, "seconds");

              const increaseBy = multipler > 3 ? 3 : multipler;

              if (diffInSeconds * increaseBy > 6) {
                logConsole(
                  {
                    diffInSeconds,
                    increaseBy,
                    energyToGet: diffInSeconds * increaseBy,
                  },
                  true
                );
                if (diffInSeconds * increaseBy >= energyLevel) {
                  setRemainingEnergyLevel(energyLevel);
                } else {
                  setRemainingEnergyLevel(
                    (prevCount) => prevCount + diffInSeconds * increaseBy
                  );
                }
              } else {
                setRemainingEnergyLevel((prevCount) =>
                  prevCount + increaseBy > energyLevel
                    ? energyLevel
                    : prevCount + increaseBy
                );
                updateProject({
                  project_id,
                  remainingEnergy: remainingEnergyLevel + increaseBy,
                });
              }

              const currentTime = now.valueOf();
              setLastActiveTime(currentTime);
              localStorage.setItem("lastActiveTime", currentTime);
            } else {
              const currentTime = now.valueOf();
              localStorage.setItem("lastActiveTime", currentTime);
              setLastActiveTime(currentTime);
            }
          }

          const currentTime = now.valueOf();
          setLastActiveTime(currentTime);
          localStorage.setItem("lastActiveTime", currentTime);
        } else {
          const currentTime = now.valueOf();
          localStorage.setItem("lastActiveTime", currentTime);
          setLastActiveTime(currentTime);
        }
    }, 1100);

    return () => clearInterval(interval);
  }
  }, [
    lastActiveTime,
    multipler,
    energyLevel,
    remainingEnergyLevel
  ]);

  useEffect(() => {
    setProfitPerHourTime(profitPerHour / 3600);
    if (profitPerHour > 0) {
      const interval = setInterval(() => {
        setCoins((prevCount) => prevCount + profitPerHourTime);
        updateProject({ project_id, coin: coins });

        const now = moment();
        const diffInMinutes = now.diff(lootLastActiveTime, "minutes");
        if (diffInMinutes < 1) {
          updateLastActiveTime();
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [profitPerHour, profitPerHourTime, lootLastActiveTime ]);


  useEffect(() => {
    if(!profitPerHourBtn){
      const local = localStorage.getItem("lootLastActiveTime");
      if (!local || local == null) {
        updateLastActiveTime();
      }
      const now = moment();
      const diffInMinutes = now.diff(lootLastActiveTime, "minutes");
      if (diffInMinutes >= 1 && profitPerHour > 0) {
        setOpenLootModal(true);
      }
    }
  }, [ profitPerHour, lootLastActiveTime]);



  const calculateProftPerHour = () => {
    const now = moment();
    const lootLastActiveTime = moment(localStorage.getItem('lootLastActiveTime'));
    const diffInMinutes = now.diff(lootLastActiveTime, 'minutes');
    const diffInHours = diffInMinutes / 60;
    
    if (diffInHours >= 3) {
      return Math.floor(profitPerHour * 3);
    } else if (diffInHours < 3 && diffInMinutes >= 1) {
      return Math.floor(profitPerHour * diffInHours);
    } else {
      return 0
    }
  }
  

  const claimProfitPerHour = async () => {
    setShowProcessingModal(true);
    setProfitPerHourBtn(true);
    const now = moment();
    const lootLastActiveTime = moment(localStorage.getItem('lootLastActiveTime'));
    const diffInMinutes = now.diff(lootLastActiveTime, 'minutes');
    const diffInHours = diffInMinutes / 60;
    
    if (diffInHours >= 3) {
      const gain = profitPerHour * 3;
      setCoins((coin) => coin + gain);
      updateProject({ project_id, coin: coins + gain });
      await userTap(coins + gain);
    } else if (diffInHours < 3 && diffInMinutes >= 1) {
      const gain = profitPerHour * diffInHours;
      setCoins((coin) => coin + gain);
      updateProject({ project_id, coin: coins + gain });
      await userTap(coins + gain);
    }

    setProfitPerHourBtn(true);
    setShowProcessingModal(false);
    setOpenLootModal(false);
    updateLastActiveTime();
  };

  const checkActiveTime = (remainingEnergy_) => {
    if (localStorage.getItem("lastActiveTime") != null) {
      logConsole({ message: "Remaining Energy Enabled" });
    } else {
      setRemainingEnergyLevel(remainingEnergy_);
      const currentTime = Date.now();
      localStorage.setItem("lastActiveTime", currentTime);
      setLastActiveTime(currentTime);
    }
  };

  useEffect(() => {
    const localProjects = getFromLocalStorage("projects");
    if (localProjects) {
      const response_ = localProjects[currentProjectIndex];
      setProject_id(response_?.project_id);
    }
  }, [project_id, currentProjectIndex]);

  const requestHardreset = async () => {
    const { data: response } = await auth.requestHardreset(telegramId);
    if (response && response.keyValue) {
      logConsole({ keyValue: response.keyValue });
      hardReset(response.keyValue);
    }
  };

  const getProjects = async (useCloud) => {
    logConsole({ useCloud });
    hardReset();
    const localProjects = getFromLocalStorage("projects");
    logConsole({ localProjects });
    await userTap(coins);
    if (!useCloud) {
      if (localProjects && localProjects.length > 0) {
        const response_ = localProjects[currentProjectIndex];

        setMultipler(response_?.earnPerTap || 0);
        setEnergyLevel(response_?.energyLevel ?? 0);
        setRemainingEnergyLevel(response_?.remainingEnergy);
        setProjects(localProjects);
        checkActiveTime(response_?.energyLevel ?? 0);
        setProject_id(response_?.project_id || 0);
        setCoins(response_?.coin ?? 0);
        setProfitPerHour(response_?.profitPerHour ?? 0);
        incrementSuccessfulApiCalls();
      } else {
        try {
          setProjects([]);
          const response = await auth.userUserProjectDetails(telegramId);
          logConsole({ getProjects: response.data });
          const response_ = response.data[currentProjectIndex];
          setMultipler(response_?.earnPerTap ?? 0);
          setEnergyLevel(response_?.energyLevel ?? 0);
          if (localProjects !== null) {
            const Localresponse = localProjects[currentProjectIndex];
            checkActiveTime(response_?.energyLevel ?? 0);
            setProject_id(response_?.project_id || "");
            setRemainingEnergyLevel(Localresponse?.remainingEnergy ?? 0);
            let saveData = response?.data;
            saveData[currentProjectIndex].remainingEnergy =
              Localresponse?.remainingEnergy ?? 0;
            setToLocalStorage("projects", saveData);
          } else {
            let saveData = response?.data;
            saveData[currentProjectIndex].remainingEnergy =
              saveData[currentProjectIndex].energyLevel ?? 0;
            setToLocalStorage("projects", saveData);
          }
          setProjects(response?.data);
          checkActiveTime(response_?.energyLevel ?? 0);
          setProject_id(response_?.project_id || "");
          setCoins(response_?.coin ?? 0);
          setProfitPerHour(response_?.profitPerHour ?? 0);
          await requestHardreset();
          incrementSuccessfulApiCalls();
        } catch (error) {
          const errMsg = auth.handleTheError(error);
          triggerNotification("error", errMsg);
        }
      }
    } else {
      try {
        const response = await auth.userUserProjectDetails(telegramId);
        logConsole({ getProjects: response.data });
        const response_ = response.data[currentProjectIndex];
        setMultipler(response_?.earnPerTap ?? 0);
        setEnergyLevel(response_?.energyLevel ?? 0);
        if (localProjects !== null) {
          const Localresponse = localProjects[currentProjectIndex];
          checkActiveTime(response_?.energyLevel ?? 0);
          setProject_id(response_?.project_id || "");
          setRemainingEnergyLevel(Localresponse?.remainingEnergy ?? 0);
          let saveData = response?.data;
          saveData[currentProjectIndex].remainingEnergy =
            Localresponse?.remainingEnergy ?? 0;
          setToLocalStorage("projects", saveData);
        } else {
          let saveData = response?.data;
          saveData[currentProjectIndex].remainingEnergy =
            saveData[currentProjectIndex].energyLevel ?? 0;
          setToLocalStorage("projects", saveData);
        }
        setProjects(response?.data);
        setCoins(response_?.coin ?? 0);
        setProfitPerHour(response_?.profitPerHour ?? 0);
        await requestHardreset();
        incrementSuccessfulApiCalls();
      } catch (error) {
        const errMsg = auth.handleTheError(error);
        triggerNotification("error", errMsg);
      }
    }
    checkPlatform();
  };

  const getUserExchange = async (useCloud) => {
    try {
      if (project_id) {
        await getProjects(useCloud);
        await userTap(coins);

        const response = await new ExchangeConnect().getUserExchange(
          telegramId,
          project_id
        );
        const response_ = response.data?.level;
        setUserExchange(response.data);
        logConsole({ userExchange: response.data });
        setToLocalStorage("userExchange", response.data);
        setCurrentLevel(response_?.currentLevel?.lvl);
        setCurrentLevelName(response_?.currentLevel?.name);
        setLevelImage(response_?.currentLevel?.levelImage);
        setTotalLevel(response_?.levelLength);
        const levelCondition = response_?.newLevel === true;
        if (levelCondition) {
          const bonus = response_.currentLevel?.levelUpBonus || 0;
          setLevelModal(true);
          setModalLevel(() => ({
            isOpen: true,
            multipler: response_?.currentLevel?.multiTap,
            bonus: Math.floor(bonus).toLocaleString(),
            energyLevel: response_?.currentLevel?.energyLimit,
          }));
          const newMultipler =
            multipler + (response_?.currentLevel?.multiTap || 0);
          const newEnergyLimit =
            energyLevel + (response_?.currentLevel?.energyLimit || 0);
          setMultipler(newMultipler);
          setCoins(coins + bonus);
          setEnergyLevel(
            (prevEnergy) =>
              prevEnergy + (response_?.currentLevel?.energyLimit ?? 0)
          );
          setRemainingEnergyLevel(
            () => energyLevel + (response_?.currentLevel?.energyLimit ?? 0)
          );
          updateProject({
            project_id,
            earnPerTap: newMultipler,
            energyLevel: newEnergyLimit,
            coin: coins + bonus,
            remainingEnergy: newEnergyLimit,
          });
        }
        setNextLevelUp(response_?.nextLevelUp);
        localStorage.setItem("nextLevel", response_?.nextLevelUp);
        setDisableTapping(false);
        await getProjects(useCloud);
        incrementSuccessfulApiCalls();
      } else {
        setDisableTapping(false);
        logConsole("No project_id");
        await getProjects(useCloud);
      }
    } catch (error) {
      setDisableTapping(false);
      const errMsg = auth.handleTheError(error);
      triggerNotification("error", errMsg);
    }
    checkPlatform();
  };

  useEffect(() => {
    if (window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;
      webApp.ready();
      if (!webApp.isExpanded) {
        webApp.expand();
      }

      const user = webApp.initDataUnsafe.user;
      if (user) {
        logConsole("Telegram user detected");
        setTelegramId(user.id);
        const localId = localStorage.getItem("telegramId");
        // if((localId !== null) && user?.id && (localId !== user.id)) {
        //   clearDB();
        //   localStorage.setItem("telegramId", user.id);
        //   webApp.close();
        // }
        localStorage.setItem("telegramId", user.id);
        setFirstname(user.first_name);
        setPhotoURL(
          user?.photo_url ||
            "https://iili.io/dfLYFJs.png"
        );
      }
    } else {
      logConsole("Telegram Web App SDK is not available");
    }
    if (telegramId) {
      if (checkPlatform()) {
        getProjects();
        getUserExchange(false);
      }
    }
  }, [telegramId]);

  useEffect(() => {
    getUserExchange(true);
  }, [project_id, exe ]);

  useEffect(() => {
    if (successfulApiCalls >= apiCount) {
      const hasOnBoarded = localStorage.getItem("hasOnBoarded");

      setShowRoadMap(false);

      if (!hasOnBoarded) {
        setShowOnBoarding(true);
      } else {
        if ((projects && projects[currentProjectIndex]?.raider) !== null) {
          setShowGame(true);
        } else {
          setShowClan(true);
          setShowGame(false);
        }
      }
    }
  }, [successfulApiCalls]);

  const checkNextLevel = () => {
    if (telegramId) {
      getUserExchange(true);
    }
  };

  const getAirdrops = async () => {
    try {
      const { data: response } = await airdrop.userAirdrops(telegramId);
      if (response) {
        setAllAirdrops(response?.airdrop?.filter(( airdrop ) => airdrop?.airdrop?.status !== "suspended" ));
        incrementSuccessfulApiCalls();
        logConsole(response, true);
      }
    } catch (error) {
      const errorMessage = airdrop.handleTheError(error);
      logConsole({ errorMessage }, true);
    }
  };

  useEffect(() => {
    getAirdrops();
  }, []);

  const getLeaderBoard = async () => {
    try {
      const { data: response } = await levelConnect.getLeaderBoard(telegramId);
      if (response) {
        logConsole({ response }, true);
        setLeaderBoard(response);
        incrementSuccessfulApiCalls();
      }
    } catch (error) {
      const errorMessage = levelConnect.handleTheError(error);
      logConsole({ errorMessage }, true);
    }
  };

  useEffect(() => {
    getLeaderBoard();
  }, []);

  const getMarketPlaceItem = async () => {
    try {
      const { data: response } = await marketplace.getItems(telegramId);
      if (response) {
        setItems(response?.filter((mine) => mine?.disabled == false));
        incrementSuccessfulApiCalls();
        logConsole({ getMarketPlaceItem: response }, true);
      }
    } catch (error) {
      const errorMessage = marketplace.handleTheError(error);
      logConsole({ errorMessage }, true);
    }
  };

  useEffect(() => {
    getMarketPlaceItem();
  }, []);

  const getUserDailyCombo = async () => {
    try {
      const { data: response } = await marketplace.todaysUserDailyCombo(
        telegramId
      );
      if (response) {
        setDailyCombo(response);
        setCombo_id(response?.dailyCombo?.combo_id);
        incrementSuccessfulApiCalls();
        logConsole({ getUserDailyCombo: response }, true);
      }
    } catch (error) {
      const errorMessage = marketplace.handleTheError(error);
      logConsole({ errorMessage }, true);
    }
  };

  const mineItem = async (mine_id, cost) => {
    setShowProcessingModal(true);
    try {
      await userTap(coins);
      const { data: response } = await marketplace.mineItem(
        telegramId,
        mine_id
      );
      if (response) {
        logConsole({ mineItem: response }, true);
        const isOpen_ =
          response?.dailyCombo?.mineIdMatch ||
          response?.dailyCombo?.dailyComboGreaterThanThree;
        setShowUnlockedDailyComboModal(isOpen_);
        setCoins((coin) => coin - cost);
        updateProject({
          project_id: project_id,
          coin: coins - cost,
        });
        // await userTap(coins - cost);
        await getMarketPlaceItem();
        triggerNotification("success", "Item purchased!");
        getUserDailyCombo();
        setExe(!exe);
      }
    } catch (error) {
      const errorMessage = marketplace.handleTheError(error);
      triggerNotification("error", errorMessage);
    }
    setShowProcessingModal(false)
  };

  useEffect(() => {
    getUserDailyCombo();
  }, []);

  // Start here....

  useEffect(() => {
    if (!nextLevelUp || coins < nextLevelUp) {
    } else {
      logConsole({ nextLevelUp });
      checkNextLevel(coins);
    }
  }, [coins, nextLevelUp]);

  const [notifications, setNotifications] = useState([]);

  const closeNotification = () => {
    setTimeout(() => {
      setNotifications((notifications) => notifications.slice(1));
    }, 1500);
  };

  const closeNotificationById = (id) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.id !== id)
    );
  };

  const triggerNotification = (type, message) => {
    setShowProcessingModal(false);
    setNotifications([...notifications, { type, message, id: v4() }]);
    closeNotification();
  };

  const startTimeout = () => {};
  const [cachedImage, setCachedImage] = useState(null);

  const hashString = (str) => {
    let hash = 0,
      i,
      chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return hash;
  };

  useEffect(() => {
    const fetchAndCacheImage = async () => {
      const currentHash = hashString(levelImage);

      const openRequest = indexedDB.open("imageCacheDB", 1);

      openRequest.onupgradeneeded = () => {
        const db = openRequest.result;
        if (!db.objectStoreNames.contains("images")) {
          db.createObjectStore("images", { keyPath: "id" });
        }
      };

      openRequest.onsuccess = () => {
        const db = openRequest.result;
        const transaction = db.transaction("images", "readonly");
        const store = transaction.objectStore("images");
        const getRequest = store.get(currentHash);

        getRequest.onsuccess = () => {
          if (getRequest.result) {
            setCachedImage(getRequest.result.data);
          } else {
            fetchAndStoreImage(db, currentHash);
          }
          incrementSuccessfulApiCalls();
        };
      };

      const fetchAndStoreImage = async (db, hash) => {
        try {
          const response = await fetch(levelImage);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result;
            const transaction = db.transaction("images", "readwrite");
            const store = transaction.objectStore("images");
            store.put({ id: hash, data: base64data });
            setCachedImage(base64data);
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error("Failed to fetch and cache image", error);
        }
      };
    };

    fetchAndCacheImage();
  }, [levelImage]);

  useEffect(() => {
    if (coins > 0) {
      checkPlatform();
      userTap(coins);
    }
  }, []);

  const [lastActivePlayTime, setLastActivePlayTime] = useState(
    localStorage.getItem("lastActivePlayTime") == null
      ? Date.now()
      : localStorage.getItem("lastActivePlayTime")
  );

  const currentRemainingEnergy = ({ energyLevel, remainingEnergy }) => {
    const lastTap = lastActivePlayTime;
    const currentDate = moment();
    const lastTapDate = moment(lastTap);
    const timeDifferenceInSeconds = currentDate.diff(lastTapDate, "seconds");
    const threshold = remainingEnergy >= energyLevel;

    // console.log({
    //   currentDate: currentDate.toISOString(),
    //   lastTapDate: lastTapDate.toISOString(),
    //   timeDifferenceInSeconds,
    //   threshold,
    // });

    if (remainingEnergy + timeDifferenceInSeconds > energyLevel) {
      return energyLevel;
    } else {
      return timeDifferenceInSeconds + remainingEnergy;
    }
  };

  // Stops here ....

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      if (window.Telegram.WebApp.isVerticalSwipesEnabled) {
        window.Telegram.WebApp.disableVerticalSwipes();
        window.Telegram.WebApp.allow_vertical_swipe = false;
        logConsole(
          { allowY: window.Telegram.WebApp.allow_vertical_swipe },
          true
        );
      }
    }
  }, []);

  return (
    <div className="max-w-[450px] mx-auto relative">
      <context.Provider
        value={{
          telegramId,
          firstname,
          photoURL,
          project_id,
          showGame,
          setShowGame,
          setShowOnBoarding,
          modal,
          setModal,
          modalLevel,
          setModalLevel,
          coins,
          setCoins,
          projects,
          setProjects,
          currentProjectIndex,
          setCurrentProjectIndex,
          multipler,
          setMultipler,
          energyLevel,
          setEnergyLevel,
          remainingEnergyLevel,
          setRemainingEnergyLevel,
          userExchange,
          profitPerHour,
          setProfitPerHour,
          currentLevel,
          totalLevel,
          nextLevelUp,
          currentLevelName,
          updateProject,
          userTap,
          showClan,
          setShowClan,
          showInstructions,
          setShowInstructions,
          firstname,
          photoURL,
          cachedImage,
          setCachedImage,
          disableTapping,
          setDisableTapping,
          levelImage,
          triggerNotification,
          closeNotification,
          closeNotificationById,
          openModal,
          setOpenModal,
          raiderLevel,
          setRaiderLevel,
          startTimeout,
          loadGameModal,
          setLoadGameModal,
          setLastActiveTime,
          allAirdrops,
          setAllAirdrops,
          leaderBoard,
          setLeaderBoard,
          items,
          setItems,
          dailyCombo,
          setDailyCombo,
          getUserDailyCombo,
          mineItem,
          getAirdrops,
          showUnlockedDailyComboModal,
          setShowUnlockedDailyComboModal,
          combo_id,
          openLootModal,
          setOpenLootModal,
          profitPerHourBtn,
          claimProfitPerHour,
          calculateProftPerHour, 
          showProcessingModal, 
          setShowProcessingModal,
          showOfflinePlayerModal,
          setShowOfflinePlayerModal,
        }}
      >
        <LevelModal />
        <Modal />
        <ProcessingBarModal
        open={showProcessingModal}
        setOpen={setShowProcessingModal}
        />
        <LoadGameModal open={openModal} setOpen={setOpenModal} />
        <div className="notification-container">
          {notifications.map((notification, index) => (
            <Notification
              key={index}
              type={notification.type}
              message={notification.message}
              id={notification.id}
            />
          ))}
        </div>
        <LevelModal open={openLevelModal} setOpen={setLevelModal} />
        <Routes>
          <Route
            path="/"
            element={
              <div>
                {showRoadMap && <NewRoadMap project={"XEND"} />}
                {showOnBoarding && <Onboarding />}
                {showClan && <ChooseClan />}
                {showInstructions && <LoadGame />}
                {showGame && <Dashboard />}
              </div>
            }
          />
          <Route path="/boosters" element={<Boosters />} />
          <Route path="/airdrops" element={<Airdrops />} />
          <Route path="/upgrades" element={<Upgrade />} />
          <Route path="/earn" element={<Earn />} />
          <Route path="/friends" element={<Friends />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/clans" element={<ChangeClan />} />
          <Route path="/characters" element={<Characters />} />
          <Route path="/roadmap" element={<RoadmapPage />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/marketplace" element={<MarketplacePage />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route
            path="/project-details/:airdrop_id"
            element={<ProjectDetails />}
          />
          <Route path="/raid-leaderboard/:airdrop_id" element={<RaidLeaderboard />} />

          {/* <Route path="/loader" element={<LoadGame />} /> */}
        </Routes>
      </context.Provider>
    </div>
  );
}

export default App;
