import { useState, useContext, useEffect } from "react";
import SpecialsCard from "./SpecialsCard";
import SpecialProductModal from "../modals/SpecialProductModal";
import { context } from "../../App";

const SpecialProductsTab = ({ myCards, newCards, completed }) => {
  const [tab, setTab] = useState("new");
  const [products, setProducts] = useState(newCards);
  const active =
    "text-[#83C60D] pb-1 border-b-2 border-[#83C60D] underline-active-tab";
  const inActive = "text-[#848AA0]";
  const [showBuyItemModal, setShowBuyItemModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [mine_id, setMine_id] = useState("");
  const { mineItem } = useContext(context);
  const [btn, setBtn] = useState(false);

  const selectItem = (item) => {
    if (item.title && item.image) {
      setMine_id(item?.mine_id);
      setSelectedItem(item);
      setShowBuyItemModal(true);
    }
  };

  const buyItem = async () => {
    const cost = selectedItem?.mineLevel?.cost ?? 0;
    setBtn(true);
    setShowBuyItemModal(false);
    await mineItem(mine_id, cost);
    setBtn(false);
  };

  const changeTab = (value) => {
    setTab(value);
    switch (value) {
      case "mine":
        setProducts(myCards);
        break;
      case "new":
        setProducts(newCards);
        break;
      case "completed":
        setProducts(completed);
        break;
      default:
        setProducts(newCards);
        break;
    }
  };

  useEffect(() => {
    switch (tab) {
      case "mine":
        setProducts(myCards);
        break;
      case "new":
        setProducts(newCards);
        break;
      case "completed":
        setProducts(completed);
        break;
      default:
        setProducts(newCards);
        break;
    }
  }, [myCards, newCards, completed, tab]);

  return (
    <div className="flex flex-col gap-6">
        <div className="flex flex-row gap-5">
            <p 
                className={`font-semibold text-[15px] cursor-pointer ${tab === 'mine' ? active : inActive }`}
                onClick={()=>changeTab('mine')}
            >
                My Cards
            </p>
            <p 
                className={`font-semibold text-[15px] cursor-pointer ${tab === 'new' ? active : inActive }`}
                onClick={()=>changeTab('new')}
            >
                New Cards
            </p>
            <p 
                className={`font-semibold text-[15px] cursor-pointer ${tab === 'completed' ? active : inActive }`}
                onClick={()=>changeTab('completed')}
            >
                Completed
            </p>
        </div>

        <div className="grid grid-cols-2 gap-4 relative">
            {
                products?.length ? products?.map(product => (
                    <SpecialsCard
                        key={product.mine_id}
                        name={product.title}
                        title={product.description}
                        image={product.image}
                        price={product?.mineLevel?.cost}
                        level={product?.mineLevel?.level}
                        earnPerHour={product?.profitPerHourSum}
                        relatedSpecial={product.relatedSpecial}
                        locked={product?.locked}
                        isLevelBased={product?.isLevelBased}
                        isReferralBased={product?.isReferralBased}
                        click={()=>selectItem({ ...product })}
                    />
                )) : 
                <p className="text-center my-4 font-semibold absolute center-absolute-el">No products here</p>
            }
        </div>
        
        <SpecialProductModal
            open={showBuyItemModal}
            setOpen={setShowBuyItemModal}
            data={selectedItem}
            btn={btn}
            buyItem={()=> buyItem()}
        />
    </div>
  );
};

export default SpecialProductsTab;
