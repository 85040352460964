import React from 'react'
import { Boosters } from '../components/Boosters'

function Booster() {
  return (
    <div>
    <Boosters />
    </div>
  )
}

export default Booster