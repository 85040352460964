import React, { useContext } from "react";
import { context } from "../../App";
import { Slide } from "react-awesome-reveal";
import DollarCoin from "../../icons/dollar-coin.svg";
import { ReactComponent as Info } from "../../icons/infoGrey.svg";

function Headers() {
  const { multipler, profitPerHour, nextLevelUp, setShowOfflinePlayerModal } =
    useContext(context);

  function formatNumber(num) {
    if (typeof num !== "number") return "0";
    if (num < 1000) return num.toString();
    const units = ["", "k", "M", "B", "T"];
    const order = Math.floor(Math.log10(num) / 3);
    const unitname = units[order];
    const numShort = num / Math.pow(10, order * 3);
    return numShort % 1 === 0
      ? numShort.toFixed(0) + unitname
      : numShort.toFixed(1) + unitname;
  }

  return (
    <Slide direction={"left"} duration={300}>
      <div
        className="flex justify-between items-center gap-2 px-4"
        style={{ height: "10%" }}
      >
        <div className="bg-[#1D202B] rounded-[2.5rem] w-full min-w-[6.5rem] px-1 py-1">
          <div className="flex flex-col items-center justify-center">
            <div className="text-[#ff8b3e] text-[0.625rem]">
              Coins to level up
            </div>
            <div className="flex items-center justify-center">
              <img className="w-[1rem] h-[1rem]" alt="coin" src={DollarCoin} />
              <div className="text-[0.875rem]">{formatNumber(nextLevelUp)}</div>
            </div>
          </div>
        </div>

        <div className="bg-[#1D202B] rounded-[2.5rem] w-full min-w-[6.5rem] px-1 py-1">
          <div className="flex flex-col items-center justify-center">
            <div className="text-[#ff8b3e] text-[0.625rem]">Multiplier</div>
            <div className="flex items-center justify-center">
              <img className="w-[1rem] h-[1rem]" alt="coin" src={DollarCoin} />
              <div className="text-[0.875rem]">+{multipler}</div>
            </div>
          </div>
        </div>

        <div className="bg-[#1D202B] rounded-[2.5rem] w-full min-w-[6.5rem] px-1 py-1">
          <div className="flex flex-col items-center justify-center">
            <div className="text-[#ff8b3e] text-[0.625rem] flex items-center gap-1">
              <span>Loot per hour</span>
              <span onClick={() => setShowOfflinePlayerModal(true)}>
                <Info className="h-[10px] w-[10px] cursor-pointer" />
              </span>
            </div>
            <div className="flex items-center justify-center">
              <img className="w-[1rem] h-[1rem]" alt="coin" src={DollarCoin} />
              <div className="text-[0.875rem]">
                {formatNumber(profitPerHour)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default Headers;
