import React from "react";
import { useContext } from "react";
import { context } from "../../../App";
import { Zoom } from "react-awesome-reveal";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import DollarCoin from "../../../icons/dollar-coin.svg";
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";

export const LoadGameModal = ({ open, setOpen }) => {
  const { raiderLevel, setRaiderLevel, showGame, setShowGame } =
    useContext(context);

  const closeModal = () => {
    setOpen(false);
    setRaiderLevel((raiderLevel) => ({ ...raiderLevel, isOpen: false }));
    setShowGame(true);
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        className="md:w-[24.7rem] md:mx-auto max-h-[90vh] overflow-x-scroll"
      >
        <div className="pt-10 pb-10">
          <Zoom duration={850}>
            <img
              className="h-[6.4375rem] w-[6.4375rem] mx-auto mb-3 object-fill"
              alt="Fi"
              src={raiderLevel.image}
            />
          </Zoom>

          <p className="text-white text-[2rem] text-center mb-2">
            Hooya! Raider
          </p>
          <div className="text-[#848aa0] text-[0.9375rem] text-center max-w-[300px] mx-auto">
            You have joined the {raiderLevel.name} Clan to raid, conquer, and
            rise as the ultimate warrior! Get ready to earn the coveted{" "}
            {raiderLevel.projectName} token airdrop. Adventure and glory await!
          </div>

          <div className="flex justify-center mt-12 mb-6">
            <div className="flex gap-1 items-center">
              <img
                className="w-[2.25rem] h-[2.25rem]"
                alt="coin"
                src={DollarCoin}
              />
              <div className="text-[2rem] font-bold">
                +{(raiderLevel.reward ?? 0).toLocaleString()}
              </div>
            </div>
          </div>

          <div className="mb-6">
            <ButtonPrimary
              text={"Start Raiding"}
              onClick={closeModal}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
