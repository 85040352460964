import { Zoom } from "react-awesome-reveal";
import Image from "../../../icons/inviteFriends.png";
import DollarCoin from "../../../icons/dollar-coin.svg";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
// import { logConsole } from "../../../utils/useConsole";
// import { ModalLayout } from "../ModalLayout"
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";

const ReferralModal = ({ open, setOpen, title, reward, onClick, disabled }) => {
  
  const avoidDefaultDomBehavior = (e) => {
    e.preventDefault();
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        onPointerDownOutside={avoidDefaultDomBehavior}
        onInteractOutside={avoidDefaultDomBehavior}
        className="md:w-[24.7rem] md:mx-auto max-h-[90vh] overflow-x-scroll"
      >
        <div className="pt-10 pb-10">
          <Zoom duration={850}>
            <img
              className="h-[6.4375rem] w-[6.4375rem] mx-auto mb-3 object-fill"
              alt="Fi"
              src={Image}
            />
          </Zoom>
          <div className="mx-auto">
            <p className="text-white text-[1.5rem] text-center mb-1">{title}</p>
          </div>

          <div className="flex items-center justify-center gap-1 mt-4">
            <img src={DollarCoin} alt="coin" className="h-[2rem] w-[2rem]" />
            <div className="text-[2rem] font-bold">
              +{reward.toLocaleString()}
            </div>
          </div>

          <div className="flex justify-center mt-6">
            <ButtonPrimary
              text={"Claim reward"}
              onClick={onClick}
              disabled={disabled}
              disabledText={"Claim reward"}
              className={"!w-[18rem] mx-auto text-[20px]"}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ReferralModal;
