import React from "react";
import { Zoom } from "react-awesome-reveal";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import DollarCoin from "../../../icons/dollar-coin.svg";
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout";

export const RefillModal = ({
  open,
  setOpen,
  onClick,
  disableBtn,
  disabledText,
}) => {
  // const closeModal = () => {
  //   setOpen(false);
  // };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalContent
        side={"bottom"}
        className="md:w-[24.7rem] md:mx-auto max-h-[90vh] overflow-x-scroll"
      >
        <div className="pt-10 pb-10">
          <Zoom duration={850}>
            <img
              className="h-[6.4375rem] w-[6.4375rem] mx-auto mb-3 object-fill"
              alt="coin"
              src={DollarCoin}
            />
          </Zoom>

          <div className="max-w-[270px] mx-auto">
            <p className="text-white text-[1.5rem] text-center mb-1">
              Free Energy Refill
            </p>
            <div className="text-[#848aa0] text-[0.935rem] text-center">
              Refill your energy to go another round of raiding
            </div>
          </div>

          <div className="flex items-center justify-center gap-2 mt-6">
            <img
              src={DollarCoin}
              alt="coin"
              className="h-[1.6125rem] w-[1.6125rem]"
            />
            <div className="text-[1.5rem] font-bold">FREE</div>
          </div>

          <div className="flex justify-center mt-6">
            <ButtonPrimary
              text={"Refill"}
              onClick={onClick}
              disabled={disableBtn}
              disabledText={disabledText}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
