const EarnTaskSkeletonLoader = () => {
  return (
    <span
        className="flex items-center justify-between cursor-pointer bg-[#151827] h-[75px] rounded-[7px] px-2 mt-4"
    >
        <div className="flex items-center flex-row-reverse gap-3">
            <div className='flex flex-col gap-2'>
                <div 
                    className="opacity-70 w-[100px] h-[0.5rem] rounded-md" 
                    style={{animation: 'skeleton-loading 1s linear infinite alternate'}}
                />
                <div 
                    className="items-center gap-1 w-[150px] opacity-70 h-[0.5rem] rounded-md" 
                    style={{animation: 'skeleton-loading 1s linear infinite alternate'}}
                />
            </div>
            <div
                className="rounded-md"
                style={{ 
                    width: "40px", 
                    height: "40px",
                    animation: 'skeleton-loading 1s linear infinite alternate'
                }}
            />
        </div>
    </span>
  )
}

export default EarnTaskSkeletonLoader