import React, { useContext, useEffect } from "react";
import { context } from "../App";
import Coins from "../components/Game/Coins";
import GameBg1 from "../icons/gameplay-bg.png";
import { ReactComponent as Finger } from "../icons/finger-pointer.svg";
import { LoadGameModal } from "../components/modals/LoadGameModal";

const LoadGame = () => {
  const {
    setShowGame,
    setShowInstructions,
    openModal,
    setOpenModal,
    projects,
    firstname,
    cachedImage,
    currentProjectIndex,
    setShowClan,
    loadGameModal,
    setLoadGameModal,
  } = useContext(context);

  const handleClick = () => {
    setShowGame(true);
    setShowInstructions(false);
  };

  useEffect(() => {
    if ((projects && projects[currentProjectIndex]?.raider) == null) {
      setShowClan(false);
      setShowInstructions(false);
    }
  }, [projects]);


  return (
    <div className="h-screen mt-4">
      <div className="flex justify-center items-center flex-row-reverse gap-2 pb-3 mb-3">
        <div>
          <div className="text-[1rem] text-white font-bold leading-tight">
            {firstname}
          </div>
          <div className="text-[1rem] text-[#F28A00] font-bold leading-tight">
            {"Raider"}
          </div>
        </div>
        <img
          className="ellipse-2"
          alt="Ellipse"
          style={{ width: "2.375rem", height: "2.375rem" }}
          src="https://res.cloudinary.com/axgura/image/upload/v1717701337/User_Avatar_lzfn0l.png"
        />
      </div>

      <div className="coins cursor-pointer" onClick={() => handleClick()}>
        <div className="rectangle pt-3">
          <Coins />
          <div className="text-white text-bold text-[2rem] text-center">
            Tap to begin
          </div>

          <div className="relative">
            <img className="w-full" alt="hammer-bg" src={GameBg1} />

            {cachedImage && <img className="fi-2" alt="Fi" src={cachedImage} />}
            <div
              className="absolute bottom-[20%] left-[50%]"
              style={{ translate: "-50% 50%" }}
            >
              <Finger className="h-[11.75rem] w-auto" />
            </div>
          </div>
        </div>
      </div>
      <LoadGameModal open={loadGameModal} setOpen={setLoadGameModal} />
    </div>
  );
};

export default LoadGame;
