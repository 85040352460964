import React from 'react'
import { Upgrades } from '../components/Upgrades'

function Upgrade() {
  return (
    <div>
      <Upgrades />
    </div>
  )
}

export default Upgrade