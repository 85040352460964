import { splitString } from "../../ProjectDetails/AirdropQuest"
import { Modal, ModalContent } from "../ModalLayout/NewModalLayout"
import LockIcon from '../../../icons/lock.png';

const LockedAirdropQuest = ({ open, setOpen, data }) => {
  return (
    <Modal open={open} onOpenChange={setOpen}>
        <ModalContent
            side={'bottom'}
            className="md:w-[24.7rem] md:mx-auto overflow-x-scroll"
        >
            <div className="flex flex-col gap-4 mt-6 justify-center w-full">
                <h4 className="text-white text-[32px] font-semibold text-center mb-1">
                    {data.title}
                </h4>
                {
                    splitString(data?.text).length > 1 ?
                    splitString(data?.text).map((text, idx) => (
                        <div key={idx} className="flex flex-row gap-4 mb-3">
                            <div className="w-2 h-2 rounded-full" />
                            <p className="text-[#848AA0] font-semibold">{text}</p>
                        </div>
                    )) : 
                    <p className="text-[#848AA0] font-semibold text-center">{data?.text}</p>
                }

                <div className="text-center flex flex-col my-4">
                    <p className="font-semibold text-[15px]">Condition to unlock</p>
                    <p className="font-semibold text-[#F28A00] text-xl">{data?.condition}</p>
                </div>

                <button 
                    disabled={true}
                    className="w-full py-[17px] flex flex-row items-center gap-4 justify-center rounded-[195px] bg-[#848AA0]"
                >
                    <img
                        src={LockIcon}
                        alt={'locked'}
                        className="w-8 h-8 object-cover"
                    />
                    <p className="text-xl font-semibold text-[#212942]">Locked</p>
                </button>
               
            </div>
        </ModalContent>
    </Modal>
  )
}

export default LockedAirdropQuest