import React from "react";
import { ReactComponent as Tick } from "../../icons/tickBlue.svg";
import { ReactComponent as TickGreen } from "../../icons/tickGreen.svg";

export const Timeline = () => {
  return (
    <div className="grid grid-cols-2 gap-2 mt-4">
      <div className="flex items-center gap-3">
        <TickGreen className="w-[0.7rem] h-[0.56rem]"/>
        <span className="!text-[0.93rem] text-[#83C60D] font-semibold">Core Game Play</span>
      </div>
      <div className="flex items-center gap-3">
        <Tick className="w-[0.7rem] h-[0.56rem]"/>
        <span className="!text-[0.93rem] text-white font-semibold">
          Exchange Negotiations
        </span>
      </div>
      <div className="flex items-center gap-3">
        <Tick className="w-[0.7rem] h-[0.56rem]"/>
        <span className="!text-[0.93rem] text-white font-semibold">Key Partnerships</span>
      </div>
      <div className="flex items-center gap-3">
        <Tick className="w-[0.7rem] h-[0.56rem]"/>
        <span className="!text-[0.93rem] text-white font-semibold">Token Airdrop</span>
      </div>
    </div>
  );
};
