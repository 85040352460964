import { AxiosConnect } from "./auth.service";

export class FriendsConnect {

    async getReferrals(telegramId) {
        const { data: response } = await AxiosConnect.get(`/friends/${telegramId}`);
        if(!response || !response.data){
            alert("Unable to complete action");
        } else {
            return response;
        }
    }

    handleTheError(AxiosError) {
        let errorMessage = AxiosError.response?.data?.message || AxiosError.message || 'Request failed';
        console.error('Error message:', errorMessage);
        if(errorMessage === "An issue has arisen. Please try again later."){
            errorMessage = "Please ensure that you are a waitlist member and use the email to send us a message"
        }
        return errorMessage;
    }
}