import { useContext, useEffect, useState } from "react";
import DollarCoin from "../../icons/dollar-coin.svg";
import DailyComboItem from "./DailyComboItem";
import DailyComboModal from "../modals/DailyComboModal";
import moment from "moment";
import UnlockedDailyComboOverlay from "../modals/DailyComboModal/UnlockedDailyComboOverlay";
import { context } from "../../App";
import { MarketPlaceConnect } from "../../services/marketplace.service";
import { logConsole } from "../../utils/useConsole";

const DailyCombo = ({ amount, comboItems_, day, claimed }) => {
  const marketplace = new MarketPlaceConnect();
  const {
    telegramId,
    showUnlockedDailyComboModal,
    setShowUnlockedDailyComboModal,
    getUserDailyCombo,
    triggerNotification,
    project_id,
    updateProject,
    setCoins,
    coins,
    combo_id,
  } = useContext(context);
  const [showDailyComboModal, setShowDailyComboModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [btn, setBtn] = useState(false);
  const [comboItems, setComboItems] = useState(comboItems_);
  const endOfDay = moment(day).endOf("day");
  const now = moment();
  const diff = endOfDay.diff(now);
  const duration = moment.duration(diff);
  const timeLeft = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");

  // useEffect(() => {
  //   if (duration.asMilliseconds() <= 0) {
  //     getUserDailyCombo();
  //   } else {
  //     logConsole({ duration: duration.asMilliseconds(), timeLeft }, true);
  //   }
  // }, [timeLeft]);

  const selectDailyCombo = (item) => {
    if (item.name && item.image) {
      setSelectedItem(item);
      setShowDailyComboModal(true);
    }
    return;
  };

  const getPosition = (comboLength) => {
    let position;

    if (comboLength > 0) {
      if (comboLength >= 1) {
        position = "first";
      }
      if (comboLength >= 2) {
        position = "second";
      }
      if (comboLength >= 3) {
        position = "third";
      }
    } else {
      position = null;
    }
    return position;
  };

  const claimCombo = async () => {
    setBtn(true);
    try {
      const { data: response } = await marketplace.claimDailyCombo(
        telegramId,
        combo_id
      );
      if (response) {
        logConsole({ claimCombo: response });
        setCoins((coin) => coin + response?.reward ?? 0);
        updateProject({ project_id, coin: coins + response?.reward ?? 0 });
        getUserDailyCombo();
        triggerNotification("success", "Daily Combo reward claimed!");
        setBtn(false);
      }
    } catch (error) {
      const errorMessage = marketplace.handleTheError(error);
      triggerNotification("error", errorMessage);
      getUserDailyCombo();
      setBtn(false);
    }
  };

  const hasItems = Array.isArray(comboItems_) && comboItems_.length > 0;

  return (
    <div className="bg-[#151827] rounded-[10px] px-4 py-5 flex flex-col gap-2 mb-8">
      <p className="font-semibold text-[#848AA0] text-base">{timeLeft}</p>
      <div className="flex flex-row justify-between items-center font-semibold">
        <p>Daily Combo</p>
        <div className="flex flex-row items-center gap-1">
          {[...Array(3)]?.map((_, index) =>
            hasItems && comboItems_[index]?.title ? (
              <div className="bg-[#83C60D] h-[0.9375rem] w-[0.9375rem] rounded-full flex justify-center items-center text-black text-xs">
                &#10004;
              </div>
            ) : (
              <div className="h-[0.9375rem] w-[0.9375rem] rounded-full !border-2 border-solid border-[#585F7D]" />
            )
          )}
        </div>
        <div className="flex gap-2 items-center">
          <img className="w-[18px] h-[18px]" alt="coins" src={DollarCoin} />
          <span>{Math.floor(amount ?? 5000000).toLocaleString()}</span>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2">
        {hasItems ? (
          <DailyComboItem
            name={comboItems_[0]?.title}
            image={comboItems_[0]?.image}
            onClick={() =>
              selectDailyCombo({
                name: comboItems_[0]?.title,
                image: comboItems_[0]?.image,
                reward: amount,
              })
            }
          />
        ) : (
          <DailyComboItem
            name={null}
            image={null}
            onClick={() =>
              selectDailyCombo({
                name: null,
                image: null,
                reward: amount,
              })
            }
          />
        )}

        {hasItems ? (
          <DailyComboItem
            name={comboItems_[1]?.title}
            image={comboItems_[1]?.image}
            onClick={() =>
              selectDailyCombo({
                name: comboItems_[1]?.title,
                image: comboItems_[1]?.image,
                reward: amount,
              })
            }
          />
        ) : (
          <DailyComboItem
            name={null}
            image={null}
            onClick={() =>
              selectDailyCombo({
                name: null,
                image: null,
                reward: amount,
              })
            }
          />
        )}

        {hasItems ? (
          <DailyComboItem
            name={comboItems_[2]?.title}
            image={comboItems_[2]?.image}
            onClick={() =>
              selectDailyCombo({
                name: comboItems_[2]?.title,
                image: comboItems_[2]?.image,
                reward: amount,
              })
            }
          />
        ) : (
          <DailyComboItem
            name={null}
            image={null}
            onClick={() =>
              selectDailyCombo({
                name: null,
                image: null,
                reward: amount,
              })
            }
          />
        )}
      </div>

      <UnlockedDailyComboOverlay
        open={showUnlockedDailyComboModal}
        combo={comboItems_}
        hasItems={hasItems}
        setOpen={setShowUnlockedDailyComboModal}
        data={{
          step: comboItems_?.length,
          position: getPosition(comboItems_?.length),
        }}
      />

      {hasItems && comboItems_?.length >= 3 && (
        <DailyComboModal
          open={showDailyComboModal}
          setOpen={setShowDailyComboModal}
          data={selectedItem}
          disabled={btn ? btn : claimed }
          claimReward={() => claimCombo()}
        />
      )}
    </div>
  );
};

export default DailyCombo;
