import { config } from "../config";
import { AxiosConnect } from "./auth.service";

export class LevelConnect {

  async getLeaderBoard(telegramId) {
    const { data: response } = await AxiosConnect.get(`/user/level-leaderboard/${telegramId}`);
    if (response && response.data) {
      return response;
    }
  }

  handleTheError(AxiosError) {
    let errorMessage =
      AxiosError.response?.data?.message ||
      AxiosError.message ||
      "Request failed";
    return errorMessage;
  }
}
