import React, { useContext, useState, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import { context } from "../App";
import { ExpandLeftIcon } from "../icons";
import { logConsole } from "../utils/useConsole";
import { RaiderConnect } from "../services/raider.service";
import { ChangeClanModal } from "../components/modals/ChangeClanModal";
import SoundPlayer from "../utils/GameSounds";
import ChooseClanSkeletonLoader from "../components/Loaders/ChooseClan";

const ChangeClan = () => {
  const raider = new RaiderConnect();
  const [clans, setClans] = useState(null);
  const [fetchingClans, setFetchingClans] = useState(false);
  const [clanImages, setClanImages] = useState({});
  const {
    setShowClan,
    setShowGame,
    coins,
    setShowInstructions,
    project_id,
    telegramId,
    triggerNotification,
    currentProjectIndex,
    projects,
    updateProject,
    setRaiderLevel,
    setCoins,
  } = useContext(context);
  const [openClanModal, setOpenClanModal] = useState(false);
  const [clanName, setClanName] = useState("");
  const [image, setImage] = useState("");

  const getClans = async () => {
    setFetchingClans(true);
    try {
      const { data: response } = await raider.getRaiders();
      if (response) {
        setClans(response);
        setFetchingClans(false);
      }
    } catch (error) {
      const errorMessage = raider.handleTheError(error);
      triggerNotification("error", errorMessage);
      setFetchingClans(false);
    }
  };

  const handleClick = async (raider_id) => {
    try {
      const data = { raider_id, project_id, telegramId };
      const { data: response } = await raider.chooseRaider(data);
      if (response && response.raider) {
        logConsole({ chooseRaider: response });
        const raiderName = response.raider.name;
        const raiderImage = response.raider.image;
        const reward = response.raider.reward;
        const projectName = projects[currentProjectIndex].projectName;
        setCoins(coins + (reward || 0));
        updateProject({
          project_id,
          raider: response.raider,
          coin: coins + (reward || 0),
        });

        setClanName(raiderName);
        setImage(raiderImage);
        setOpenClanModal(true);
        SoundPlayer.playSound("success2");
        setRaiderLevel((prevRaider) => ({
          ...prevRaider,
          isOpen: true,
          name: raiderName,
          reward,
          projectName,
        }));
      }
    } catch (error) {
      const errorMessage = raider.handleTheError(error);
      triggerNotification("error", errorMessage);
    }
  };

  useEffect(() => {
    getClans();
  }, []);

  useEffect(() => {
    if (clans && clans.length > 0) {
      clans.forEach(clan => {
        const clanName = clan?.name;
        if (clanName && !clanImages[clanName]) {
          import(`../assets/Clans/${clanName}.png`)
            .then(image => {
              setClanImages(prevImages => ({ ...prevImages, [clanName]: image.default }));
            })
            .catch(err => console.error('Error loading image:', err));
        }
      });
    }
  }, [clans, clanImages]);

  return (
    <div className="bg-[#0d0f18] w-full px-3">
      <div className="w-full">
        <div className="mb-6 mt-10">
          <div>
            <div className="text-white text-[1.5rem] font-bold">
              Change your Clan
            </div>
            <div className="text-[#848aa0] text-[0.9375rem]">
              Become the ultimate raider in your Clan
            </div>
          </div>
        </div>

        <div className="flex gap-2 flex-col">
          {
            fetchingClans ? 
            Array.from({ length: 6 }, (_, i) => <ChooseClanSkeletonLoader key={i} />) :

            <Slide cascade duration={400}>
              {clans &&
                clans.length > 0 &&
                clans.map((clan, index) => (
                  <div
                    key={index}
                    onClick={() => handleClick(clan.raider_id)}
                    className="flex items-center justify-between gap-1 cursor-pointer bg-[#151827] h-[78px] rounded-[7px] px-2"
                  >
                    <div className="flex items-center flex-row-reverse gap-3">
                      <div>
                        <div className="text-[1.5rem] text-white">
                          {clan?.name}
                        </div>
                      </div>
                      <img
                        className="img-2 rounded-md"
                        alt="Img"
                        style={{ width: "4rem", height: "4rem" }}
                        src={clanImages[clan?.name] || clan.image}
                      />
                    </div>
                    <ExpandLeftIcon
                      style={{ cursor: "pointer" }}
                      className="w-[1.5rem] h-[1.5rem]"
                    />
                  </div>
                ))}
            </Slide>
          }
        </div>
      </div>
      <ChangeClanModal open={openClanModal} setOpen={setOpenClanModal} name={clanName} image={image} />
    </div>
  );
};

export default ChangeClan;
