import axios from "axios";
import { config } from "../config";

const testMode = false;

export const AxiosConnect = axios.create({
    baseURL: testMode ? "http://localhost:2024" : config.SERVER_URL
});

AxiosConnect.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("token") || "";
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
