import React from 'react'
import { Game } from "../components/Game"

function Dashboard() {
  return (
    <div>
      <Game />
    </div>
  )
}

export default Dashboard