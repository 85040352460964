import React, { useContext } from "react";
import { context } from "../../App";
import { useNavigate } from "react-router-dom";
import { ExpandLeftIcon } from "../../icons";
// import { Slide } from "react-awesome-reveal";

function Progress() {
  const navigate = useNavigate();
  const { currentLevel, totalLevel, currentLevelName, coins, nextLevelUp } =
    useContext(context);
  const percentage = (coins / nextLevelUp) * 100;
  return (
    <div>
      <div className="relative flex justify-center w-full mx-auto mt-3 px-4">
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${percentage}%` }} />
        </div>

        <button
          type="button"
          onClick={() => navigate("/characters")}
          className="absolute left-[1rem] top-[-120%] text-white flex items-center gap-1"
        >
          <span className="text-[0.875rem] text-white">
            {currentLevelName ?? "No Level"}
          </span>
          <ExpandLeftIcon
            style={{ cursor: "pointer" }}
            className="w-[1.25rem] h-[1.25rem]"
          />
        </button>

        <div className="absolute right-[1rem] top-[-120%] text-white">
          <span className="text-[0.875rem] text-[#848AA0]">Level </span>
          <span className="text-[0.875rem] text-white">
            {currentLevel}/{totalLevel}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Progress;
