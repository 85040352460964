import React from "react";
import { RaidLeaderboardComponents } from "../components/RaidLeaderboard";

function RaidLeaderboard() {
  return (
    <div>
      <RaidLeaderboardComponents />
    </div>
  );
}

export default RaidLeaderboard;
