import React from "react";
// import "./style.css";
import Image from "../../../icons/twitter-btn.png";
import DollarCoin from "../../../icons/dollar-coin.svg";
import { ButtonPrimary } from "../../buttons/ButtonPrimary";
import { ModalLayout } from "../ModalLayout";

export const JoinXModal = ({ open, setOpen, data, isTwitterEnabled }) => {
  if (isTwitterEnabled) {
    data.linked = true;
  }

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      icon={Image}
      content={
        <div>
          <div className="mx-auto">
            <p className="text-white text-[1.5rem] text-center mb-1">
              Follow our X account
            </p>
          </div>

          <div className="flex justify-center mt-4">
            <ButtonPrimary
              text={"Follow"}
              onClick={() =>
                data.completeTask(data.action, false, data.link, data.reward)
              }
              className={"!w-[18rem] mx-auto"}
            />
          </div>

          <div className="flex items-center justify-center gap-1 mt-4">
            <img src={DollarCoin} alt="coin" className="h-[2rem] w-[2rem]" />
            <div className="text-[2rem] font-bold">+{data.reward}</div>
          </div>

          <div className="flex flex-col justify-center mt-6">
            {!data.linked && (
              <p className="text-sm text-[#848aa0] mb-2">Admin has to verify</p>
            )}
            <ButtonPrimary
              text={"Claim Reward"} 
              onClick={() =>
                data.completeTask(data.action, true, data.link, data.reward)
              }
              disabled={!isTwitterEnabled || data?.linked}
              disabledText={"Claim Reward"}
              className={'!w-[18rem] mx-auto text-[20px]'}
            />
          </div>
        </div>
      }
    />
  );
};
